export interface IEnumItem<T> {
  readonly value: T;
  readonly displayName: string;
}

export class Enum<T> {
  readonly items: ReadonlyArray<IEnumItem<T>>;
  readonly itemMap: ReadonlyMap<T, IEnumItem<T>>;

  constructor(items: ReadonlyArray<[T, string]>) {
    const array: IEnumItem<T>[] = [];
    const map = new Map<T, IEnumItem<T>>();

    for (const [value, displayName] of items) {
      const item: IEnumItem<T> = { value, displayName };
      array.push(item);
      map.set(value, item);
    }

    this.items = array;
    this.itemMap = map;
  }

  getDisplayName(value: T): string {
    return this.itemMap.get(value)?.displayName ?? String(value);
  }
}
