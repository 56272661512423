import GridActions from '../../../../components/common/GridActions';
import Button from '../../../../components/ui/Button';
import { BASE_URL } from '../../../../const';
import { IStatOptions, STAT_DATA_KEYS } from '../../../../redux/api/statistic';
import { useAppSelector } from '../../../../redux/hooks';
import { reportSelectors } from '../../../../redux/slices/reports';
import { getSearchParams } from '../../../../utils/searchParams';

const DepartmentsReportActions = () => {
  const startDate = useAppSelector(reportSelectors.selectDepartmentsReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectDepartmentsReportEndDate);

  const options: IStatOptions = {
    dateFromTs: startDate,
    dateToTs: endDate,
  };
  const params = getSearchParams(options, STAT_DATA_KEYS);

  return (
    <GridActions>
      <Button link={`${BASE_URL}api/statistics/complexes/totals/export${params}`} download>
        Экспорт
      </Button>
    </GridActions>
  );
};
export default DepartmentsReportActions;
