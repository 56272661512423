import { IPrintJobAuthor } from '../../types/IPrintJobAuthor';
import { TId } from '../../types/TId';
import { api } from '../api';

export const printjobAuthorsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPrinjobAuthorList: builder.query<IPrintJobAuthor[], void>({
      query: () => 'api/print-job-authors',
    }),
    getPrintjobAuthorById: builder.query<IPrintJobAuthor, TId>({
      query: id => `api/print-job-authors/${id}`,
    }),
    createPrintjobAuthor: builder.mutation<
      IPrintJobAuthor,
      Omit<IPrintJobAuthor, 'id' | 'divisionName' | 'showName'>
    >({
      query: body => ({
        url: `api/print-job-authors`,
        method: 'POST',
        body,
      }),
    }),
    updatePrintjobAuthor: builder.mutation<
      IPrintJobAuthor,
      { id: string; body: Omit<IPrintJobAuthor, 'id' | 'divisionName' | 'showName'> }
    >({
      query: ({ id, body }) => ({
        url: `api/print-job-authors/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deletePrintjobAuthor: builder.mutation<string, TId>({
      query: id => ({
        url: `api/print-job-authors/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreatePrintjobAuthorMutation,
  useUpdatePrintjobAuthorMutation,
  useDeletePrintjobAuthorMutation,
  useGetPrinjobAuthorListQuery,
  useGetPrintjobAuthorByIdQuery,
} = printjobAuthorsApi;
