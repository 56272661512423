import { MIN_PAGE_WIDTH } from '../../../const/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IImageEditorState {
  imageWidth: number;
  imageAnimation: boolean;
  keepSize: boolean;
}

const initialState: IImageEditorState = {
  imageWidth: 500,
  imageAnimation: true,
  keepSize: false,
};

const imageEditorSlice = createSlice({
  name: 'imageEditor',
  initialState,
  reducers: {
    setImageWidth: (state, action: PayloadAction<number>) => {
      state.imageWidth = action.payload < MIN_PAGE_WIDTH ? MIN_PAGE_WIDTH : action.payload;
    },
    turnOnImageAnimation: state => {
      state.imageAnimation = true;
    },
    turnOffImageAnimation: state => {
      state.imageAnimation = false;
    },
    toggleKeepSize: state => {
      state.keepSize = !state.keepSize;
    },
  },
});

export const { setImageWidth, turnOnImageAnimation, turnOffImageAnimation, toggleKeepSize } =
  imageEditorSlice.actions;

export default imageEditorSlice.reducer;
