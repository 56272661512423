import { Enum } from '../data/enum';

export enum Role {
  SuperAdmin = 'super_administrator',
  Admin = 'administrator',
  Tech = 'technologist',
  Operator = 'operator',
}

export interface IUserCreateInfo {
  userName: string;
  newPassword: string;
  role: Role;
  firstName: string;
  secondName: string;
  lastName: string;
  active: boolean;
  key: string;
}

export interface IUserInfo extends Omit<IUserCreateInfo, 'password'> {
  id: number;
  showName: string;
}

export const RoleEnum = new Enum<Role>([
  [Role.SuperAdmin, 'Суперадминистратор'],
  [Role.Admin, 'Администратор'],
  [Role.Tech, 'Технолог'],
  [Role.Operator, 'Оператор'],
]);
