import { TNormalizedComplex } from './selectors';
import { INormalizedImage } from './slice';
import { EditorActionType } from '../../../types/EditorAction';
import cloneDeep from 'lodash.clonedeep';
import { IComplex } from '../../../types/IComplex';
import { IImage } from '../../../types/IImage';
import { IComplexDocument } from '../../../types/IComplexDocument';

const handleRotation = (img: INormalizedImage) => {
  const newImage = { ...img };
  const rotateActions = newImage.actions.filter(a => a.type === EditorActionType.Rotate);
  if (rotateActions.length) {
    const degree = rotateActions.reduce((acc, ra) => acc + ra.payload, 0) % 360;
    newImage.actions = newImage.actions.filter(a => a.type !== EditorActionType.Rotate);

    let newRotation = (newImage.rotation + degree) % 360;

    if (newRotation === -90) {
      newRotation = 270;
    } else if (newRotation === -180) {
      newRotation = 180;
    } else if (newRotation === -270) {
      newRotation = 90;
    }
    newImage.rotation = newRotation;
  }

  return newImage;
};

const mapToOriginalImage = (img: INormalizedImage, isRecognition?: boolean): IImage => {
  return {
    id: img.id,
    important: img.important,
    format: img.format,
    rotation: img.rotation,
    recognized: isRecognition ? false : img.recognized,
    needRecognize: isRecognition ? true : img.needRecognize,
    filename: img.filename,
    group: img.group,
  };
};

const mapToOriginalDocument = (
  doc: TNormalizedComplex['awards'][0],
  isRecognition?: boolean,
): IComplexDocument => {
  return {
    id: doc.id,
    barcode: doc.barcode,
    viewed: doc.viewed,
    images: doc.images.map(handleRotation).map(img => mapToOriginalImage(img, isRecognition)),
  };
};

export const prepareComplex = (
  fullComplex: TNormalizedComplex,
  isRecognition?: boolean,
): Partial<IComplex> => {
  const complex = cloneDeep(fullComplex);

  if (complex.petition) {
    complex.petition.images = complex.petition.images.filter(img => !img.deleted);
  }

  complex.awards = complex.awards
    .filter(aw => !aw.actions.some(act => act.type === EditorActionType.DeleteAward))
    .map(aw => ({
      ...aw,
      images: aw.images.filter(img => !img.deleted),
    }));

  return {
    id: complex.complexId ?? undefined,
    awardsFactCount: complex.awardsFactCount ?? undefined,
    petition: complex.petition ? mapToOriginalDocument(complex.petition, isRecognition) : undefined,
    awards: complex.awards.map(aw => mapToOriginalDocument(aw, isRecognition)),
  };
};
