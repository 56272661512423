import { MIN_PAGE_WIDTH } from '../../../const/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILayoutState {
  pageWidth: number;
  operatorPreviewState: boolean;
}

const initialState: ILayoutState = {
  pageWidth: 500,
  operatorPreviewState: true,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setPageWidth: (state, action: PayloadAction<number>) => {
      state.pageWidth = action.payload < MIN_PAGE_WIDTH ? MIN_PAGE_WIDTH : action.payload;
    },
    togglePreviewState: (state, action: PayloadAction<boolean>) => {
      state.operatorPreviewState = action.payload;
    },
  },
});

export const { setPageWidth, togglePreviewState } = layoutSlice.actions;

export default layoutSlice.reducer;
