import { FormEventHandler, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { PETITION_REGEX } from '../../../const';
import { toast } from 'react-toastify';
import { operatorTaskActions } from '../../../redux/slices/operatorTask';
import TextInput from '../../../components/ui/TextInput';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';

interface Props {
  index?: number;
  onClose?: () => void;
}

const CreateAwardModal = ({ index, onClose }: Props) => {
  const [barcode, setBarcode] = useState('');
  const dispatch = useAppDispatch();

  const skipHandler = () => {
    if (!index) {
      toast.error('Что-то пошло не так!');
      return;
    }

    dispatch(operatorTaskActions.insertAward({ index, barcode: '29..........................' }));
    onClose?.();
  };

  const submitHandler: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    if (!index) {
      toast.error('Что-то пошло не так!');
      return;
    }
    if (!PETITION_REGEX.test(barcode)) {
      toast.error('Штрихкод должен состоять из 13 цифр и начинаться на 29!');
      return;
    }
    dispatch(operatorTaskActions.insertAward({ index, barcode }));
    onClose?.();
  };

  return (
    <form onSubmit={submitHandler}>
      <TextInput
        label="Штрихкод"
        placeholder="Введите значение"
        type="text"
        value={barcode}
        onChange={value => setBarcode(value)}
        className="mb-4"
        autoFocus={true}
        maxLength={13}
      />
      <Buttons>
        <Button type="button" variant="outlined" onClick={skipHandler}>
          Пропустить
        </Button>
        <Button type="submit">OK</Button>
      </Buttons>
    </form>
  );
};
export default CreateAwardModal;
