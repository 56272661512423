import { FormEventHandler, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import Buttons from '../../../components/ui/Buttons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { operatorTaskActions, operatorTaskSelectors } from '../../../redux/slices/operatorTask';
import { PETITION_REGEX } from '../../../const';

interface Props {
  onClose?: () => void;
}

const AssignBarcodeModal = ({ onClose }: Props) => {
  const [barcode, setBarcode] = useState('');
  const dispatch = useAppDispatch();
  const activeDocument = useAppSelector(operatorTaskSelectors.selectActiveDocumentInfo);

  const submitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      if (!PETITION_REGEX.test(barcode)) {
        toast.error('Штрихкод должен состоять из 13 цифр и начинаться на 29!');
        return;
      }
      if (activeDocument?.barcode.startsWith('29...')) {
        dispatch(operatorTaskActions.changeAwardBarcode({ id: activeDocument.id, barcode }));
      } else {
        dispatch(operatorTaskActions.splitAward(barcode));
      }
      onClose?.();
    },
    [activeDocument?.barcode, activeDocument?.id, barcode, dispatch, onClose],
  );

  return (
    <form onSubmit={submitHandler}>
      <TextInput
        label="Штрихкод"
        placeholder="Введите значение"
        type="text"
        value={barcode}
        onChange={value => setBarcode(value)}
        className="mb-4"
        autoFocus={true}
        maxLength={13}
      />
      <Buttons>
        <Button type="button" variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button type="submit">OK</Button>
      </Buttons>
    </form>
  );
};

export default AssignBarcodeModal;
