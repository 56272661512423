import { useEffect, useState } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import GridPage from '../../gridPage/GridPage';
import PrintingReportFilters from './PrintingReportFilters';
import PrintingReportGrid from './PrintingReportGrid';
import PrintingReportActions from './PrintingReportActions';
import { useAppSelector } from '../../../../redux/hooks';
import { reportSelectors } from '../../../../redux/slices/reports';

const PrintingReport = () => {
  const startDate = useAppSelector(reportSelectors.selectPrintingReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectPrintingReportEndDate);

  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  useEffect(() => {
    setFetchOptions(prev => ({ ...prev, dateFromTs: startDate, dateToTs: endDate }));
  }, [startDate, endDate]);

  return (
    <GridPage
      title="Статистика по обратной печати"
      className="pt-2 pb-7"
      panel={<PrintingReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={<PrintingReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />}
      actions={<PrintingReportActions fetchOptions={fetchOptions} />}
    />
  );
};
export default PrintingReport;
