import { ComplexState } from '../../../types/IComplexInfo';
import { PrintJobState } from '../../../types/IPrintInfo';

const RUNNING_COMPLEX_STATES: ComplexState[] = [
  ComplexState.LoadingInProcess,
  ComplexState.ValidationReadyTo,
  ComplexState.ValidationInProcess,
  ComplexState.PrepareReadyTo,
  ComplexState.PrepareInProcess,
  ComplexState.RecognitionReadyTo,
  ComplexState.RecognitionInProcess,
  ComplexState.BuildStructureReadyTo,
  ComplexState.BuildStructureInProcess,
  ComplexState.SendReadyTo,
  ComplexState.SendInProcess,
  ComplexState.WaitForPart,
  ComplexState.AssembleReadyTo,
  ComplexState.AssembleInProcess,
];

const OPERATOR_COMPLEX_STATES: ComplexState[] = [
  ComplexState.LoadingError,
  ComplexState.ValidationError,
  ComplexState.ValidationErrorParity,
  ComplexState.ValidationErrorDpi,
  ComplexState.ValidationErrorPageFormat,
  ComplexState.PrepareError,
  ComplexState.RecognitionError,
  ComplexState.BuildStructureError,
  ComplexState.ReadyToWorkNoCode,
  ComplexState.ReadyToWorkDuplicateCode,
  ComplexState.ReadyToWork,
  ComplexState.InWork,
  ComplexState.SendError,
  ComplexState.AssembleError,
  ComplexState.Sent,
  ComplexState.SentError,
  ComplexState.PartReady,
  ComplexState.PartsReady,
  ComplexState.LoadingDuplicate,
  ComplexState.LoadingErrorDuplicate,
];

const PRINT_JOB_STATES: PrintJobState[] = [
  PrintJobState.Loading,
  PrintJobState.ReadyToPrint,
  PrintJobState.InWork,
  PrintJobState.Fail,
];
const FINISHED_PRINT_JOB_STATES: PrintJobState[] = [
  PrintJobState.ErrorPrinting,
  PrintJobState.Complete,
];
const FINISHED_COMPLEX_STATES: ComplexState[] = [ComplexState.SentSuccess];

export const RUNNING_COMPLEXES = RUNNING_COMPLEX_STATES.join(',');
export const PRINT_JOBS = PRINT_JOB_STATES.join(',');
export const OPERATOR_COMPLEXES = OPERATOR_COMPLEX_STATES.join(',');
export const FINISHED_PRINT_JOBS = FINISHED_PRINT_JOB_STATES.join(',');
export const FINISHED_COMPLEXES = FINISHED_COMPLEX_STATES.join(',');
