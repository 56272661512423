import { ICreatePrinterInfo, IPrinter } from '../../types/IPrinter';
import { TId } from '../../types/TId';
import { api } from '../api';

export const printersApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPrintersList: builder.query<IPrinter[], void>({
      query: () => 'api/printers',
    }),
    getPrinterById: builder.query<IPrinter, TId>({
      query: id => ({
        url: `api/printers/${id}`,
      }),
      forceRefetch: () => true,
    }),
    createPrinter: builder.mutation<IPrinter, ICreatePrinterInfo>({
      query: body => ({
        url: 'api/printers',
        method: 'POST',
        body,
      }),
    }),
    editPrinter: builder.mutation<IPrinter, IPrinter>({
      query: body => ({
        url: `api/printers/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deletePrinter: builder.mutation<string, string>({
      query: id => ({
        url: `api/printers/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPrintersListQuery,
  useGetPrinterByIdQuery,
  useCreatePrinterMutation,
  useEditPrinterMutation,
  useDeletePrinterMutation,
} = printersApi;
