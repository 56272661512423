import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';

import Tag from '../../../components/ui/Tag';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import { POLLING_INTERVAL_S } from '../../../const';
import GridActions from '../../../components/common/GridActions';
import { useAppSelector } from '../../../redux/hooks';
import Button from '../../../components/ui/Button';
import { selectionSelectors } from '../../../redux/slices/selection';
import { Routes } from '../../../Router';

import styles from './Grid.module.scss';
import { useGetPrinjobListQuery } from '../../../redux/api/printjobs';
import { ICompletedJob } from '../../../types/ICompletedJob';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import { useGetComplexesListQuery } from '../../../redux/api/complexes';
import { FINISHED_COMPLEXES, FINISHED_PRINT_JOBS } from './filters';
import { IComplexInfo } from '../../../types/IComplexInfo';
import { IPrintInfo } from '../../../types/IPrintInfo';
import { getReadableDateTime } from '../../../utils/date';
import Select from '../../../components/ui/Select';
import { ISelectOption } from '../../../types/ISelectOption';

const columnHelper = createColumnHelper<ICompletedJob>();

const columns = [
  columnHelper.display({
    id: 'index',
    header: '#',
    cell: ({ row }) => row.index + 1,
    size: 50,
  }),
  columnHelper.accessor('name', {
    header: 'Имя документа',
    cell: info => <span className={styles.title}>{info.renderValue()}</span>,
  }),
  columnHelper.accessor('barcode', {
    header: 'Штрихкод',
    cell: info => <span className={styles.title}>{info.renderValue()}</span>,
  }),
  columnHelper.accessor('userShowName', {
    header: 'Оператор',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Принято',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
  }),
  columnHelper.accessor('dateUpdTs', {
    header: 'Завершено',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<ICompletedJob>(),
  }),
  columnHelper.accessor('awardsTotal', {
    header: 'Кол-во НЛ',
    cell: info =>
      info.getValue() < 0 ? null : (
        <Tag style={{ minWidth: '60px', margin: '0 auto' }}>{info.renderValue()}</Tag>
      ),
    size: 150,
    enableSorting: false,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во',
    cell: info => <Tag style={{ minWidth: '60px', margin: '0 auto' }}>{info.renderValue()}</Tag>,
    size: 150,
    enableSorting: false,
  }),
  columnHelper.accessor('importantPages', {
    header: 'Кол-во знач. стр.',
    cell: info =>
      info.getValue() < 0 ? null : (
        <Tag style={{ minWidth: '60px', margin: '0 auto' }}>{info.renderValue()}</Tag>
      ),
    size: 150,
    enableSorting: false,
  }),
];

const mapToCompleted = (job: IComplexInfo | IPrintInfo): ICompletedJob => {
  if ('author' in job) {
    return {
      id: +job.id,
      name: job.name,
      barcode: null,
      userShowName: job.userShowName,
      dateAddTs: job.dateAddTs,
      dateUpdTs: job.dateExecuteTs,
      awardsTotal: -1,
      pagesTotal: job.pagesTotal,
      importantPages: -1,
    };
  } else {
    return {
      id: +job.id,
      name: job.name ?? job.barcode,
      barcode: job.barcode,
      userShowName: job.userShowName,
      dateAddTs: job.dateAddTs,
      dateUpdTs: job.dateUpdTs,
      awardsTotal: job.awardsTotal,
      pagesTotal: job.pagesTotal,
      importantPages: job.importantPages,
    };
  }
};

const sortCompleted = (a: ICompletedJob, b: ICompletedJob) => {
  const dateA = a.dateUpdTs;
  const dateB = b.dateUpdTs;

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }

  return 0;
};

const CompletedGrid = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string | undefined>();

  const {
    data: printJobs,
    isFetching: printJobsLoading,
    refetch: refetchPrintJobs,
  } = useGetPrinjobListQuery(FINISHED_PRINT_JOBS, {
    pollingInterval: POLLING_INTERVAL_S,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: complexes,
    isFetching: complexesLoading,
    refetch: refetchComplexes,
  } = useGetComplexesListQuery(
    { states: FINISHED_COMPLEXES },
    {
      pollingInterval: POLLING_INTERVAL_S,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const refreshHandler = () => {
    refetchPrintJobs();
    refetchComplexes();
  };

  const rows = useMemo(() => {
    const res = [];

    if (filter === 'printing') {
      res.push(...(printJobs ?? []));
    } else if (filter === 'operator') {
      res.push(...(complexes?.results ?? []));
    } else if (!filter) {
      res.push(...(printJobs ?? []), ...(complexes?.results ?? []));
    }
    return res.map(mapToCompleted).sort(sortCompleted);
  }, [complexes, printJobs, filter]);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  const activeRow = useAppSelector(store =>
    selectionSelectors.selectSelectedRowIdByGridKey(store, QueryKeys.Completed),
  );

  const historyClickHandler = useCallback(() => {
    const path = generatePath(Routes.TaskHistory, {
      section: QueryKeys.Completed,
      taskId: activeRow,
    });
    navigate(path);
  }, [activeRow, navigate]);

  return (
    <div className={styles.listActions}>
      {}
      <DataGrid
        gridKey={QueryKeys.Completed}
        className={classNames(styles.grid, 'mr-4')}
        columns={columns}
        rows={rows}
        isLoading={printJobsLoading || complexesLoading}
        rowIdSelector={row => row.id.toString()}
        columnSettings={true}
        hideMyFilter
        filterColumn="name"
        dateFilterColumn="dateUpdTs"
        extraSetting={
          <Select
            isSearchable
            isClearable
            className="mr-4 w-30"
            placeholder="Тип здания"
            options={[
              { label: 'Задания оператору', value: 'operator' },
              { label: 'Задания на печать', value: 'printing' },
            ]}
            onChange={mode => {
              setFilter(mode ? (mode as ISelectOption).value : undefined);
            }}
          />
        }
      />
      <GridActions>
        <Button onClick={refreshHandler}>Обновить</Button>
        <Button disabled={!activeRow} onClick={historyClickHandler}>
          История обработки
        </Button>
      </GridActions>
    </div>
  );
};

export default CompletedGrid;
