import GridPage from '../gridPage/GridPage';
import Search from '../../../components/common/Search';
import CRInstancesActions from './CRInstancesActions';
import CRInstancesGrid from './CRInstancesGrid';

const CRInstancesPage = () => {
  return (
    <GridPage
      title="Настройка Content Reader"
      panel={<Search placeholder="Поиск" />}
      grid={<CRInstancesGrid />}
      actions={<CRInstancesActions />}
    />
  );
};

export default CRInstancesPage;
