import { useEffect } from 'react';
import { Outlet } from 'react-router';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';

import Header from './Header';
import { useCheckAuthQuery } from '../../redux/api/auth';
import Spinner from '../ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { userActions, userSelectors } from '../../redux/slices/user';

import styles from './Layout.module.scss';
// import { useHealthObserver } from '../../hooks/useHealthObserver';

interface Props {
  className?: string;
}

const Layout = ({ className }: Props) => {
  const authChecked = useAppSelector(userSelectors.selectAuthCheckedStatus);
  const dispatch = useAppDispatch();
  // useHealthObserver();

  const { data, error, isLoading } = useCheckAuthQuery(undefined, {
    skip: authChecked,
  });

  useEffect(() => {
    if (error && 'status' in error && error.status === 401) {
      dispatch(userActions.setAuthCheckedStatus(true));
      dispatch(userActions.setUser(null));
    }
  }, [error, dispatch]);

  useEffect(() => {
    if ((data || data === null) && !authChecked) {
      dispatch(userActions.setUser(data));
      dispatch(userActions.setAuthCheckedStatus(true));
    }
  }, [authChecked, data, dispatch]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Header className={styles.header} />
      <main>
        {isLoading && <Spinner />}
        <Outlet />
      </main>
      <ToastContainer />
    </div>
  );
};

export default Layout;
