export interface IFilter {
  important: boolean;
  notImportant: boolean;
  recognized: boolean;
  notRecognized: boolean;
}

export const ALL_FILTER: IFilter = {
  important: true,
  notImportant: true,
  recognized: true,
  notRecognized: true,
};

export const EMPTY_FILTER: IFilter = {
  important: false,
  notImportant: false,
  recognized: false,
  notRecognized: false,
};
