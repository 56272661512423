export enum QueryKeys {
  Running = 'running',
  Printing = 'printing',
  Operator = 'operator',
  Completed = 'completed',
  Users = 'users',
  PrintjobAuthors = 'authors',
  Printers = 'printers',
  Processing = 'processing',
  Folders = 'folders',
  DepartmentReport = 'departmentReport',
  UserReport = 'userReport',
  PetitionsReport = 'petitionsReport',
  SinglePetitionReport = 'singlePetitionReport',
  PrintingReport = 'printingReport',
  Petitions = 'petitions',
  Settings = 'settings',
  CRInstances = 'crinstances',
  Events = 'events',
  Processes = 'processes',
  Department = 'departments',
  Employee = 'employees',
  Division = 'division',
}
