import ModalHeading from '../ui/ModalHeading';
import Buttons from '../ui/Buttons';
import Button from '../ui/Button';

interface Props {
  title?: string;
  text?: string;
  onClose?: () => void;
  onConfirm: () => void;
  variant?: 'success' | 'danger';
}

const ConfirmationModal = ({
  title,
  text = 'Выбранная запись будет безвовратно удалена!',
  onClose,
  onConfirm,
  variant = 'success',
}: Props) => {
  return (
    <div>
      {title && <ModalHeading>{title}</ModalHeading>}
      <p className="ml-5 mt-7 mb-7">{text}</p>
      <Buttons>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button variant={variant} onClick={onConfirm}>
          OK
        </Button>
      </Buttons>
    </div>
  );
};

export default ConfirmationModal;
