import { useEffect, useState } from 'react';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import useModal from '../../../hooks/useModal';
import UserModal from './UserModal';
import { useDeleteUserMutation, usersApi } from '../../../redux/api/users';
import Spinner from '../../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { QueryKeys } from '../../../data/QueryKeys';

const UsersActions = () => {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Users),
  );

  const { modalOpenHandler, modalMarkup } = useModal(
    <UserModal userId={editing ? +selectedUser : undefined} />,
  );

  const [deleteUser, { isLoading: deletingUser, isSuccess }] = useDeleteUserMutation();

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalMarkup: confirmModal,
    modalCloseHandler,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deleteUser(+selectedUser)}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.Users));
      dispatch(
        usersApi.endpoints.getUsersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
    }
  }, [dispatch, isSuccess, modalCloseHandler]);

  return (
    <GridActions>
      {deletingUser && <Spinner />}

      <Button
        onClick={() => {
          setEditing(false);
          modalOpenHandler();
        }}
      >
        Создать пользователя
      </Button>
      <Button
        onClick={() => {
          setEditing(true);
          modalOpenHandler();
        }}
        disabled={!selectedUser}
      >
        Редактировать пользователя
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedUser}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {modalMarkup}
      {confirmModal}
    </GridActions>
  );
};

export default UsersActions;
