import { DayPicker } from 'react-day-picker';
import { useCallback, useEffect, useState } from 'react';
import { ru } from 'date-fns/locale';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { RxCalendar } from 'react-icons/rx';

import TextInput, { TextInputProps } from './TextInput';
import Card from './Card';
import Button from './Button';
import PopperButton from './PopperButton';

import styles from './DateInput.module.scss';
import { DEFAULT_DATE_FORMAT } from '../../utils/date';

interface Props
  extends Omit<TextInputProps, 'type' | 'buttonIcon' | 'onButtonClick' | 'value' | 'onChange'> {
  value?: DateTime;
  onChange?: (value: DateTime | undefined) => void;
}

const MATRIX = '__.__.____';
const RegEx = /[^\d]/g;

const DateInput = ({ value, onChange, className, ...rest }: Props) => {
  const [selected, setSelected] = useState<DateTime | undefined>(value);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const popupCloseHandler = useCallback(() => setOpen(false), []);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.addEventListener('click', popupCloseHandler, { once: true });
      }, 10);
    }
  }, [open, popupCloseHandler]);

  useEffect(() => {
    setSelected(value);
    setText(value?.toFormat(DEFAULT_DATE_FORMAT) ?? '');
  }, [value]);

  const daySelectHandler = useCallback(
    (value: Date | undefined) => {
      const date = value ? DateTime.fromJSDate(value) : undefined;
      onChange?.(date);
      setSelected(date);
    },
    [onChange],
  );

  const todayClickHandler = useCallback(() => {
    const todayDate = DateTime.now();
    setSelected(todayDate);
    setText(todayDate.toFormat(DEFAULT_DATE_FORMAT));
    onChange?.(todayDate);
  }, [onChange]);

  const btn = (
    <Button viewType="round">
      <RxCalendar />
    </Button>
  );

  const dropDown = (
    <Card className={styles.popup}>
      <DayPicker
        mode="single"
        initialFocus={open}
        defaultMonth={selected?.toJSDate()}
        selected={selected?.toJSDate()}
        onSelect={daySelectHandler}
        locale={ru}
      />
      <div className={styles.todayWrapper}>
        <Button onClick={todayClickHandler} className={styles.todayBtn}>
          Сегодня
        </Button>
      </div>
    </Card>
  );

  const handleTextChange = useCallback(
    (text: string) => {
      const def = MATRIX.replace(RegEx, '');
      let val = text.replace(RegEx, '');
      let i = 0;

      if (def.length >= val.length) {
        val = def;
      }

      text = MATRIX.replace(/./g, a => {
        if (/[_^]/.test(a) && i < val.length) {
          return val.charAt(i++);
        } else if (i >= val.length) {
          return '';
        } else {
          return a;
        }
      });

      setText(text);

      if (text.length === 0) {
        setSelected(undefined);
        onChange?.(undefined);
        return;
      }

      const dateTime = DateTime.fromFormat(text, DEFAULT_DATE_FORMAT);

      if (!dateTime.isValid) return;

      setSelected(dateTime);
      onChange?.(dateTime);
    },
    [onChange],
  );

  return (
    <div className={classNames(styles.root, className)}>
      <TextInput type="text" {...rest} value={text} onChange={handleTextChange} />
      <PopperButton
        button={btn}
        dropDown={dropDown}
        className={styles.calendar}
        closeClasses={['rdp-day', styles.todayBtn]}
      />
    </div>
  );
};

export default DateInput;
