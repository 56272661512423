import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import { useAppSelector } from '../../../redux/hooks';
import { selectionSelectors } from '../../../redux/slices/selection';
import useModal from '../../../hooks/useModal';
import SettingsModal from './SettingsModal';
import { QueryKeys } from '../../../data/QueryKeys';

const SettingsActions = () => {
  const selectedSetting = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Settings),
  );

  const { modalOpenHandler, modalMarkup } = useModal(<SettingsModal id={selectedSetting} />);

  return (
    <GridActions>
      <Button disabled={!selectedSetting} onClick={modalOpenHandler}>
        Изменить
      </Button>
      {modalMarkup}
    </GridActions>
  );
};

export default SettingsActions;
