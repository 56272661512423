import classNames from 'classnames';

import styles from './Indicator.module.scss';

interface Props {
  active: boolean;
  variant: 'success' | 'danger';
  className?: string;
}

const Indicator = ({ active, variant, className }: Props) => {
  return (
    <span
      className={classNames(styles.root, active && styles.active, styles[variant], className)}
    />
  );
};

export default Indicator;
