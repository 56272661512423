import { useCallback, useEffect, useState } from 'react';
import {
  printjobsApi,
  useDeletePrintjobMutation,
  useGetPrinjobListQuery,
} from '../../../redux/api/printjobs';
import { createColumnHelper } from '@tanstack/react-table';
import { IPrintInfo, PrintJobStateEnum } from '../../../types/IPrintInfo';
import { POLLING_INTERVAL_S } from '../../../const';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import Tag from '../../../components/ui/Tag';
import { generatePath, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import useModal from '../../../hooks/useModal';
import { Routes } from '../../../Router';
import DataGrid from '../../../components/grid/DataGrid';
import classNames from 'classnames';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';

import styles from './Grid.module.scss';
import { QueryKeys } from '../../../data/QueryKeys';
import { PRINT_JOBS } from './filters';
import { getReadableDateTime } from '../../../utils/date';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper<IPrintInfo>();

const columns = [
  columnHelper.display({
    id: 'index',
    header: '#',
    cell: ({ row }) => row.index + 1,
    size: 50,
  }),
  columnHelper.accessor('author', {
    header: 'От кого',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<IPrintInfo>(),
  }),
  columnHelper.accessor('state', {
    header: 'Статус',
    cell: info => (
      <Tag variant={info.getValue() === 'fail' ? 'low' : 'status'} style={{ minWidth: '200px' }}>
        {PrintJobStateEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во стр.',
    cell: info =>
      info.getValue() ? (
        <Tag style={{ minWidth: '60px', margin: '0 auto' }}>{info.renderValue()}</Tag>
      ) : null,
    size: 150,
    enableSorting: false,
  }),
];

const PrintingGrid = () => {
  const { data, isFetching, refetch } = useGetPrinjobListQuery(PRINT_JOBS, {
    pollingInterval: POLLING_INTERVAL_S,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [jobName, setJobName] = useState<string>();

  const [deletePrintjob, { isLoading, isSuccess }] = useDeletePrintjobMutation();

  const activeRow = useAppSelector(store =>
    selectionSelectors.selectSelectedRowIdByGridKey(store, QueryKeys.Printing),
  );

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalCloseHandler,
    modalMarkup: confirmModal,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deletePrintjob(activeRow)}
    />,
  );

  const historyClickHandler = useCallback(() => {
    const path = generatePath(Routes.TaskHistory, {
      section: QueryKeys.Printing,
      taskId: activeRow!,
    });
    navigate(path);
  }, [activeRow, navigate]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.Printing));
      dispatch(
        printjobsApi.endpoints.getPrinjobList.initiate(PRINT_JOBS, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
      toast.success(`Задание ${jobName} успешно удалено!`);
      setJobName(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSuccess, modalCloseHandler]);

  return (
    <div className={styles.listActions}>
      {isLoading && <Spinner />}
      <DataGrid
        gridKey={QueryKeys.Printing}
        className={classNames(styles.grid, 'mr-4')}
        columns={columns}
        rows={data}
        isLoading={isFetching}
        rowIdSelector={row => row.id}
        onRowClick={row => setJobName(row.original.name)}
        columnSettings={true}
        stateString="Всего заданий на печать"
        hideMyFilter
        filterColumn="author"
        dateFilterColumn="dateAddTs"
      />
      <GridActions>
        <Button
          disabled={!activeRow}
          onClick={() => {
            const path = generatePath(Routes.Details, {
              section: QueryKeys.Printing,
              taskId: activeRow,
            });
            navigate(path);
          }}
        >
          Обработать
        </Button>
        <Button onClick={refetch}>Обновить</Button>
        <Button disabled={!activeRow} onClick={historyClickHandler}>
          История обработки
        </Button>
        <Button
          className="mt-12"
          variant="danger"
          disabled={!activeRow}
          onClick={confirmModalOpenHandler}
        >
          Удалить
        </Button>
      </GridActions>
      {confirmModal}
    </div>
  );
};

export default PrintingGrid;
