import Tag from '../ui/Tag';

interface Props {
  isError: boolean;
}

const ErrorCell = ({ isError }: Props) => {
  return (
    <Tag variant={isError ? 'low' : 'status'} style={{ minWidth: '60px', margin: '0 auto' }}>
      {isError ? 'Да' : 'Нет'}
    </Tag>
  );
};
export default ErrorCell;
