import GridPage from '../gridPage/GridPage';
import FoldersGrid from './FoldersGrid';
import FoldersActions from './FoldersActions';
import { useState } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';
import FoldersFilters from './FoldersFilters';

const FoldersPage = () => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <GridPage
      title="Настройка сетевых папок"
      panel={<FoldersFilters filterSetter={setColumnFilters} />}
      grid={<FoldersGrid filters={columnFilters} />}
      actions={<FoldersActions />}
    />
  );
};

export default FoldersPage;
