import { IPrintInfo } from '../../types/IPrintInfo';
import { IPrintJob } from '../../types/IPrintJob';
import { PrinterTaskFinal } from '../../types/IPrinterTask';
import { TId } from '../../types/TId';
import { api } from '../api';

export const printjobsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPrinjobList: builder.query<IPrintInfo[], string | void>({
      query: filters => 'api/print-jobs' + (filters ? `?states=${filters}` : ''),
    }),
    getPrintjobById: builder.query<IPrintJob, TId>({
      query: id => `api/print-jobs/${id}`,
    }),
    deletePrintjob: builder.mutation<string, TId>({
      query: id => ({
        url: `api/print-jobs/${id}`,
        method: 'DELETE',
      }),
    }),
    printPrintjob: builder.mutation<string, PrinterTaskFinal>({
      query: body => ({
        url: `api/print-jobs/${body.printJobId}/print`,
        method: 'POST',
        body,
      }),
    }),
    finishPrintjob: builder.mutation<string, TId>({
      query: id => ({
        url: `api/print-jobs/${id}/done`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetPrinjobListQuery,
  useGetPrintjobByIdQuery,
  useDeletePrintjobMutation,
  usePrintPrintjobMutation,
  useFinishPrintjobMutation,
} = printjobsApi;
