import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../../types/IUser';

export interface IUserState {
  authChecked: boolean;
  data: IUser | null;
}

const initialState: IUserState = {
  authChecked: false,
  data: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthCheckedStatus: (state, action: PayloadAction<boolean>) => {
      state.authChecked = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.data = action.payload;
    },
    resetUser: state => {
      state.data = null;
    },
  },
});

export const { setUser, resetUser, setAuthCheckedStatus } = userSlice.actions;

export default userSlice.reducer;
