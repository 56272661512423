import classNames from 'classnames';

import styles from './Spinner.module.scss';

interface Props {
  block?: boolean;
  inline?: boolean;
  transparent?: boolean;
}

const Spinner = ({ block, inline, transparent }: Props) => {
  return (
    <div
      className={classNames({
        [styles.blockContainer]: block,
        [styles.inlineContainer]: inline,
        [styles.fixedContainer]: !inline && !block,
        [styles.transparentContainer]: transparent,
      })}
    >
      <div className={styles.spinner} />
    </div>
  );
};

export default Spinner;
