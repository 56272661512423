import { ReactNode, createElement } from 'react';
import classNames from 'classnames';

import styles from './Heading.module.scss';

interface Props {
  children: ReactNode;
  level?: 1 | 2 | 3 | 4 | 5;
  className?: string;
}

const Heading = ({ children, level = 3, className }: Props) => {
  return createElement(
    `h${level}`,
    {
      className: classNames(styles.heading, className),
    },
    children,
  );
};

export default Heading;
