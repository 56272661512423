import { api } from '../api';

export const countApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCount: builder.query<number, { type: 'complexes' | 'print-jobs'; states: string }>({
      query: ({ type, states }) => `api/${type}/count?states=${states}`,
    }),
  }),
});

export const { useGetCountQuery } = countApi;
