import { useCallback } from 'react';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { reportActions, reportSelectors } from '../../../../redux/slices/reports';
import { DateTime } from 'luxon';

const DepartmentsReportFilters = () => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(reportSelectors.selectDepartmentsReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectDepartmentsReportEndDate);

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      dispatch(
        reportActions.setRange({
          report: 'departments',
          range: { start: dates.start?.toString(), end: dates.end?.toString() },
        }),
      );
    },
    [dispatch],
  );

  return (
    <DateRangeInput
      wrapped
      className="mr-4"
      onChange={dateChangeHandler}
      value={{
        start: startDate ? DateTime.fromISO(startDate) : undefined,
        end: endDate ? DateTime.fromISO(endDate) : undefined,
      }}
    />
  );
};

export default DepartmentsReportFilters;
