import { RouterProvider } from 'react-router-dom';
import router from './Router';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import notifSound from './sounds/incoming.mp3';

import { selectNewPrintTaskCount } from './redux/slices/notification/selectors';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { setPrintTaskCount } from './redux/slices/notification/slice';
import { useGetCountQuery } from './redux/api/count';
import { PRINT_JOBS } from './pages/main/grids/filters';
import { POLLING_INTERVAL_S } from './const';
import { userSelectors } from './redux/slices/user';

const App = () => {
  const dispatch = useAppDispatch();
  const newPrintTaskCount = useAppSelector(selectNewPrintTaskCount);
  const user = useAppSelector(userSelectors.selectUserData);
  const played = useRef(0);

  const { data } = useGetCountQuery(
    { type: 'print-jobs', states: PRINT_JOBS },
    {
      pollingInterval: POLLING_INTERVAL_S,
      skip: !user,
    },
  );

  const notification = useMemo(() => {
    return new Audio(notifSound);
  }, []);

  const playHandler = useCallback(() => {
    played.current++;
  }, []);

  const playEndHandler = useCallback(() => {
    if (played.current < 3) {
      notification.play();
    }
  }, [notification, played]);

  useEffect(() => {
    notification.addEventListener('play', playHandler);
    notification.addEventListener('ended', playEndHandler);
  }, [notification, playEndHandler, playHandler]);

  useEffect(() => {
    if (data || data === 0) {
      dispatch(setPrintTaskCount(data));
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (data && data > newPrintTaskCount) {
      played.current = 0;
      notification.play().catch(err => console.error(err));
    }
  }, [data, newPrintTaskCount, notification]);

  return <RouterProvider router={router} />;
};

export default App;
