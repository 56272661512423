import { createColumnHelper } from '@tanstack/react-table';
import { IDivision } from '../../../types/IDivision';
import { useGetDivisionsListQuery } from '../../../redux/api/divisions';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';

const columnHelper = createColumnHelper<IDivision>();

const columns = [
  // columnHelper.accessor('id', {
  //   header: 'ID',
  //   cell: info => info.renderValue(),
  //   size: 80,
  // }),
  columnHelper.accessor('name', {
    header: 'Наименование',
    cell: info => info.renderValue(),
  }),
];

const DivisionsGrid = () => {
  const { data, isLoading } = useGetDivisionsListQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.Division}
      rows={data}
      isLoading={isLoading}
      columns={columns}
      rowIdSelector={row => row.id.toString()}
      filterColumn="name"
      hideAllSettings
    />
  );
};
export default DivisionsGrid;
