import GridActions from '../../../../components/common/GridActions';
import Button from '../../../../components/ui/Button';
import { IStatOptions, STAT_DATA_KEYS } from '../../../../redux/api/statistic';
import { getSearchParams } from '../../../../utils/searchParams';
import { useAppSelector } from '../../../../redux/hooks';
import { selectionSelectors } from '../../../../redux/slices/selection';
import { QueryKeys } from '../../../../data/QueryKeys';
import { useNavigate } from 'react-router';
import { BASE_URL } from '../../../../const';
interface Props {
  fetchOptions: IStatOptions;
}

const PetitionsReportActions = ({ fetchOptions }: Props) => {
  const params = getSearchParams(fetchOptions, STAT_DATA_KEYS);
  const navigate = useNavigate();

  const selectedPetition = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.PetitionsReport),
  );

  return (
    <GridActions className="mt-11">
      <Button
        disabled={!selectedPetition}
        onClick={() => navigate(`/reports/${QueryKeys.Petitions}/${selectedPetition}`)}
      >
        Статистика
      </Button>
      <Button link={`${BASE_URL}api/statistics/complexes/export${params}`} download>
        Экспорт
      </Button>
    </GridActions>
  );
};

export default PetitionsReportActions;
