import { Dispatch, SetStateAction, useCallback } from 'react';
import { IGridOptions } from '../types/IGridOptions';
import { PaginationState, SortingState } from '@tanstack/react-table';

export function useServerSortingPagination<T extends IGridOptions>(
  fetchOptionsSetter: Dispatch<SetStateAction<T>>,
) {
  const paginationChangeHandler = useCallback(
    (paginationState: PaginationState) => {
      fetchOptionsSetter(prev => ({
        ...prev,
        page: paginationState.pageIndex,
        perPage: paginationState.pageSize,
      }));
    },
    [fetchOptionsSetter],
  );

  const sortingChangeHandler = useCallback(
    (sortingState: SortingState) => {
      if (!sortingState.length) {
        fetchOptionsSetter(prev => ({
          ...prev,
          order: undefined,
          sort: undefined,
        }));
      } else {
        fetchOptionsSetter(prev => ({
          ...prev,
          sort: sortingState[0].id,
          order: sortingState[0].desc ? 'desc' : 'asc',
        }));
      }
    },
    [fetchOptionsSetter],
  );

  return { paginationChangeHandler, sortingChangeHandler };
}
