import { useEffect, useState } from 'react';
import classNames from 'classnames';
import QRCode from 'qrcode';

import styles from './QrCode.module.scss';

interface Props {
  label?: string;
  data: string;
  width?: number;
  margin?: number;
  className?: string;
}

const QrCode = ({ data, label, width = 120, margin = 2, className }: Props) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    QRCode.toDataURL(data, { type: 'image/png', margin, width })
      .then(setCode)
      .catch(err => console.error(err));
  }, [data, width, margin]);

  return (
    <div className={classNames(styles.root, className)}>
      {label && <p className={styles.label}>{label}</p>}
      <img className={styles.img} src={code} />
    </div>
  );
};

export default QrCode;
