import { createColumnHelper } from '@tanstack/react-table';
import Tag from '../../../components/ui/Tag';
import { useGetUsersListQuery } from '../../../redux/api/users';
import DataGrid from '../../../components/grid/DataGrid';
import { IUserInfo, RoleEnum } from '../../../types/IUserInfo';
import { QueryKeys } from '../../../data/QueryKeys';

const columnHelper = createColumnHelper<IUserInfo>();

const columns = [
  columnHelper.accessor('showName', {
    header: 'ФИО',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('role', {
    header: 'Роль',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', margin: '0 auto' }}>
        {RoleEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('userName', {
    header: 'Логин',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('key', {
    header: 'Ключ',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('active', {
    header: 'Активность',
    cell: info => (
      <Tag
        variant={info.getValue() ? 'status' : 'low'}
        style={{ minWidth: '60px', margin: '0 auto' }}
      >
        {info.getValue() ? 'Да' : 'Нет'}
      </Tag>
    ),
  }),
];

const UsersGrid = () => {
  const { data, isLoading } = useGetUsersListQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.Users}
      rows={data}
      isLoading={isLoading}
      columns={columns}
      rowIdSelector={row => row.id.toString()}
      filterColumn="showName"
      hideAllSettings
    />
  );
};

export default UsersGrid;
