import { SheetFormat } from './SheetFormat';
import { TId } from './TId';

export interface IImage {
  id: TId;
  filename: string;
  format: SheetFormat;
  rotation: number;
  important: boolean;
  recognized: boolean;
  needRecognize: boolean;
  inserted?: boolean;
  group: number | null;
}

export enum ImageState {
  NotRecognized = 'notrec',
  Deleted = 'deleted',
  Added = 'added',
  Empty = 'empty',
  Inserted = 'inserted',
}
