import { ICRInstance } from '../../types/ICRInstance';
import { TId } from '../../types/TId';
import { api } from '../api';

export const crInstancesApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getCRInstancesList: builder.query<ICRInstance[], void>({
      query: () => 'api/content-readers',
    }),
    getCRInstanceById: builder.query<ICRInstance, TId>({
      query: id => ({
        url: `api/content-readers/${id}`,
      }),
      forceRefetch: () => true,
    }),
    createCRInstance: builder.mutation<string, Omit<ICRInstance, 'id'>>({
      query: body => ({
        url: 'api/content-readers',
        method: 'POST',
        body,
      }),
    }),
    editCRInstance: builder.mutation<ICRInstance, ICRInstance>({
      query: body => ({
        url: `api/content-readers/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCRInstance: builder.mutation<string, string>({
      query: id => ({
        url: `api/content-readers/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCRInstancesListQuery,
  useGetCRInstanceByIdQuery,
  useCreateCRInstanceMutation,
  useEditCRInstanceMutation,
  useDeleteCRInstanceMutation,
} = crInstancesApi;
