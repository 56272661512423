import classNames from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';

import SearchTabs from './SearchTabs';
import Container from '../../components/ui/Container';

import styles from './MainPage.module.scss';
import { useAppSelector } from '../../redux/hooks';
import { userSelectors } from '../../redux/slices/user';
import { useEffect } from 'react';
import { Role } from '../../types/IUserInfo';
import { Routes } from '../../Router';

const MainPage = () => {
  const navigate = useNavigate();
  const { data } = useAppSelector(userSelectors.selectUserState);

  useEffect(() => {
    if (data?.role === Role.Admin) {
      navigate(Routes.Users);
    }
  }, [data, navigate]);

  return (
    <Container className={classNames(styles.mainPage, 'pt-4 pb-4')}>
      <SearchTabs className={classNames(styles.search, 'mb-4')} />
      <Outlet />
    </Container>
  );
};

export default MainPage;
