import classNames from 'classnames';
import { ReactElement, forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outlined' | 'success' | 'danger' | 'primary' | 'plain';
  icon?: ReactElement;
  viewType?: 'round' | 'small' | 'toolbar' | 'toolbarText';
  link?: string;
  size?: 'big';
  download?: boolean;
  selected?: boolean;
}

const Button = forwardRef(
  (
    {
      children,
      icon,
      className,
      variant,
      viewType,
      link,
      download,
      size,
      selected,
      onClick,
      ...restProps
    }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const buttonClickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        e.currentTarget.blur();
        onClick?.(e);
      },
      [onClick],
    );

    const classes = classNames(
      styles.root,
      variant && styles[variant],
      viewType && styles[viewType],
      selected && styles.selected,
      size && styles[size],
      className,
    );

    if (link && !download) {
      return (
        <Link to={link} className={classes}>
          {children}
        </Link>
      );
    }

    if (link && download) {
      return (
        <a href={link} className={classes} download>
          {children}
        </a>
      );
    }

    return (
      <button ref={ref} className={classes} onClick={buttonClickHandler} {...restProps}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {children}
      </button>
    );
  },
);

export default Button;
