import { Enum } from '../data/enum';

export interface ICRInstance {
  id: string;
  name: string;
  url: string;
  threads: number;
  active: boolean;
  status: string;
  properties: {
    orientationDetectionMode?: OrientationDetectionMode;
    recognitionMode?: RecognitionMode;
  };
}

export enum OrientationDetectionMode {
  Fast = 'Fast',
  Normal = 'Normal',
  Thorough = 'Thorough',
}

export const OrientationDetectionModeEnum = new Enum<OrientationDetectionMode>([
  [OrientationDetectionMode.Fast, 'Быстрый'],
  [OrientationDetectionMode.Normal, 'Нормальный'],
  [OrientationDetectionMode.Thorough, 'Тщательный'],
]);

export enum RecognitionMode {
  Fast = 'Fast',
  Normal = 'Normal',
  Accurate = 'Accurate',
}

export const RecognitionModeEnum = new Enum<RecognitionMode>([
  [RecognitionMode.Fast, 'Быстрый'],
  [RecognitionMode.Normal, 'Нормальный'],
  [RecognitionMode.Accurate, 'Тщательный'],
]);
