import { ISelectOption } from './../types/ISelectOption';
import { Enum } from '../data/enum';
import { IUserInfo } from '../types/IUserInfo';

export const getGroupedOptions = <T extends string>(en: Enum<T>): ISelectOption[] => {
  const resultMap: Record<string, string> = {};

  en.items.forEach(option => {
    if (!resultMap[option.displayName]) {
      resultMap[option.displayName] = option.value;
    } else {
      resultMap[option.displayName] += `,${option.value}`;
    }
  });

  return Object.keys(resultMap)
    .sort()
    .map(key => ({ label: key, value: resultMap[key] }));
};

export const getOptions = <T extends string>(en: Enum<T>): ISelectOption[] => {
  return en.items
    .map(a => ({ label: a.displayName, value: a.value }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getSortedUsers = (users: IUserInfo[] | undefined, valueKey: keyof IUserInfo) =>
  users
    ?.map(user => ({ label: user.showName, value: user[valueKey] }))
    .sort((a, b) => a.label.localeCompare(b.label));
