import { useCallback, useEffect } from 'react';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionSelectors } from '../../../redux/slices/selection';
import { generatePath, useNavigate } from 'react-router';
import { Routes } from '../../../Router';
import { QueryKeys } from '../../../data/QueryKeys';
import {
  complexesApi,
  useDeleteComplexMutation,
  useGetComplexesListQuery,
  useRetryComplexMutation,
  useRevertComplexMutation,
  usePriorityComplexMutation,
  IComplexesOptions,
} from '../../../redux/api/complexes';
import Spinner from '../../../components/ui/Spinner';
import { toast } from 'react-toastify';
import useModal from '../../../hooks/useModal';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { ComplexState, IComplexInfo } from '../../../types/IComplexInfo';

interface Props {
  selectedRow: IComplexInfo | undefined;
  fetchOptions: IComplexesOptions;
}

const ProcessingActions = ({ selectedRow, fetchOptions }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedComplex = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Processing),
  );

  const { refetch } = useGetComplexesListQuery(fetchOptions);

  const [revertComplex, { isLoading: revertingComplex, isSuccess: revertingSuccess }] =
    useRevertComplexMutation();

  const [retryComplex, { isLoading: retryingComplex, isSuccess: retryingSuccess }] =
    useRetryComplexMutation();

  const [priorityComplex, { isLoading: prioritizingComplex, isSuccess: prioritizingSuccess }] =
    usePriorityComplexMutation();

  useEffect(() => {
    if (revertingSuccess || retryingSuccess || prioritizingSuccess) {
      toast.success('Действие выполнено!');
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(fetchOptions, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [dispatch, revertingSuccess, retryingSuccess, prioritizingSuccess, fetchOptions]);

  const [deleteComplex, { isLoading: deleteing, isSuccess: deleteSuccess }] =
    useDeleteComplexMutation();

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(fetchOptions, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [deleteSuccess, dispatch, fetchOptions]);

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalCloseHandler: confirmModalCloseHandler,
    modalMarkup: confirmModal,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => {
        deleteComplex(selectedComplex);
        confirmModalCloseHandler();
      }}
    />,
  );

  const historyClickHandler = useCallback(() => {
    const path = generatePath(Routes.TaskHistory, {
      section: QueryKeys.Running,
      taskId: selectedComplex!,
    });
    navigate(path);
  }, [selectedComplex, navigate]);

  const revertHandler = useCallback(() => {
    if (!selectedComplex) return toast.error('Не удалось определить идентификатор процесса!');
    revertComplex(selectedComplex);
  }, [revertComplex, selectedComplex]);

  const retryHandler = useCallback(() => {
    if (!selectedComplex) return toast.error('Не удалось определить идентификатор процесса!');
    retryComplex(selectedComplex);
  }, [retryComplex, selectedComplex]);

  const priorityHandler = useCallback(() => {
    if (!selectedComplex) return toast.error('Не удалось определить идентификатор процесса!');
    priorityComplex(selectedComplex);
  }, [priorityComplex, selectedComplex]);

  return (
    <GridActions>
      {(revertingComplex || deleteing || retryingComplex || prioritizingComplex) && <Spinner />}
      <Button onClick={refetch}>Обновить</Button>
      <Button disabled={!selectedComplex} onClick={historyClickHandler}>
        История обработки
      </Button>
      <Button
        disabled={!selectedComplex || selectedRow?.state === ComplexState.Deleted}
        onClick={priorityHandler}
      >
        Повысить приоритет
      </Button>
      <Button
        disabled={!selectedComplex || selectedRow?.state === ComplexState.Deleted}
        onClick={revertHandler}
      >
        Откатить
      </Button>
      <Button
        disabled={!selectedComplex || selectedRow?.state === ComplexState.Deleted}
        onClick={retryHandler}
      >
        Повторить
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedComplex || selectedRow?.state === ComplexState.Deleted}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {confirmModal}
    </GridActions>
  );
};

export default ProcessingActions;
