import Search from '../../../components/common/Search';
import Button from '../../../components/ui/Button';
import { Routes } from '../../../Router';
import GridPage from '../gridPage/GridPage';
import PrintjobAuthorsActions from './PrintjobAuthorsActions';
import PrintjobAuthorsGrid from './PrintjobAuthorsGrid';

const PrintjobAuthorsPage = () => {
  return (
    <GridPage
      title="Сотрудники управлений"
      panel={
        <>
          <Search placeholder="Введите ФИО" />
          <Button className="ml-a" link={Routes.Divisions}>
            Управления
          </Button>
        </>
      }
      grid={<PrintjobAuthorsGrid />}
      actions={<PrintjobAuthorsActions />}
    />
  );
};
export default PrintjobAuthorsPage;
