import { useLocation, useParams } from 'react-router';
import { useGetComplexByIdQuery } from '../../redux/api/complexes';
import { useEffect, useMemo, useState } from 'react';
import Spinner from '../../components/ui/Spinner';
import { ITag } from '../../redux/slices/headerInfo/slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { headerInfoActions } from '../../redux/slices/headerInfo';
import { ComplexState, ComplexTypeEnum } from '../../types/IComplexInfo';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import DetailPage from '../details/DetailPage';
import ItemList from './ItemList';
import OperatorTaskToolbar from './OperatorTaskToolbar';
import ItemView from './ItemView';
import { toast } from 'react-toastify';

const OperatorTask = () => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const notRecognizedTotal = useAppSelector(operatorTaskSelectors.selectNotRecognizedTotal);
  const [asedNotificationShown, setAsedNotificationShown] = useState(false);

  const { data, isLoading } = useGetComplexByIdQuery(taskId!, {
    skip: !taskId,
    refetchOnMountOrArgChange: true,
  });

  const readOnly = useMemo(() => {
    return data?.state === ComplexState.PartsReady;
  }, [data]);

  useEffect(() => {
    if (data) {
      const items: (ITag | null)[] = [
        readOnly
          ? null
          : {
              text: data.name ?? '-',
              variant: 'status',
              button: true,
            },
        readOnly
          ? null
          : {
              text: `Указ. НЛ: -`,
              modal: true,
            },
        {
          text: data.barcode ?? '-',
        },
        {
          text: ComplexTypeEnum.getDisplayName(data.type),
        },
        {
          text: `Расп. НЛ: ${data.awardsTotal ?? '-'}`,
        },
        {
          text: `Всего страниц: ${data.pagesTotal ?? '-'}`,
        },
        {
          text: `Кол-во знач.:${data.importantPages ?? '-'}`,
        },
        {
          text: `Кол-во нерасп.: ${notRecognizedTotal}`,
        },
      ];

      dispatch(headerInfoActions.setHeaderInfoContent(items));
    }
    return () => {
      dispatch(headerInfoActions.setHeaderInfoContent([]));
    };
  }, [data, dispatch, location.pathname, notRecognizedTotal, readOnly]);

  useEffect(() => {
    if (data) {
      dispatch(operatorTaskActions.setNormalizedTask(data));
      dispatch(operatorTaskActions.setActiveDocument(null));
    }

    if ((data?.copiedToSed === true || data?.copiedToSed === false) && !asedNotificationShown) {
      toast.warn(
        data.copiedToSed ? 'Не забудьте проверить задание в АСЭД' : 'Ошибка копирования в АСЭД',
        { autoClose: false, position: 'bottom-right' },
      );
      setAsedNotificationShown(true);
    }

    return () => {
      dispatch(operatorTaskActions.setNormalizedTask(null));
    };
  }, [dispatch, data, asedNotificationShown]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <DetailPage
      isLoading={isLoading}
      aside={<ItemList readOnly={readOnly} />}
      toolbar={<OperatorTaskToolbar readOnly={readOnly} />}
      content={isLoading ? <Spinner /> : <ItemView readOnly={readOnly} />}
    />
  );
};
export default OperatorTask;
