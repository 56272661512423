import { createColumnHelper } from '@tanstack/react-table';
import Tag from '../../../components/ui/Tag';
import { IPrinter } from '../../../types/IPrinter';
import { useGetPrintersListQuery } from '../../../redux/api/printers';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import ColorIndicator from '../../../components/common/ColorIndicator';

const columnHelper = createColumnHelper<IPrinter>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Наименование',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('host', {
    header: 'Адрес',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', margin: '0 auto' }}>
        {info.getValue()}
      </Tag>
    ),
  }),
  columnHelper.accessor('path', {
    header: 'Путь IPP',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('active', {
    header: 'Активность',
    cell: info => (
      <Tag
        variant={info.getValue() ? 'status' : 'low'}
        style={{ minWidth: '60px', margin: '0 auto' }}
      >
        {info.getValue() === true ? 'Да' : 'Нет'}
      </Tag>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'Статус',
    cell: info => (
      <ColorIndicator
        style={{ marginLeft: '20px' }}
        color={info.row.original.active ? (info.getValue() === 'alive' ? 'green' : 'red') : 'grey'}
        hint={info.getValue() === 'alive' ? 'Доступен' : 'Недоступен'}
      />
    ),
  }),
];

const PrintersGrid = () => {
  const { data, isLoading } = useGetPrintersListQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.Printers}
      columns={columns}
      rows={data}
      isLoading={isLoading}
      rowIdSelector={row => row.id}
      filterColumn="name"
      hideAllSettings
    />
  );
};

export default PrintersGrid;
