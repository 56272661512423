import Button from '../ui/Button';
import styles from './PageError.module.scss';
import logo from '../../images/logo.png';

const PageError = () => {
  const refreshHandler = () => {
    location.reload();
  };

  return (
    <div className={styles.root}>
      <img src={logo} alt="Лого" className={styles.logo} />
      <h1>Что-то пошло не так!</h1>
      <Button onClick={refreshHandler}>Обновить</Button>
    </div>
  );
};
export default PageError;
