import { IComplexesStat, useGetComplexesStatQuery } from '../../../../redux/api/statistic';
import { createColumnHelper } from '@tanstack/react-table';
import Tag from '../../../../components/ui/Tag';
import DataGrid from '../../../../components/grid/DataGrid';
import { QueryKeys } from '../../../../data/QueryKeys';
import { useAppSelector } from '../../../../redux/hooks';
import { reportSelectors } from '../../../../redux/slices/reports';

const columnHelper = createColumnHelper<IComplexesStat>();

const columns = [
  columnHelper.accessor('petitionsTotal', {
    header: 'Кол-во ходатайств',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
    enableSorting: false,
  }),
  columnHelper.accessor('awardsTotal', {
    header: 'Кол-во НЛ',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
    enableSorting: false,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во обр.',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
    enableSorting: false,
  }),
  columnHelper.accessor('sentErrorsTotal', {
    header: 'Кол-во ошибок в ИС Н',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
    enableSorting: false,
  }),
];

const DepartmentsReportGrid = () => {
  const startDate = useAppSelector(reportSelectors.selectDepartmentsReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectDepartmentsReportEndDate);
  const { data, isFetching } = useGetComplexesStatQuery(
    { dateFromTs: startDate, dateToTs: endDate },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <DataGrid
      gridKey={QueryKeys.DepartmentReport}
      rows={data ? [data] : []}
      totalCount={1}
      pagesCount={1}
      isLoading={isFetching}
      columns={columns}
      hideAllSettings
      serverControlled={true}
      hidePagination
    />
  );
};

export default DepartmentsReportGrid;
