import classNames from 'classnames';
import { Table, flexRender } from '@tanstack/react-table';

import { SortingIcon } from '../ui/icons/Icons';

import styles from './GridHeader.module.scss';

interface Props<T> {
  table: Table<T>;
}

const GridHeader = <T,>({ table }: Props<T>) => {
  return (
    <thead className={styles.root}>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <th
              key={header.id}
              style={{
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder ? null : (
                <div
                  className={header.column.getCanSort() ? styles.sortable : ''}
                  onClick={
                    header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined
                  }
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanSort() ? (
                    <span
                      className={classNames(
                        styles.sortingIcon,
                        styles[header.column.getIsSorted() as string],
                      )}
                    >
                      <SortingIcon />
                    </span>
                  ) : null}
                </div>
              )}
              <div
                {...{
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  className: `${styles.resizer} ${
                    header.column.getIsResizing() ? styles.isResizing : ''
                  }`,
                  style: {
                    transform: '',
                  },
                }}
              />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default GridHeader;
