import { ReactNode, useEffect } from 'react';
import styles from './GridPage.module.scss';
import Container from '../../../components/ui/Container';
import classNames from 'classnames';
import Card from '../../../components/ui/Card';
import { useAppDispatch } from '../../../redux/hooks';
import { headerInfoActions } from '../../../redux/slices/headerInfo';

interface Props {
  title?: string;
  panel: ReactNode;
  grid: ReactNode;
  actions: ReactNode;
  className?: string;
}
const GridPage = ({ panel, grid, actions, title, className = 'pt-7 pb-7' }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (title) {
      dispatch(headerInfoActions.setHeaderInfoContent([title]));
    }

    return () => {
      dispatch(headerInfoActions.setHeaderInfoContent([]));
    };
  }, [dispatch, title]);

  return (
    <Container className={classNames(styles.root, className)}>
      <Card className={classNames(styles.panel, 'mb-4')}>{panel}</Card>
      <div className={styles.content}>
        <div className={classNames(styles.grid, 'mr-4')}>{grid}</div>
        {actions}
      </div>
    </Container>
  );
};

export default GridPage;
