import { useParams } from 'react-router';
import GridPage from '../../gridPage/GridPage';
import SinglePetitionActions from './SinglePetitionActions';
import SinglePetitionGrid from './SinglePetitionGrid';
import Heading from '../../../../components/ui/Heading';
import { useGetSinglePetitionStatQuery } from '../../../../redux/api/statistic';

const SinglePetitionReport = () => {
  const { complexId } = useParams();

  const { data } = useGetSinglePetitionStatQuery(complexId!, {
    skip: !complexId,
    refetchOnMountOrArgChange: true,
  });

  return (
    <GridPage
      title="Статистика по ходатайству"
      className="pt-2 pb-7"
      panel={
        <Heading className="m-0">{data?.results[0]?.barcode.split('-')[0] ?? complexId}</Heading>
      }
      grid={<SinglePetitionGrid complexId={complexId} />}
      actions={<SinglePetitionActions />}
    />
  );
};

export default SinglePetitionReport;
