import UsersGrid from './UsersGrid';
import Search from '../../../components/common/Search';
import UsersActions from './UsersActions';
import GridPage from '../gridPage/GridPage';

const UsersPage = () => {
  return (
    <GridPage
      title="Управление пользователями"
      panel={<Search placeholder="Введите ФИО" />}
      grid={<UsersGrid />}
      actions={<UsersActions />}
    />
  );
};

export default UsersPage;
