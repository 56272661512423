import { IInformation } from '../../types/IInformation';
import { api } from '../api';

export const informationApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getInformation: builder.query<IInformation, void>({
      query: () => 'api/system/information',
    }),
  }),
});

export const { useGetInformationQuery } = informationApi;
