import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { documentsAdapter, pagesAdapter } from './slice';
import { INormalizedPrintDocument } from '../../../types/IPrintDocument';
import { IPage } from '../../../types/IPage';

export const documents = documentsAdapter.getSelectors<RootState>(state => state.printJob.document);
export const pages = pagesAdapter.getSelectors<RootState>(state => state.printJob.page);

export const selectPrintJobInfo = (state: RootState) => state.printJob.info;
export const selectActiveDocumentId = (state: RootState) => state.printJob.activeDocument;
export const selectActiveDocument = (state: RootState) =>
  state.printJob.activeDocument
    ? state.printJob.document.entities[state.printJob.activeDocument]
    : undefined;
export const selectSelectedDocumentIds = (state: RootState) => state.printJob.selectedDocuments;
export const selectSelectedDocuments = createSelector(
  [documents.selectEntities, selectSelectedDocumentIds],
  (documentEntities, ids) =>
    ids
      .map(id => documentEntities[id])
      .filter(doc => doc !== undefined) as INormalizedPrintDocument[],
);
export const selectDocumentPages = createSelector(
  [selectActiveDocument, pages.selectAll, pages.selectEntities],
  (doc, pages, pageEntities) => {
    if (!doc) {
      return pages;
    } else {
      return doc.pages.map(p => pageEntities[p]).filter(Boolean) as IPage[];
    }
  },
);
export const selectActiveImage = (state: RootState) => state.printJob.activeImage;
export const selectSelectedPages = (state: RootState) => state.printJob.selectedPages;
