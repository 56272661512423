import { Table } from '@tanstack/react-table';
import Button from '../ui/Button';
import { ArrowNextIcon, ArrowPrevIcon } from '../ui/icons/Icons';

import styles from './GridPagination.module.scss';

interface Props<T> {
  table: Table<T>;
  totalCount?: number;
}

const GridPagination = <T,>({ table, totalCount }: Props<T>) => {
  const { pageSize, pageIndex } = table.getState().pagination;

  const pageStart = pageIndex * pageSize + 1;
  const pageEnd = (pageIndex + 1) * pageSize;
  const total = totalCount ?? table.getFilteredRowModel().rows.length;

  return (
    <div className={styles.root}>
      {total > 0 && (
        <span className={styles.displayedItems}>
          {pageStart} - {pageEnd > total ? total : pageEnd} из {total}
        </span>
      )}
      <select
        className={styles.pageSizeSelect}
        value={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Показать {pageSize}
          </option>
        ))}
      </select>
      <div className={styles.pages}>
        <Button
          variant="outlined"
          viewType="small"
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          <ArrowPrevIcon />
        </Button>
        <span className={styles.pageNumber}>
          {pageIndex + 1}/{Math.max(table.getPageCount(), 1)}
        </span>
        <Button
          variant="outlined"
          viewType="small"
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          <ArrowNextIcon />
        </Button>
      </div>
    </div>
  );
};

export default GridPagination;
