import GridPage from '../gridPage/GridPage';
import PrintersGrid from './PrintersGrid';
import Search from '../../../components/common/Search';
import PrintersActions from './PrintersActions';

const PrintersPage = () => {
  return (
    <GridPage
      title="Управление принтерами"
      panel={<Search placeholder="Введите наименование" />}
      grid={<PrintersGrid />}
      actions={<PrintersActions />}
    />
  );
};

export default PrintersPage;
