import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import { QueryKeys } from '../../../data/QueryKeys';
import useModal from '../../../hooks/useModal';
import DivisionModal from './DivisionModal';
import { divisionsApi, useDeleteDivisionMutation } from '../../../redux/api/divisions';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import GridActions from '../../../components/common/GridActions';
import Spinner from '../../../components/ui/Spinner';
import Button from '../../../components/ui/Button';

const DivisionsActions = () => {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();

  const selectedDivision = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Division),
  );

  const { modalOpenHandler, modalMarkup } = useModal(
    <DivisionModal divisionId={editing ? selectedDivision : undefined} />,
  );

  const [deleteDivision, { isLoading: deletingDivision, isSuccess }] = useDeleteDivisionMutation();

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalMarkup: confirmModal,
    modalCloseHandler,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deleteDivision(selectedDivision)}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.Division));
      dispatch(
        divisionsApi.endpoints.getDivisionsList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
    }
  }, [dispatch, isSuccess, modalCloseHandler]);
  return (
    <GridActions>
      {deletingDivision && <Spinner />}
      <Button
        onClick={() => {
          setEditing(false);
          modalOpenHandler();
        }}
      >
        Создать управление
      </Button>
      <Button
        onClick={() => {
          setEditing(true);
          modalOpenHandler();
        }}
        disabled={!selectedDivision}
      >
        Редактировать
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedDivision}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {modalMarkup}
      {confirmModal}
    </GridActions>
  );
};

export default DivisionsActions;
