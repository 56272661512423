import { ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import styles from './Tab.module.scss';

export interface TabProps<T> {
  value: T;
  children: ReactNode;
  active?: boolean;
  className?: string;
  count?: number;
  onClick?: (value: T) => void;
}

const Tab = <T,>({ value, children, active, count, className, onClick }: TabProps<T>) => {
  const clickHandler = useCallback(() => onClick?.(value), [onClick, value]);

  return (
    <button
      className={classNames(styles.root, className, active && styles.active)}
      onClick={clickHandler}
    >
      {children}
      {!!count && ` (${count})`}
    </button>
  );
};

export default Tab;
