import { useEffect, useMemo, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import { PAGE_SIDES_RATIO } from '../../const';
import Spinner from '../ui/Spinner';

import styles from './DraggableView.module.scss';
import { SheetFormat } from '../../types/SheetFormat';

interface Props {
  src: string | undefined;
  imageWidth: number;
  imageFormat?: SheetFormat;
  isVisible?: boolean;
  rotateDeg?: number;
  isAnimated?: boolean;
  className?: string;
  scrollBy?: ScrollToOptions;
}

const DraggableView = ({
  src,
  className,
  imageWidth,
  imageFormat,
  isVisible = true,
  rotateDeg = 0,
  isAnimated = true,
  scrollBy = { left: 0, top: 0 },
}: Props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  const container = useRef<HTMLElement>(null);

  useEffect(() => {
    container.current?.scrollTo({
      left: 5000 - container.current.clientWidth / 2 + (scrollBy.left ?? 0),
      top: 5000 - container.current.clientHeight / 2 + (scrollBy.top ?? 0),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBy.left, scrollBy.top, container.current, src]);

  const size = useMemo(() => {
    if (imageFormat === SheetFormat.A3) {
      return {
        width: imageWidth * 2,
        height: imageWidth * PAGE_SIDES_RATIO,
      };
    } else {
      return {
        width: imageWidth,
        height: imageWidth * PAGE_SIDES_RATIO,
      };
    }
  }, [imageWidth, imageFormat]);

  return (
    <ScrollContainer className={classNames(styles.root, className)} innerRef={container}>
      <div className={styles.content}>
        <div
          className={classNames(styles.imageWrapper, isAnimated && styles.animated)}
          style={{
            transform: `rotate(${rotateDeg}deg)`,
            width: `${size.width}px`,
            height: `${size.height}px`,
          }}
        >
          {src && isVisible && (
            <>
              {!loaded && <Spinner block />}
              <img
                className={loaded ? undefined : styles.loading}
                src={src}
                width={size.width}
                height={size.height}
                loading="lazy"
                alt="Просмотр изображения"
                onLoad={() => setLoaded(true)}
              />
            </>
          )}
        </div>
      </div>
    </ScrollContainer>
  );
};

export default DraggableView;
