import { FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/ui/Button';
import Buttons from '../../../components/ui/Buttons';
import Checkbox from '../../../components/ui/Checkbox';
import ModalHeading from '../../../components/ui/ModalHeading';
import Select from '../../../components/ui/Select';
import TextInput from '../../../components/ui/TextInput';
import { toast } from 'react-toastify';
import { IUserCreateInfo, RoleEnum } from '../../../types/IUserInfo';
import { IEnumItem } from '../../../data/enum';
import { Role } from '../../../types/IUserInfo';
import {
  useCreateUserMutation,
  useEditUserMutation,
  useGetUserQuery,
  usersApi,
} from '../../../redux/api/users';
import Spinner from '../../../components/ui/Spinner';
import PasswordInput from '../../../components/ui/PasswordInput';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  userId?: number;
  onClose?: () => void;
}

const UserModal = ({ userId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<IUserCreateInfo & { newPassword2: string }>({
    lastName: '',
    firstName: '',
    secondName: '',
    userName: '',
    key: '',
    newPassword: '',
    newPassword2: '',
    role: Role.Operator,
    active: true,
  });

  const { data: userData, isFetching: userFetching } = useGetUserQuery(userId!, { skip: !userId });

  useEffect(() => {
    if (userData) {
      setFormData({
        lastName: userData.lastName,
        firstName: userData.firstName,
        secondName: userData.secondName,
        userName: userData.userName,
        key: userData.key,
        newPassword: '',
        newPassword2: '',
        role: userData.role as Role,
        active: userData.active,
      });
    }
  }, [userData]);

  const [createUser, { isLoading: creatingUser, data: createdUser }] = useCreateUserMutation();
  const [editUser, { isLoading: editingUser, data: editResult }] = useEditUserMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const role = useMemo(
    () => RoleEnum.items.find(role => role.value === formData.role),
    [formData.role],
  );

  const validateForm = useCallback(
    (checkPassword: boolean) => {
      let isValid = true;
      if (formData.firstName.trim().length < 2) {
        toast.error('Некорректное значение имени!');
        isValid = false;
      }
      if (formData.lastName.trim().length < 2) {
        toast.error('Некорректное значение фамилии!');
        isValid = false;
      }
      if (formData.userName.trim().length < 2) {
        toast.error('Некорректное значение логина!');
        isValid = false;
      }
      if (checkPassword && formData.newPassword.trim().length < 3) {
        toast.error('Некорректное значение пароля!');
        isValid = false;
      }
      if (formData.newPassword !== formData.newPassword2) {
        toast.error('Введенные пароли не совпадают!');
        isValid = false;
      }
      return isValid;
    },
    [
      formData.firstName,
      formData.lastName,
      formData.userName,
      formData.newPassword,
      formData.newPassword2,
    ],
  );

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm(true);
      if (isFormValid) {
        createUser({
          userName: formData.userName,
          key: formData.key,
          newPassword: formData.newPassword,
          role: formData.role as Role,
          firstName: formData.firstName,
          secondName: formData.secondName,
          lastName: formData.lastName,
          active: formData.active,
        });
      } else {
        return;
      }
    },
    [createUser, formData, validateForm],
  );

  useEffect(() => {
    if (createdUser || editResult) {
      onClose?.();
      dispatch(
        usersApi.endpoints.getUsersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createdUser, dispatch, editResult, onClose]);

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();

      if (!userData) return;

      const isFormValid = validateForm(false);

      if (!isFormValid) return toast.error('Данные некорректны!');

      const newData: Partial<typeof formData & { newPassword: string }> = { ...formData };
      delete newData.newPassword;
      delete newData.newPassword2;

      if (formData.newPassword.trim().length) {
        newData.newPassword = formData.newPassword;
      }

      editUser({
        id: userData.id,
        data: newData,
      });
    },
    [editUser, formData, userData, validateForm],
  );

  return (
    <div>
      {(creatingUser || editingUser || userFetching) && <Spinner />}
      <ModalHeading className="mb-6">
        {userId ? 'Редактирование данных пользователя' : 'Создание нового пользователя'}
      </ModalHeading>
      <form onSubmit={userId ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="lastName"
          name="lastName"
          label="Фамилия"
          className="mb-2"
          value={formData.lastName}
          onChange={value => formChangeHandler('lastName', value)}
        />
        <TextInput
          type="text"
          id="firstName"
          name="firstName"
          label="Имя"
          className="mb-2"
          value={formData.firstName}
          onChange={value => formChangeHandler('firstName', value)}
        />
        <TextInput
          type="text"
          id="middleName"
          name="middleName"
          label="Отчество"
          className="mb-2"
          value={formData.secondName}
          onChange={value => formChangeHandler('secondName', value)}
        />
        <TextInput
          type="text"
          id="key"
          name="key"
          label="Ключ"
          className="mb-2"
          value={formData.key}
          onChange={value => formChangeHandler('key', value)}
        />
        <TextInput
          type="text"
          id="login"
          name="login"
          label="Логин"
          className="mb-2"
          value={formData.userName}
          onChange={value => formChangeHandler('userName', value)}
        />
        <PasswordInput
          id="newPassword"
          name="newPassword"
          label="Пароль"
          className="mb-2"
          value={formData.newPassword}
          onChange={value => formChangeHandler('newPassword', value)}
        />
        <PasswordInput
          id="newPassword2"
          name="newPassword2"
          label="Повтор пароля"
          className="mb-2"
          value={formData.newPassword2}
          onChange={value => formChangeHandler('newPassword2', value)}
        />
        <Select
          label="Роль"
          className="mb-4"
          placeholder="Выберите роль пользователя"
          options={RoleEnum.items.filter(role => role.value !== Role.SuperAdmin)}
          value={role}
          getOptionLabel={item => (item as IEnumItem<Role>).displayName}
          getOptionValue={item => (item as IEnumItem<Role>).value}
          onChange={value =>
            setFormData(prev => ({ ...prev, role: (value as IEnumItem<Role>).value as Role }))
          }
        />
        <Checkbox
          label="Активность"
          checked={formData.active}
          onChange={state => setFormData(prev => ({ ...prev, active: state }))}
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {userId ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default UserModal;
