import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface INotificationState {
  operatorTask: number;
  printTask: number;
}

const initialState: INotificationState = {
  operatorTask: 0,
  printTask: 0,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setOperatorTaskCount: (state, action: PayloadAction<number>) => {
      state.operatorTask = action.payload;
    },
    setPrintTaskCount: (state, action: PayloadAction<number>) => {
      state.printTask = action.payload;
    },
    // incPrintTaskCount: state => {
    //   state.printTask++;
    // },
    // incOperatorTaskCount: state => {
    //   state.operatorTask++;
    // },
  },
});

export const {
  setOperatorTaskCount,
  setPrintTaskCount,
  // incOperatorTaskCount, incPrintTaskCount
} = notificationSlice.actions;

export default notificationSlice.reducer;
