import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  Row,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table';
import classNames from 'classnames';

import GridHeader from './GridHeader';
import GridBody from './GridBody';
import Card from '../ui/Card';
import GridPagination from './GridPagination';
import GridSettings from './GridSettings';
import Spinner from '../ui/Spinner';
import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';
import { TId } from '../../types/TId';

import styles from './DataGrid.module.scss';
import { useAppDispatch } from '../../redux/hooks';
import { headerInfoActions } from '../../redux/slices/headerInfo';
import { selectionActions } from '../../redux/slices/selection';

interface Props<T> {
  gridKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  rows: T[] | undefined;
  isLoading?: boolean;
  onRowClick?: (row: Row<T>) => void;
  onRowDoubleClick?: (row: Row<T>) => void;
  rowIdSelector?: (item: T) => TId;
  columnSettings?: boolean;
  hideMyFilter?: boolean;
  hideAllSettings?: boolean;
  hidePagination?: boolean;
  className?: string;
  stateString?: string;
  filterColumn?: string;
  hiddenFilterColumn?: string;
  dateFilterColumn?: string;
  filters?: ColumnFiltersState;
  highlighter?: (row: T) => { color?: string; isNeeded: boolean };
  serverControlled?: boolean;
  totalCount?: number;
  pagesCount?: number;
  onPaginationChange?: (paginationState: PaginationState) => void;
  onSortingChange?: (sortingState: SortingState) => void;
  onFiltersChange?: (filtersState: ColumnFiltersState) => void;
  initialSorting?: SortingState;
  pageIndex?: number;
  perPage?: number;
  extraSetting?: ReactNode;
}

const DataGrid = <T,>({
  gridKey,
  columns,
  rows,
  isLoading,
  className,
  onRowClick,
  onRowDoubleClick,
  rowIdSelector,
  columnSettings,
  hideMyFilter,
  hideAllSettings,
  filterColumn,
  hiddenFilterColumn,
  dateFilterColumn,
  filters,
  highlighter,
  stateString,
  serverControlled,
  totalCount,
  pagesCount,
  onPaginationChange,
  onSortingChange,
  onFiltersChange,
  initialSorting,
  pageIndex,
  perPage,
  extraSetting,
  hidePagination,
}: Props<T>) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting ?? []);
  const [globalFilter, setGlobalFilter] = useState('');

  const dispatch = useAppDispatch();
  const defaultData = useMemo(() => [], []);
  const scrollContainerRef = usePerfectScrollbar<HTMLDivElement>();
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(filters ?? []);
  const [searchParams] = useSearchParams();
  const filterValue = searchParams.get('search');

  useEffect(() => {
    if (filters) {
      setColumnFilters(filters);
    }
  }, [filters]);

  useEffect(() => {
    scrollContainerRef.current?.scroll({ top: 0, left: 0 });
  }, [pageIndex, perPage, scrollContainerRef, rows]);

  useEffect(() => {
    if (rows && stateString) {
      dispatch(headerInfoActions.setHeaderInfoContent([`${stateString}: ${rows.length}`]));
    }

    return () => {
      if (stateString) {
        dispatch(headerInfoActions.setHeaderInfoContent([]));
      }
    };
  }, [rows, dispatch, stateString, gridKey]);

  useEffect(() => {
    return () => {
      dispatch(selectionActions.removeSelection(gridKey));
    };
  }, [dispatch, gridKey]);

  useEffect(() => {
    if (filterColumn && filterValue) {
      setColumnFilters(prev => [
        ...prev.filter(f => f.id !== filterColumn),
        { id: filterColumn as string, value: filterValue },
      ]);
    } else if (filterColumn && !filterValue) {
      setColumnFilters(prev => prev.filter(f => f.id !== filterColumn));
    } else if (!filterColumn && filterValue) {
      setGlobalFilter(filterValue);
    } else if (!filterColumn && !filterValue) {
      setGlobalFilter('');
    }
  }, [filterColumn, filterValue]);

  const table = useReactTable({
    data: rows ?? defaultData,
    columns,
    getRowId: rowIdSelector ? row => rowIdSelector(row).toString() : undefined,
    pageCount: pagesCount ?? undefined,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    initialState: {
      columnVisibility: hiddenFilterColumn
        ? {
            [hiddenFilterColumn as string]: false,
          }
        : undefined,
      pagination: {
        pageSize: perPage ?? 20,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: serverControlled ? undefined : getFilteredRowModel(),
    getSortedRowModel: serverControlled ? undefined : getSortedRowModel(),
    getPaginationRowModel: serverControlled ? undefined : getPaginationRowModel(),
    manualPagination: serverControlled,
    manualSorting: serverControlled,
    manualFiltering: serverControlled,
  });

  useEffect(() => {
    if (pageIndex || pageIndex === 0) {
      table.setPageIndex(pageIndex);
    }
  }, [pageIndex, table]);

  useEffect(() => {
    if (perPage) {
      table.setPageSize(perPage);
    }
  }, [perPage, table]);

  useEffect(() => {
    onSortingChange?.(sorting);
  }, [onSortingChange, sorting]);
  const tableFilter = table.getState().columnFilters;

  useEffect(() => {
    onFiltersChange?.(tableFilter);
  }, [tableFilter, onFiltersChange]);

  const pagination = table.getState().pagination;

  useEffect(() => {
    onPaginationChange?.(pagination);
  }, [onPaginationChange, pagination]);

  return (
    <div className={classNames(styles.gridRoot, className)}>
      <Card noPadding className={styles.gridCard}>
        {isLoading && <Spinner block />}
        {!hideAllSettings && (
          <GridSettings
            table={table}
            columnSettings={columnSettings}
            hideMyFilter={hideMyFilter}
            hiddenFilterColumn={hiddenFilterColumn}
            dateFilterColumn={dateFilterColumn}
            extra={extraSetting}
          />
        )}
        <div className={styles.gridWrapper} ref={scrollContainerRef}>
          <table style={{ width: table.getTotalSize() }}>
            <GridHeader table={table} />
            <GridBody
              table={table}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              gridKey={gridKey}
              rowIdSelector={rowIdSelector}
              highlighter={highlighter}
            />
          </table>
        </div>
        {isLoading || table.getFilteredRowModel().rows.length ? null : (
          <div className={styles.message}>Нет данных для отображения!</div>
        )}
      </Card>
      {!hidePagination && <GridPagination table={table} totalCount={totalCount} />}
    </div>
  );
};

export default DataGrid;
