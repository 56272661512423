import { useEffect, useState } from 'react';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import useModal from '../../../hooks/useModal';
import PrinterModal from './PrinterModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import { printersApi, useDeletePrinterMutation } from '../../../redux/api/printers';
import Spinner from '../../../components/ui/Spinner';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { QueryKeys } from '../../../data/QueryKeys';

const PrintersActions = () => {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();

  const selectedPrinter = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Printers),
  );

  const { modalOpenHandler, modalMarkup } = useModal(
    <PrinterModal id={editing ? selectedPrinter : undefined} />,
  );

  const [deletePrinter, { isLoading, isSuccess }] = useDeletePrinterMutation();

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalMarkup: confirmModal,
    modalCloseHandler,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deletePrinter(selectedPrinter)}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.Printers));
      dispatch(
        printersApi.endpoints.getPrintersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
    }
  }, [dispatch, isSuccess, modalCloseHandler]);

  return (
    <GridActions>
      {isLoading && <Spinner />}
      <Button
        onClick={() => {
          setEditing(false);
          modalOpenHandler();
        }}
      >
        Добавить
      </Button>
      <Button
        onClick={() => {
          setEditing(true);
          modalOpenHandler();
        }}
        disabled={!selectedPrinter}
      >
        Редактировать
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedPrinter}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {modalMarkup}
      {confirmModal}
    </GridActions>
  );
};

export default PrintersActions;
