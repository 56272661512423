import 'globalthis/polyfill';
import 'array-flat-polyfill';
import 'polyfill-object.fromentries';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-day-picker/dist/style.css';
import 'swiper/scss'; // core Swiper
import 'swiper/css/scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/style.scss';
import { store } from './redux/store';
import App from './App';
import { ErrorBoundary } from './components/layout/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
);
