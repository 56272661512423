import { useEffect, useState } from 'react';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import useModal from '../../../hooks/useModal';
import FoldersModal from './FoldersModal';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { foldersApi, useDeleteFolderMutation } from '../../../redux/api/folders';
import Spinner from '../../../components/ui/Spinner';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { QueryKeys } from '../../../data/QueryKeys';

const FoldersActions = () => {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();
  const selectedFolder = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Folders),
  );

  const { modalOpenHandler, modalMarkup } = useModal(
    <FoldersModal folderId={editing ? selectedFolder : undefined} />,
  );

  const [deleteFolder, { isLoading, isSuccess }] = useDeleteFolderMutation();

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalMarkup: confirmModal,
    modalCloseHandler,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deleteFolder(selectedFolder)}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.Folders));
      dispatch(
        foldersApi.endpoints.getFoldersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
    }
  }, [dispatch, isSuccess, modalCloseHandler]);

  return (
    <GridActions>
      {isLoading && <Spinner />}
      <Button
        onClick={() => {
          setEditing(false);
          modalOpenHandler();
        }}
      >
        Добавить
      </Button>
      <Button
        disabled={!selectedFolder}
        onClick={() => {
          setEditing(true);
          modalOpenHandler();
        }}
      >
        Изменить
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedFolder}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {modalMarkup}
      {confirmModal}
    </GridActions>
  );
};

export default FoldersActions;
