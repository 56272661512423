import { ReactNode } from 'react';
import classNames from 'classnames';

import Heading from './Heading';

import styles from './ModalHeading.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const ModalHeading = ({ children, className }: Props) => {
  return (
    <Heading className={classNames(styles.root, className)} level={2}>
      {children}
    </Heading>
  );
};

export default ModalHeading;
