import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectSelectedRows = (state: RootState) => state.selection.selectedRow;

export const selectSelectedRowIdByGridKey = createSelector(
  [selectSelectedRows, (state, gridKey) => gridKey],
  (selectedRows, gridKey: string) => selectedRows[gridKey],
);

export const selectSelectedPreview = (state: RootState) => state.selection.selectedPreview;
