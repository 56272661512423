import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TId } from '../../../types/TId';

export interface ISelectedRowState {
  selectedRow: Record<string, TId>;
  selectedPreview: string | null;
}

const initialState: ISelectedRowState = {
  selectedRow: {},
  selectedPreview: null,
};

const selectedRows = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setGridSelectedRow: (state, action: PayloadAction<[string, TId]>) => {
      const [gridKey, id] = action.payload;
      state.selectedRow[gridKey] = id;
    },
    removeSelection: (state, { payload }: PayloadAction<string>) => {
      delete state.selectedRow[payload];
    },
    setSelectedPreview: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedPreview = payload;
    },
  },
});

export const { setGridSelectedRow, removeSelection, setSelectedPreview } = selectedRows.actions;

export default selectedRows.reducer;
