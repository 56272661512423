import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Row, Table, flexRender } from '@tanstack/react-table';

import { useAppSelector } from '../../redux/hooks';
import { TId } from '../../types/TId';
import { selectionActions, selectionSelectors } from '../../redux/slices/selection';

import styles from './GridBody.module.scss';
import { useEffect, useRef } from 'react';

interface Props<T> {
  table: Table<T>;
  onRowClick?: (row: Row<T>) => void;
  onRowDoubleClick?: (row: Row<T>) => void;
  gridKey: string;
  rowIdSelector?: (item: T) => TId;
  highlighter?: (row: T) => { color?: string; isNeeded: boolean };
}

const GridBody = <T,>({
  table,
  onRowClick,
  onRowDoubleClick,
  gridKey,
  rowIdSelector,
  highlighter,
}: Props<T>) => {
  const dispatch = useDispatch();

  const indexSelector = (row: Row<T>) => row.index.toString();

  const getId = (row: Row<T>) => (rowIdSelector ? rowIdSelector(row.original) : indexSelector(row));

  const rowClickHandler = (row: Row<T>) => {
    dispatch(selectionActions.setGridSelectedRow([gridKey, getId(row)]));
    onRowClick?.(row);
  };

  const activeRow = useAppSelector(store =>
    selectionSelectors.selectSelectedRowIdByGridKey(store, gridKey),
  );

  const activeRowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (activeRow && activeRowRef.current) {
      activeRowRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tbody className={styles.root}>
      {table.getRowModel().rows.map(row => (
        <tr
          key={row.id}
          ref={activeRow === getId(row) ? activeRowRef : undefined}
          onClick={() => rowClickHandler(row)}
          onDoubleClick={() => onRowDoubleClick?.(row)}
          className={classNames(activeRow === getId(row) && 'active')}
          style={
            highlighter?.(row.original).isNeeded
              ? {
                  backgroundColor: highlighter?.(row.original).color ?? 'transparent',
                }
              : undefined
          }
        >
          {row.getVisibleCells().map(cell => (
            <td key={cell.id} style={{ width: cell.column.getSize() }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default GridBody;
