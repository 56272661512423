import classNames from 'classnames';
import { RiPrinterLine } from 'react-icons/ri';
import { BiSelectMultiple } from 'react-icons/bi';

import Button from '../../components/ui/Button';
import useModal from '../../hooks/useModal';
import PrintModal from './modals/PrintModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import DocumentList from '../../components/common/DocumentList';

import styles from './ItemList.module.scss';
import { INormalizedPrintDocument } from '../../types/IPrintDocument';
import { printJobActions, printJobSelectors } from '../../redux/slices/printJob';
import { useCallback, useState } from 'react';
import { IPrinterTask, IPrinterTaskDocuments } from '../../types/IPrinterTask';

const ItemList = () => {
  const [task, setTask] = useState<IPrinterTask | IPrinterTaskDocuments>();

  const [complexPrinting, setComplexPrinting] = useState(false);
  const selectedDocuments = useAppSelector(printJobSelectors.selectSelectedDocuments);
  const selectedDocumentIds = useAppSelector(printJobSelectors.selectSelectedDocumentIds);
  const { modalOpenHandler, modalMarkup } = useModal(
    <PrintModal
      showCountSelector={selectedDocuments.length < 2 && !complexPrinting}
      initialCount={selectedDocuments[0]?.copies}
      task={task!}
    />,
  );
  const dispatch = useAppDispatch();
  const activeDoc = useAppSelector(printJobSelectors.selectActiveDocumentId);
  const documents = useAppSelector(printJobSelectors.documents.selectAll);
  const jobInfo = useAppSelector(printJobSelectors.selectPrintJobInfo);

  const listCLickHandler = useCallback(
    (item: INormalizedPrintDocument) => {
      dispatch(printJobActions.setDocument(item.id));
    },
    [dispatch],
  );

  const documentSelectHandler = useCallback(
    (item: INormalizedPrintDocument) => {
      dispatch(printJobActions.changeSelectedDocuments(item.id));
    },
    [dispatch],
  );

  return (
    <>
      <Button
        className={classNames(styles.printBtn, 'mb-4')}
        variant="success"
        viewType="toolbarText"
        onClick={() => {
          setComplexPrinting(true);
          setTask({ printJobId: jobInfo!.id });
          modalOpenHandler();
        }}
        icon={<RiPrinterLine />}
      >
        Печать комплекта
      </Button>
      <DocumentList<INormalizedPrintDocument & { extraCount?: number }>
        className={styles.list}
        title="Список документов"
        items={documents.map(i => ({ ...i, extraCount: i.copies }))}
        activeId={activeDoc ? activeDoc : undefined}
        onDocumentClick={listCLickHandler}
        selection={selectedDocuments}
        onDocumentSelect={documentSelectHandler}
        multiple
        getItemTitle={item => item.name}
        getItemId={item => item.id}
        showCount
      />
      <Button
        icon={<BiSelectMultiple />}
        variant="success"
        onClick={() => {
          setComplexPrinting(false);
          setTask({ printJobId: jobInfo!.id, selectedDocuments: selectedDocumentIds });
          modalOpenHandler();
        }}
        disabled={selectedDocuments.length === 0}
      >
        Печать выделенных
      </Button>
      {modalMarkup}
    </>
  );
};

export default ItemList;
