import { Row, createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';

import Button from '../../../components/ui/Button';
import Tag from '../../../components/ui/Tag';
import { POLLING_INTERVAL_XS } from '../../../const';
import useModal from '../../../hooks/useModal';
import {
  ComplexPriority,
  ComplexPriorityEnum,
  ComplexStateEnum,
  ComplexTypeEnum,
  IComplexInfo,
} from '../../../types/IComplexInfo';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import PrintQrModal from '../modals/PrintQrModal';
import GridActions from '../../../components/common/GridActions';

import styles from './Grid.module.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionSelectors } from '../../../redux/slices/selection';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { Routes } from '../../../Router';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import {
  complexesApi,
  useGetComplexesListQuery,
  usePriorityComplexMutation,
} from '../../../redux/api/complexes';
import { RUNNING_COMPLEXES } from './filters';
import { getReadableDateTime } from '../../../utils/date';
import { toast } from 'react-toastify';
import Spinner from '../../../components/ui/Spinner';
import useQrList from '../../../hooks/useQrList';

const columnHelper = createColumnHelper<IComplexInfo>();

const columns = [
  columnHelper.display({
    id: 'index',
    header: '#',
    cell: ({ row }) => row.index + 1,
    size: 50,
  }),
  columnHelper.accessor('name', {
    header: 'Имя документа',
    cell: info => <span className={styles.title}>{info.renderValue()}</span>,
    filterFn: 'includesString',
    enableColumnFilter: true,
  }),
  columnHelper.accessor('type', {
    header: 'Тип документа',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', margin: '0 auto' }}>
        {ComplexTypeEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
    size: 150,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('barcode', {
    id: 'barcode',
    header: 'Штрихкод (QR код)',
    cell: info => info.renderValue(),
    size: 170,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    id: 'dateFilterColumn',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<IComplexInfo>(),
  }),
  columnHelper.accessor('state', {
    header: 'Статус',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', display: 'block', margin: '0 auto' }}>
        {ComplexStateEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во',
    cell: info => (
      <Tag style={{ display: 'block', minWidth: '62px', width: '62px', margin: '0 auto' }}>
        {info.getValue() ? info.renderValue() : '-'}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('pagesProcessed', {
    header: 'Кол-во об. обр.',
    cell: info => (
      <Tag style={{ display: 'block', minWidth: '62px', width: '62px', margin: '0 auto' }}>
        {info.getValue() === null ? 0 : info.renderValue()}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('pagesTotal', {
    id: 'processedPercent',
    header: '% обр.',
    cell: info => {
      const value = Math.round(
        ((info.row.original.pagesProcessed ?? 0) / info.row.original.pagesTotal) * 100,
      );
      return isNaN(value) ? (
        '-'
      ) : (
        <Tag style={{ minWidth: '60px', margin: '0 auto' }} bold>
          {value}%
        </Tag>
      );
    },
    size: 100,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('userShowName', {
    header: 'Оператор',
    cell: info => info.renderValue(),
    enableColumnFilter: true,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('priority', {
    header: 'Приоритет',
    cell: info =>
      info.getValue() ? (
        <Tag variant="status" style={{ minWidth: '123px', margin: '0 auto' }}>
          {ComplexPriorityEnum.getDisplayName(info.getValue()!)}
        </Tag>
      ) : null,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    sortingFn: 'text',
  }),
];

const RunningGrid = () => {
  const { modalOpenHandler, modalMarkup } = useModal(<PrintQrModal />);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeComplexData, setActiveComplexData] = useState<{
    barcode: string;
    name: string | null;
    raiseable: boolean;
    partsTotal?: number;
  }>({
    barcode: '',
    name: '',
    raiseable: false,
  });

  const {
    data,
    refetch,
    isFetching: listLoading,
  } = useGetComplexesListQuery(
    { states: RUNNING_COMPLEXES },
    {
      pollingInterval: POLLING_INTERVAL_XS,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const { printHandler, content } = useQrList(
    activeComplexData.barcode,
    activeComplexData.partsTotal ?? 0,
  );

  const activeRow = useAppSelector(store =>
    selectionSelectors.selectSelectedRowIdByGridKey(store, QueryKeys.Running),
  );

  const [priorityComplex, { isLoading: settingPriority, isSuccess: prioritySet }] =
    usePriorityComplexMutation();

  const historyClickHandler = useCallback(() => {
    const path = generatePath(Routes.TaskHistory, {
      section: QueryKeys.Running,
      taskId: activeRow!,
    });
    navigate(path);
  }, [activeRow, navigate]);

  const priorityHandler = useCallback(() => {
    if (!activeRow) return toast.error('Не удалось определить идентификатор процесса!');
    priorityComplex(activeRow);
  }, [priorityComplex, activeRow]);

  const rowClickHandler = useCallback((row: Row<IComplexInfo>) => {
    setActiveComplexData({
      barcode: row.original.barcode,
      name: row.original.name,
      raiseable: row.original.priority === ComplexPriority.Default,
      partsTotal: row.original.partsTotal,
    });
  }, []);

  useEffect(() => {
    if (prioritySet) {
      toast.success(`Приоритет задания ${activeComplexData.name} повышен!`);
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: RUNNING_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prioritySet]);

  return (
    <div className={styles.listActions}>
      {settingPriority && <Spinner />}
      <DataGrid
        gridKey={QueryKeys.Running}
        className={classNames(styles.grid, 'mr-4')}
        columns={columns}
        rows={data?.results}
        rowIdSelector={row => row.id}
        onRowClick={rowClickHandler}
        columnSettings={true}
        stateString="Всего заданий в обработке"
        hiddenFilterColumn="userId"
        dateFilterColumn="dateFilterColumn"
        isLoading={listLoading}
      />
      <GridActions>
        <Button onClick={refetch}>Обновить</Button>
        <Button disabled={!activeRow} onClick={historyClickHandler}>
          История обработки
        </Button>
        <Button disabled={!activeRow || !activeComplexData.raiseable} onClick={priorityHandler}>
          Повысить приоритет
        </Button>
        <Button disabled={!activeRow || !activeComplexData.partsTotal} onClick={printHandler}>
          Печать QR
        </Button>
        <Button className="mt-12" onClick={modalOpenHandler}>
          Обработать частями
        </Button>
        {content}
      </GridActions>
      {modalMarkup}
    </div>
  );
};

export default RunningGrid;
