import { createColumnHelper } from '@tanstack/react-table';
import { IPrintJobAuthor } from '../../../types/IPrintJobAuthor';
import { useGetPrinjobAuthorListQuery } from '../../../redux/api/printJobAuthors';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';

const columnHelper = createColumnHelper<IPrintJobAuthor>();

const columns = [
  columnHelper.accessor('name', {
    header: 'ФИО',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('divisionName', {
    header: 'Подразделение',
    cell: info => info.renderValue(),
  }),
];

const PrintjobAuthorsGrid = () => {
  const { data, isLoading } = useGetPrinjobAuthorListQuery();
  return (
    <DataGrid
      gridKey={QueryKeys.PrintjobAuthors}
      rows={data}
      isLoading={isLoading}
      columns={columns}
      rowIdSelector={row => row.id.toString()}
      filterColumn="name"
      hideAllSettings
    />
  );
};

export default PrintjobAuthorsGrid;
