import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import Select from '../../../components/ui/Select';
import { ISelectOption } from '../../../types/ISelectOption';

import styles from './PrintModal.module.scss';
import { useGetPrintersListQuery } from '../../../redux/api/printers';
import { PrinterTask, PrinterTaskFinal } from '../../../types/IPrinterTask';
import { usePrintPrintjobMutation } from '../../../redux/api/printjobs';
import Spinner from '../../../components/ui/Spinner';
import { IPrinterBin } from '../../../types/IPrinter';

interface Props {
  onClose?: () => void;
  showCountSelector?: boolean;
  initialCount?: number;
  task: PrinterTask;
}

interface IPrintInfo {
  printer: ISelectOption | null;
  tray: ISelectOption | null;
  copies?: string;
}

const PrintModal = ({ onClose, showCountSelector, initialCount, task }: Props) => {
  const [availableBins, setAvailableBins] = useState<IPrinterBin[]>([]);
  const [printInfo, setPrintInfo] = useState<IPrintInfo>({
    printer: null,
    tray: null,
    copies: initialCount ? initialCount.toString() : '1',
  });

  const { data: printers, isLoading: printersLoading } = useGetPrintersListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [printTask, { isLoading: printingJob, isSuccess }] = usePrintPrintjobMutation();

  const submitHandler: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    let error = false;

    if (!printInfo.printer) {
      toast.error('Выберите принтер!');
      error = true;
    }

    if (!task) {
      toast.error('Задание не определено!');
      error = true;
    }

    if (error) return;

    const finalTask: PrinterTaskFinal = {
      ...task,
      printerId: printInfo.printer!.value,
      outputBinId: printInfo.tray?.value,
      copies: showCountSelector && printInfo.copies ? +printInfo.copies : undefined,
    };
    printTask(finalTask);
  };

  useEffect(() => {
    if (isSuccess) onClose?.();
  }, [isSuccess, onClose]);

  return (
    <form className={styles.root} onSubmit={submitHandler}>
      {printingJob && <Spinner />}
      <ModalHeading>Печать</ModalHeading>
      <Select
        label="Устройство"
        className="mb-4"
        placeholder="Выберите устройство печати"
        isLoading={printersLoading}
        options={printers
          ?.filter(p => p.active)
          .map(p => ({ label: p.name, value: p.id, bins: p.attributes?.outputBins ?? [] }))}
        onChange={value => {
          setPrintInfo(prev => ({
            ...prev,
            tray:
              printInfo.printer?.value === (value as ISelectOption & { bins: IPrinterBin[] }).value
                ? printInfo.tray
                : null,
            printer: value as ISelectOption & { bins: IPrinterBin[] },
          }));
          setAvailableBins((value as ISelectOption & { bins: IPrinterBin[] }).bins);
        }}
      />
      <Select
        label="Лоток"
        className="mb-4"
        placeholder="Выберите лоток"
        isDisabled={availableBins.length === 0}
        options={availableBins.map(bin => ({ value: bin.id, label: bin.name }))}
        value={printInfo.tray}
        onChange={value => setPrintInfo(prev => ({ ...prev, tray: value as ISelectOption }))}
      />
      {showCountSelector && (
        <TextInput
          className="mb-4"
          label="Укажите количество копий для печати"
          type="number"
          placeholder="Введите количество"
          value={printInfo.copies}
          min={1}
          onChange={value => setPrintInfo(prev => ({ ...prev, copies: value }))}
        />
      )}
      <Buttons>
        <Button type="button" variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="success" type="submit">
          Печать
        </Button>
      </Buttons>
    </form>
  );
};

export default PrintModal;
