import { useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

const usePerfectScrollbar = <T extends HTMLElement>() => {
  const scrollContainerRef = useRef<T>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      new PerfectScrollbar(scrollContainerRef.current, {
        wheelSpeed: 0.2,
        wheelPropagation: false,
      });
    }
  }, [scrollContainerRef.current?.clientWidth, scrollContainerRef.current?.clientHeight]);

  return scrollContainerRef;
};

export default usePerfectScrollbar;
