import { toast } from 'react-toastify';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import { QueryKeys } from '../../../data/QueryKeys';
import {
  processApi,
  useGetProcessByIdQuery,
  useGetProcessListQuery,
  useRestartAllMutation,
  useStartProcessMutation,
  useStopProcessMutation,
} from '../../../redux/api/process';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionSelectors } from '../../../redux/slices/selection';
import { useEffect, useMemo } from 'react';

const ProcessesActions = () => {
  const { refetch } = useGetProcessListQuery();
  const dispatch = useAppDispatch();
  const selectedProcess = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.Processes),
  );

  const { data } = useGetProcessByIdQuery(selectedProcess, {
    skip: !selectedProcess,
    refetchOnMountOrArgChange: true,
  });

  const [startProcess, { isLoading: starting, isSuccess: startSuccess }] =
    useStartProcessMutation();

  const [restartAll, { isLoading: restarting, isSuccess: restartSuccess }] =
    useRestartAllMutation();

  const [stopProcess, { isLoading: stopping, isSuccess: stopSuccess }] = useStopProcessMutation();

  useEffect(() => {
    if (startSuccess || stopSuccess || restartSuccess) {
      dispatch(
        processApi.endpoints.getProcessList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );

      if (selectedProcess) {
        dispatch(
          processApi.endpoints.getProcessById.initiate(selectedProcess, {
            forceRefetch: true,
            subscribe: false,
          }),
        );
      }
    }
  }, [dispatch, restartSuccess, selectedProcess, startSuccess, stopSuccess]);

  const startHandler = () => {
    if (!data) return toast.error('Не удалось получить данные о процессе!');
    startProcess(selectedProcess);
  };

  const stopHandler = () => {
    if (!data) return toast.error('Не удалось получить данные о процессе!');
    stopProcess(selectedProcess);
  };

  const isActive = useMemo(() => {
    return !!data?.status.running;
  }, [data]);

  return (
    <GridActions>
      {(starting || stopping || restarting) && <Spinner />}
      <Button onClick={refetch}>Обновить</Button>
      <Button onClick={isActive ? stopHandler : startHandler} disabled={!data}>
        {isActive ? 'Остановить' : 'Запустить'}
      </Button>
      <Button onClick={() => restartAll()}>Перезапустить все</Button>
    </GridActions>
  );
};

export default ProcessesActions;
