import DateRangeInput from '../../../components/ui/DateRangeInput';
import { SearchIcon } from '../../../components/ui/icons/Icons';
import TextInput from '../../../components/ui/TextInput';
import { IComplexesOptions } from '../../../redux/api/complexes';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IDateRange } from '../../../types/IDateRange';
import Select from '../../../components/ui/Select';
import { getGroupedOptions, getSortedUsers } from '../../../utils/select';
import { ComplexStateEnum } from '../../../types/IComplexInfo';
import { ISelectOption } from '../../../types/ISelectOption';
import { useGetUsersListQuery } from '../../../redux/api/users';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IComplexesOptions>>;
}

const ProcessingFilters = ({ fetchOptionsSetter }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const updateFilter = useCallback(
    (value: string | boolean | undefined, filterId: string) => {
      fetchOptionsSetter(prev => ({
        ...prev,
        [`${filterId}`]:
          typeof value === 'boolean' ? value : value && value.length ? value : undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  useEffect(() => {
    const timeOut = setTimeout(() => updateFilter(searchTerm, 'search'), 300);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchTerm, updateFilter]);

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        dateAddTsFrom: startDate ?? undefined,
        dateAddTsTo: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  const { data: users, isLoading: usersLoading } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <TextInput
        type="search"
        className="mr-4 w-30"
        placeholder="Введите имя или штрихкод документа"
        buttonIcon={<SearchIcon />}
        value={searchTerm}
        onChange={setSearchTerm}
      />
      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
      <Select
        isSearchable
        className="ml-4 w-25"
        placeholder="Статус документа"
        options={getGroupedOptions(ComplexStateEnum)}
        onChange={value =>
          updateFilter(value ? (value as ISelectOption).value : undefined, 'states')
        }
        isClearable
      />
      <Select
        isSearchable
        className="ml-4 w-25"
        placeholder="Оператор"
        options={getSortedUsers(users, 'id')}
        isClearable
        onChange={value =>
          updateFilter(value ? (value as ISelectOption).value : undefined, 'userId')
        }
        isLoading={usersLoading}
      />
      <Select
        className="ml-4 w-15"
        placeholder="Ошибки"
        options={[
          { value: true, label: 'Да' },
          { value: false, label: 'Нет' },
        ]}
        isClearable
        onChange={value =>
          updateFilter(value ? (value as ISelectOption).value : undefined, 'errors')
        }
      />
    </>
  );
};

export default ProcessingFilters;
