import { IEvent, ObjectAction } from '../../types/IEvent';
import { IGridOptions } from '../../types/IGridOptions';
import { IPaginatedResult } from '../../types/IPaginatedResult';
import { api } from '../api';
import { getSearchParams } from '../../utils/searchParams';

export interface IEventsOptions extends IGridOptions {
  objectType?: string;
  objectId?: string;
  objectName?: string;
  objectState?: string;
  objectCode?: string;
  error?: boolean;
  userEvent?: boolean;
  action?: ObjectAction;
  search?: string;
  dateAddTsFrom?: string;
  dateAddTsTo?: string;
  userId?: string;
}

export const EVENTS_DATA_KEYS = [
  'objectType',
  'objectId',
  'objectName',
  'objectState',
  'objectCode',
  'error',
  'userEvent',
  'action',
  'search',
  'dateAddTsFrom',
  'dateAddTsTo',
  'userId',
];

export const eventsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getEvents: builder.query<IPaginatedResult<IEvent>, IEventsOptions>({
      query: params => `api/events${getSearchParams(params)}`,
    }),
  }),
});

export const { useGetEventsQuery } = eventsApi;
