import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RiFolderOpenLine } from 'react-icons/ri';

import Button from '../../components/ui/Button';
import Spinner from '../../components/ui/Spinner';

import styles from './FileUpload.module.scss';
import { useAppSelector } from '../../redux/hooks';
import { operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { toast } from 'react-toastify';
import { useUploadImagesMutation } from '../../redux/api/complexes';
import { IImage } from '../../types/IImage';

interface IFileUploadProps {
  onLoad: (data: IImage[]) => void;
  accept?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FileUpload = ({ onLoad, accept }: IFileUploadProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const complexId = useAppSelector(operatorTaskSelectors.selectComplexId);

  const [uploadImages, { data, isLoading, isSuccess }] = useUploadImagesMutation();

  useEffect(() => {
    if (files.length) {
      const fData = new FormData();
      files.forEach(file => fData.append(`Images[]`, file));
      if (complexId) {
        uploadImages({ complexId, data: fData });
      } else {
        toast.error('Не определен id комплекса!');
      }
    }
  }, [files, uploadImages, complexId]);

  useEffect(() => {
    if (data && isSuccess) {
      onLoad(data);
    }
  }, [data, isSuccess, onLoad]);

  const handleFileChoosingStart = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files).filter(f => f.type === 'image/tiff'));
      e.target.value = '';
    }
  };

  return (
    <div className={styles.root}>
      {isLoading && <Spinner />}
      <Button
        icon={<RiFolderOpenLine />}
        viewType="toolbar"
        title="Открыть каталог"
        onClick={handleFileChoosingStart}
      />
      <input
        type="file"
        className="visually-hidden"
        ref={inputRef}
        webkitdirectory=""
        accept={accept}
        onChange={handleFileChange}
        multiple
      />
    </div>
  );
};

export default FileUpload;
