import { CSSProperties, ReactElement } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import styles from './InfoButton.module.scss';
import classNames from 'classnames';
import useModal from '../../hooks/useModal';

interface Props {
  icon?: ReactElement;
  className?: string;
  text: string;
  style?: CSSProperties;
}

const InfoButton = ({ icon = <FaInfoCircle />, className, text, style }: Props) => {
  const { modalMarkup, modalOpenHandler } = useModal(<div>{text}</div>, undefined, { light: true });

  if (text.trim().length === 0) return null;

  return (
    <>
      <button
        className={classNames(styles.root, className)}
        onClick={modalOpenHandler}
        style={style}
      >
        {icon}
      </button>
      {modalMarkup}
    </>
  );
};

export default InfoButton;
