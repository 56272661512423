import classNames from 'classnames';
import styles from './ColorIndicator.module.scss';
import { CSSProperties } from 'react';

interface Props {
  color: 'green' | 'red' | 'grey';
  className?: string;
  style?: CSSProperties;
  hint?: string;
}

const ColorIndicator = ({ color, className, style, hint }: Props) => {
  return (
    <div style={style} className={classNames(styles.root, className, styles[color])}>
      {hint && color !== 'grey' && (
        <span className={classNames(styles.hint, styles[color])}>{hint}</span>
      )}
    </div>
  );
};

export default ColorIndicator;
