import { CSSProperties, createElement, forwardRef } from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

interface Props {
  children: React.ReactNode;
  htmlTag?: 'div' | 'section' | 'article';
  className?: string;
  noPadding?: boolean;
  style?: CSSProperties;
}

const Card = forwardRef(
  (
    { htmlTag = 'div', children, className, noPadding, style }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return createElement(
      htmlTag,
      {
        className: classNames(styles.root, noPadding && styles.noPadding, className),
        ref,
        style,
      },
      children,
    );
  },
);

export default Card;
