import { ReactElement, cloneElement, useCallback, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';

import styles from './PopperButton.module.scss';

interface Props {
  button: ReactElement;
  dropDown: ReactElement;
  className?: string;
  closeClasses?: string[];
}

const PopperButton = ({ button, dropDown, className, closeClasses }: Props) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const popupRef = useRef<HTMLElement>(null);

  const clickAwayListener = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (
        !popupRef.current?.contains(target) ||
        (closeClasses &&
          typeof target.className === 'string' &&
          target.className.split(' ').filter(x => closeClasses.includes(x)).length)
      ) {
        setOpen(false);
        document.removeEventListener('click', clickAwayListener);
      }
    },
    [closeClasses],
  );

  const popupOpenHandler = useCallback(() => {
    setOpen(true);
    setTimeout(() => {
      document.addEventListener('click', clickAwayListener);
    }, 10);
  }, [clickAwayListener]);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement);

  const buttonElement = useMemo<ReactElement>(
    () =>
      cloneElement(button, {
        ref: setReferenceElement,
        onClick: open ? undefined : popupOpenHandler,
      }),
    [button, popupOpenHandler, open],
  );

  const popupElement = useMemo<ReactElement>(
    () =>
      cloneElement(dropDown, {
        ref: popupRef,
      }),
    [dropDown],
  );

  return (
    <div className={(styles.root, className)}>
      {buttonElement}

      {open &&
        createPortal(
          <div
            className={styles.popup}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            <FocusLock>{popupElement}</FocusLock>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default PopperButton;
