import { ColumnFiltersState, Updater } from '@tanstack/react-table';
import { ISelectOption } from '../../../types/ISelectOption';
import TextInput from '../../../components/ui/TextInput';
import { SearchIcon } from '../../../components/ui/icons/Icons';
import Select from '../../../components/ui/Select';
import { FolderTypeEnum } from '../../../types/IFolder';
import { getOptions } from '../../../utils/select';

interface Props {
  filterSetter: (updater: Updater<ColumnFiltersState>) => void;
}

const FoldersFilters = ({ filterSetter }: Props) => {
  const updateFilter = (value: ISelectOption, filterId: string) => {
    if (value) {
      filterSetter(prev => [
        ...prev.filter(f => f.id !== filterId),
        { id: filterId, value: value.value },
      ]);
    } else {
      filterSetter(prev => prev.filter(f => f.id !== filterId));
    }
  };

  return (
    <>
      <TextInput
        type="search"
        className="mr-4"
        placeholder="Введите наименование"
        buttonIcon={<SearchIcon />}
        onChange={value => updateFilter({ value, label: '' }, 'name')}
      />
      <Select
        isSearchable
        className="w-35"
        placeholder="Тип"
        options={getOptions(FolderTypeEnum)}
        isClearable
        onChange={value => updateFilter(value as ISelectOption, 'type')}
      />
    </>
  );
};

export default FoldersFilters;
