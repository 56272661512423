import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  INormalizedImage,
  actionsAdapter,
  awardsAdapter,
  imagesAdapter,
  insertedImagesAdapter,
} from './slice';
import { EditorActionType } from '../../../types/EditorAction';
import { TId } from '../../../types/TId';
import { DocSection } from '../../../types/DocSection';
import { getImageVisibility } from './utils';

export const images = imagesAdapter.getSelectors<RootState>(state => state.operatorTask.image);
export const actions = actionsAdapter.getSelectors<RootState>(state => state.operatorTask.action);
export const awards = awardsAdapter.getSelectors<RootState>(state => state.operatorTask.award);
export const insertedImages = insertedImagesAdapter.getSelectors<RootState>(
  state => state.operatorTask.insertedImage,
);

export const selectActualAwardsCount = createSelector([awards.selectAll], allAwards => {
  return allAwards.filter(aw => !aw.actions.some(a => a.type === EditorActionType.DeleteAward))
    .length;
});

export const selectComplexInfo = (state: RootState) => state.operatorTask;

export const selectComplexId = (state: RootState) => state.operatorTask.complexId;
export const selectComplexName = (state: RootState) => state.operatorTask.complexName;

export const selectImageSelection = (state: RootState) => state.operatorTask.imageSelection;
export const selectPetition = (state: RootState) => state.operatorTask.petition;
export const selectActiveDocumentInfo = (state: RootState) => state.operatorTask.activeDocument;

export const selectActiveAwardIndex = (state: RootState) => {
  if (
    !state.operatorTask.activeDocument ||
    state.operatorTask.activeDocument.docType === DocSection.Petitions
  )
    return -1;
  return state.operatorTask.award.ids.findIndex(id => id === state.operatorTask.activeDocument!.id);
};

export const selectAllDocuments = createSelector(
  [selectPetition, awards.selectAll],
  (petition, awards) => (petition ? [petition, ...awards] : awards),
);

export const selectAllActiveDocuments = createSelector(
  [selectPetition, awards.selectAll],
  (petition, awards) => {
    const activeAwards = awards.filter(
      award => !award.actions.some(a => a.type === EditorActionType.DeleteAward),
    );
    return petition ? [petition, ...activeAwards] : activeAwards;
  },
);

export const selectNotRecognizedCount = createSelector(
  [selectAllDocuments, images.selectEntities],
  (documents, images) => {
    const counts: Record<string, number> = {};
    documents.forEach(doc => {
      let count = 0;
      doc.images.forEach(img => {
        // count += images[img]?.recognized ? 0 : 1;
        count += images[img]?.recognized ? 0 : images[img]?.important ? 1 : 0;
      });
      counts[doc.barcode] = count;
    });
    return counts;
  },
);

export const selectNotRecognizedTotal = createSelector([selectNotRecognizedCount], record => {
  return Object.values(record).reduce((acc, item) => acc + item, 0);
});

export const selectDocumentImageCount = (state: RootState) => {
  if (!state.operatorTask.activeSheet.currentImage) return state.operatorTask.image.ids.length;
  const image = state.operatorTask.image.entities[state.operatorTask.activeSheet.currentImage];
  if (!image) return 0;
  if (image.docType === DocSection.Petitions) {
    return state.operatorTask.petition?.images.length ?? 0;
  }
  if (image.docType === DocSection.Awards) {
    return state.operatorTask.award.entities[image.docId]?.images.length ?? 0;
  }
  return 0;
};

export const selectActiveDocument = createSelector(
  [selectActiveDocumentInfo, selectPetition, awards.selectEntities],
  (docInfo, petition, awards) => {
    if (!docInfo) return null;
    if (docInfo.docType === DocSection.Petitions) {
      return petition;
    } else {
      return awards[docInfo.id];
    }
  },
);

export const selectAwardFactCount = (state: RootState) => {
  return state.operatorTask.awardsFactCount ?? '-';
};

export const selectActiveImageId = (state: RootState) =>
  state.operatorTask.activeSheet.currentImage;

export const selectActiveImage = (state: RootState) => {
  if (!state.operatorTask.activeSheet.currentImage) return null;
  return state.operatorTask.image.entities[state.operatorTask.activeSheet.currentImage] ?? null;
};

export const selectActiveImageIndex = createSelector(
  [selectActiveDocument, selectActiveImageId],
  (doc, imgId) => {
    const index = doc?.images.findIndex(id => id === imgId);
    return index ?? -1;
  },
);

export const selectAllImages = createSelector([images.selectAll], images => images);

export const selectAllActualImages = createSelector(
  [selectPetition, awards.selectAll, images.selectEntities],
  (petition, awards, images) => {
    const actualAwards = awards.filter(
      award => !award.actions.some(a => a.type === EditorActionType.DeleteAward),
    );
    const petitionImages = petition?.images ?? [];
    const awardImages = actualAwards.flatMap(a => a.images);
    const imageIds = [...petitionImages, ...awardImages];
    return imageIds.map(id => images[id]) as INormalizedImage[];
  },
);

export const selectImagesByIds = createSelector(
  [images.selectEntities, (state, ids: TId[]) => ids],
  (images, ids) => {
    return ids
      .map(id => {
        if (images[id]) return images[id];
        return;
      })
      .filter(img => img !== undefined) as INormalizedImage[];
  },
);

export const selectRotateDeg = createSelector([selectActiveImage], img => {
  if (!img) return 0;
  return (
    img.rotation +
    img.actions
      .filter(a => a.type === EditorActionType.Rotate)
      .reduce((sum, action) => sum + action.payload, 0)
  );
});

export const selectInsertedImages = insertedImages.selectAll;

export const selectInsertedActiveImageId = (state: RootState) => state.operatorTask.insertedActive;

export const selectInsertedActiveImage = (state: RootState) => {
  if (!state.operatorTask.insertedActive) return null;
  return state.operatorTask.insertedImage.entities[state.operatorTask.insertedActive];
};

export const selectInsertedSelection = (state: RootState) => state.operatorTask.insertedSelection;

export const selectFilter = (state: RootState) => state.operatorTask.filter;

export const selectImageVisibility = createSelector(
  [selectActiveImage, selectFilter],
  (img, filters) => {
    if (!img) return false;
    return getImageVisibility(img, filters);
  },
);

export const selectComplex = createSelector(
  [selectComplexInfo, selectPetition, awards.selectAll, images.selectEntities],
  (complex, petition, awards, imageEntities) => {
    return {
      complexId: complex.complexId,
      awardsFactCount: complex.awardsFactCount,
      petition: petition
        ? {
            ...petition,
            images: petition.images.map(imgId => imageEntities[imgId]) as INormalizedImage[],
          }
        : null,
      awards: awards.map(a => ({
        ...a,
        images: a.images.map(imgId => imageEntities[imgId]) as INormalizedImage[],
      })),
    };
  },
);

export type TNormalizedComplex = ReturnType<typeof selectComplex>;
