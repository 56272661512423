import { createColumnHelper } from '@tanstack/react-table';
import { ComplexPriorityEnum, ComplexStateEnum, IComplexInfo } from '../../../types/IComplexInfo';
import Tag from '../../../components/ui/Tag';
import { POLLING_INTERVAL_S } from '../../../const';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import { IComplexesOptions, useGetComplexesListQuery } from '../../../redux/api/complexes';
import InfoButton from '../../../components/ui/InfoButton';
import ErrorCell from '../../../components/grid/ErrorCell';
import { getReadableDateTime } from '../../../utils/date';
import { Dispatch, SetStateAction } from 'react';
import { useServerSortingPagination } from '../../../hooks/useServerSortingPagination';

interface Props {
  onRowClick: (row: IComplexInfo) => void;
  fetchOptions: IComplexesOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IComplexesOptions>>;
}

const columnHelper = createColumnHelper<IComplexInfo>();

const columns = [
  columnHelper.display({
    id: 'index',
    header: '#',
    cell: ({ row }) => row.index + 1,
    size: 50,
  }),
  columnHelper.accessor('name', {
    header: 'Имя документа',
    cell: info => info.renderValue(),
    filterFn: 'includesString',
    enableColumnFilter: true,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('barcode', {
    header: 'Штрихкод (QR код)',
    cell: info => info.renderValue(),
    size: 170,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    enableGlobalFilter: false,
    filterFn: makeDateRangeFilterFunction<IComplexInfo>(),
  }),
  columnHelper.accessor('state', {
    header: 'Статус',
    cell: info => (
      <Tag
        variant={info.getValue().includes('error') ? 'low' : 'status'}
        style={{ minWidth: '200px', margin: '0 auto', display: 'block' }}
      >
        {ComplexStateEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('userShowName', {
    header: 'Оператор',
    cell: info => info.renderValue(),
    enableColumnFilter: true,
    enableGlobalFilter: false,
    sortingFn: 'text',
  }),
  columnHelper.accessor('priority', {
    header: 'Приоритет',
    cell: info =>
      info.getValue() ? (
        <Tag variant="status" style={{ minWidth: '123px', margin: '0 auto' }}>
          {ComplexPriorityEnum.getDisplayName(info.getValue()!)}
        </Tag>
      ) : null,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    sortingFn: 'text',
  }),
  columnHelper.accessor('state', {
    id: 'errors',
    header: 'Ошибки',
    cell: info => (
      <ErrorCell isError={info.getValue().includes('error') || info.getValue().includes('fail')} />
    ),
    filterFn: (row, columnId, filterValue) => {
      if (
        (filterValue && row.original.state.includes('error')) ||
        (filterValue && row.original.state.includes('fail'))
      )
        return true;
      else if (filterValue === false && !row.original.state.includes('error')) return true;
      else return false;
    },
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('errorMessage', {
    header: 'Дополнительно',
    cell: info =>
      info.getValue() && info.getValue()?.trim().length ? (
        <InfoButton text={info.getValue() as string} style={{ verticalAlign: 'middle' }} />
      ) : null,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableSorting: false,
  }),
];

const ProcessingGrid = ({ onRowClick, fetchOptions, fetchOptionsSetter }: Props) => {
  const { data, isFetching: listLoading } = useGetComplexesListQuery(fetchOptions, {
    pollingInterval: POLLING_INTERVAL_S,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  return (
    <DataGrid
      gridKey={QueryKeys.Processing}
      rows={data?.results}
      totalCount={data?.pagination?.totalCount}
      pagesCount={
        data ? Math.ceil(data.pagination?.totalCount / data.pagination?.perPage) : undefined
      }
      isLoading={listLoading}
      columns={columns}
      rowIdSelector={row => row.id}
      hideAllSettings
      onRowClick={row => onRowClick(row.original)}
      serverControlled={true}
      onPaginationChange={paginationChangeHandler}
      onSortingChange={sortingChangeHandler}
    />
  );
};

export default ProcessingGrid;
