import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import { ComplexType } from '../../../types/IComplexInfo';
import { IProcessingParams } from './OperatorGrid';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import { QueryKeys } from '../../../data/QueryKeys';
import {
  complexesApi,
  useDeleteComplexMutation,
  useDuplicateActualizeMutation,
  useGetComplexesListQuery,
  usePriorityComplexMutation,
} from '../../../redux/api/complexes';
import { OPERATOR_COMPLEXES } from './filters';
import useModal from '../../../hooks/useModal';
import UpdateComplexModal from '../modals/UpdateComplexModal';
import OtherOperatorModal from '../modals/OtherOperatorModal';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { generatePath, useNavigate } from 'react-router';
import { Routes } from '../../../Router';
import { toast } from 'react-toastify';
import Spinner from '../../../components/ui/Spinner';

interface Props {
  processingParams: IProcessingParams;
  setProcessingParams: Dispatch<SetStateAction<IProcessingParams>>;
  complexName: string | null | undefined;
  setComplexName: Dispatch<SetStateAction<string | null | undefined>>;
}

const OperatorActions = ({
  processingParams,
  setProcessingParams,
  complexName,
  setComplexName,
}: Props) => {
  const activeRow = useAppSelector(store =>
    selectionSelectors.selectSelectedRowIdByGridKey(store, QueryKeys.Operator),
  );

  const [duplicateAction, setDuplicateAction] = useState<'accept' | 'cancel'>('accept');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { refetch } = useGetComplexesListQuery({ states: OPERATOR_COMPLEXES });

  const [priorityComplex, { isLoading: settingPriority, isSuccess: prioritySet }] =
    usePriorityComplexMutation();

  const [deleteComplex, { isLoading: deleteingComplex, isSuccess: complexDeleted }] =
    useDeleteComplexMutation();

  const [duplicateActualize, { isLoading: actualizingDuplicate, isSuccess: duplicateActualized }] =
    useDuplicateActualizeMutation();

  const { modalMarkup, modalOpenHandler } = useModal(
    <UpdateComplexModal
      complexId={activeRow}
      countSetting={processingParams.needsCount}
      barcodeSetting={processingParams.needsBarcode}
      initialCount={processingParams.initialCount}
    />,
  );

  const { modalMarkup: otherOperatorModalMarkup, modalOpenHandler: otherOperatorModalOpenHandler } =
    useModal(<OtherOperatorModal taskId={activeRow!} />);

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalCloseHandler,
    modalMarkup: confirmModal,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deleteComplex(activeRow)}
    />,
  );

  const {
    modalOpenHandler: duplicateModalOpenHandler,
    modalCloseHandler: duplicateModalCloseHandler,
    modalMarkup: duplicateModal,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение действия с дубликатом"
      variant={duplicateAction === 'cancel' ? 'danger' : 'success'}
      text={
        duplicateAction === 'accept'
          ? 'Вы действительно хотите утвердить дубликат?'
          : 'Вы действительно хотите отклонить дубликат?'
      }
      onConfirm={() => {
        duplicateActualize({ id: activeRow!, action: duplicateAction });
        setProcessingParams(prev => ({ ...prev, isDuplicate: false }));
      }}
    />,
  );

  const historyClickHandler = useCallback(() => {
    const path = generatePath(Routes.TaskHistory, {
      section: QueryKeys.Operator,
      taskId: activeRow!,
    });
    navigate(path);
  }, [activeRow, navigate]);

  const priorityHandler = useCallback(() => {
    if (!activeRow) return toast.error('Не удалось определить идентификатор процесса!');
    priorityComplex(activeRow);
  }, [priorityComplex, activeRow]);

  useEffect(() => {
    if (complexDeleted) {
      dispatch(selectionActions.removeSelection(QueryKeys.Operator));
      dispatch(selectionActions.setSelectedPreview(null));
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: OPERATOR_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
      toast.success(`Задание ${complexName} успешно удалено!`);
      setComplexName(undefined);
      modalCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, complexDeleted, modalCloseHandler]);

  useEffect(() => {
    if (prioritySet) {
      toast.success(`Приоритет задания ${complexName} успешно повышен!`);
    }
    dispatch(
      complexesApi.endpoints.getComplexesList.initiate(
        { states: OPERATOR_COMPLEXES },
        {
          forceRefetch: true,
          subscribe: false,
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prioritySet]);

  useEffect(() => {
    if (duplicateActualized) {
      toast.success(`Статус задания-дубликата установлен!`);
      duplicateModalCloseHandler();
    }
    dispatch(
      complexesApi.endpoints.getComplexesList.initiate(
        { states: OPERATOR_COMPLEXES },
        {
          forceRefetch: true,
          subscribe: false,
        },
      ),
    );
  }, [dispatch, duplicateActualized, duplicateModalCloseHandler]);

  const detailsNavigateHandler = useCallback(() => {
    const path = generatePath(Routes.Details, {
      section: QueryKeys.Operator,
      taskId: activeRow!,
    });
    navigate(path);
  }, [activeRow, navigate]);

  const takeJobHandler = useCallback(() => {
    if (processingParams.otherOperator) {
      otherOperatorModalOpenHandler();
    } else if (!processingParams.needsCount && !processingParams.needsBarcode) {
      detailsNavigateHandler();
    } else {
      modalOpenHandler();
    }
  }, [
    detailsNavigateHandler,
    modalOpenHandler,
    otherOperatorModalOpenHandler,
    processingParams.needsBarcode,
    processingParams.needsCount,
    processingParams.otherOperator,
  ]);

  return (
    <>
      {(deleteingComplex || settingPriority || actualizingDuplicate) && <Spinner />}
      <GridActions>
        {processingParams.type !== ComplexType.AwardBig ? (
          <Button onClick={takeJobHandler} disabled={!activeRow || processingParams.disabled}>
            Обработать
          </Button>
        ) : (
          <Button
            onClick={detailsNavigateHandler}
            disabled={!activeRow || !processingParams.readyForAssembly}
          >
            Собрать
          </Button>
        )}
        <Button onClick={() => refetch()}>Обновить</Button>
        <Button
          disabled={!activeRow || !processingParams.barcodeChangable}
          onClick={() => {
            setProcessingParams(prev => ({ ...prev, needsCount: false, needsBarcode: true }));
            modalOpenHandler();
          }}
        >
          Присвоить штрихкод
        </Button>
        <Button disabled={!activeRow} onClick={historyClickHandler}>
          История обработки
        </Button>
        <Button
          disabled={!activeRow || !processingParams.priorityRiseable}
          onClick={priorityHandler}
        >
          Повысить приоритет
        </Button>
        {processingParams.isDuplicate && (
          <>
            <Button
              disabled={!activeRow || !processingParams.isDuplicate}
              onClick={() => {
                setDuplicateAction('accept');
                duplicateModalOpenHandler();
              }}
            >
              Дубликат - утвердить
            </Button>
            <Button
              disabled={!activeRow || !processingParams.isDuplicate}
              onClick={() => {
                setDuplicateAction('cancel');
                duplicateModalOpenHandler();
              }}
            >
              Дубликат - отклонить
            </Button>
          </>
        )}
        <Button
          className="mt-12"
          disabled={!activeRow}
          variant="danger"
          onClick={confirmModalOpenHandler}
        >
          Удалить
        </Button>
      </GridActions>
      {modalMarkup}
      {otherOperatorModalMarkup}
      {confirmModal}
      {duplicateModal}
    </>
  );
};

export default OperatorActions;
