export const MIN_PAGE_WIDTH = 100;
export const PAGE_SIDES_RATIO = 1.414;
export const SLIDE_PREVIEW_SIZE = 180;
export const SLIDER_WIDTH = 222;

export const PARTS_MAX_COUNT = 10;

export const POLLING_INTERVAL_XS = 10000;
export const POLLING_INTERVAL_S = 30000;
export const POLLING_INTERVAL_M = 60000;

export const AWARD_REGEX = /^21\d{11}$/;
export const PETITION_REGEX = /^29\d{11}$/;

export const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'https://publishing.dev.wepromo.ru/'
    : '/';
