import GridPage from '../../gridPage/GridPage';
import DepartmentsReportActions from './DepartmentsReportActions';
import DepartmentsReportFilters from './DepartmentsReportFilters';
import DepartmentsReportGrid from './DepartmentsReportGrid';

const DepartmentsReport = () => {
  return (
    <GridPage
      title="Статистика по участку"
      className="pt-2 pb-7"
      panel={<DepartmentsReportFilters />}
      grid={<DepartmentsReportGrid />}
      actions={<DepartmentsReportActions />}
    />
  );
};
export default DepartmentsReport;
