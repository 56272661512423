import { api } from '../api';
import { ISetting } from '../../types/ISetting';

export const settingsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getSettings: builder.query<ISetting[], void>({
      query: () => 'api/settings',
    }),
    editSetting: builder.mutation<string, { id: string; key: string; value: string }>({
      query: ({ id, key, value }) => ({
        url: `api/settings/${id}`,
        method: 'PUT',
        body: { key, value },
      }),
    }),
  }),
});

export const { useGetSettingsQuery, useEditSettingMutation } = settingsApi;
