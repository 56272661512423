import { Dispatch, SetStateAction } from 'react';
import {
  IStatOptions,
  IUserStatItem,
  useGetSingleUserStatQuery,
  useGetUsersStatQuery,
} from '../../../../redux/api/statistic';
import { createColumnHelper } from '@tanstack/react-table';
import Tag from '../../../../components/ui/Tag';
import DataGrid from '../../../../components/grid/DataGrid';
import { QueryKeys } from '../../../../data/QueryKeys';
import classNames from 'classnames';
import styles from '../ReportsPage.module.scss';
import { useSearchParams } from 'react-router-dom';
import { useServerSortingPagination } from '../../../../hooks/useServerSortingPagination';

interface Props {
  fetchOptions: IStatOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const columnHelper = createColumnHelper<IUserStatItem>();

const columns = [
  columnHelper.accessor('user.showName', {
    header: 'ФИО',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('petitionsTotal', {
    header: 'Кол-во ходатайств',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('awardsTotal', {
    header: 'Кол-во НЛ',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во обр.',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('validationErrorsTotal', {
    header: 'Ошибки загр.',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('processErrorsTotal', {
    header: 'Ошибки обр.',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
];

const UsersReportGrid = ({ fetchOptions, fetchOptionsSetter }: Props) => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  const { data, isFetching } = useGetUsersStatQuery(fetchOptions, {
    refetchOnMountOrArgChange: true,
    skip: !!userId,
  });

  const { data: userStat, isFetching: userStatFetching } = useGetSingleUserStatQuery(
    {
      userId: userId!,
      params: { dateFromTs: fetchOptions.dateFromTs, dateToTs: fetchOptions.dateToTs },
    },
    {
      skip: !userId,
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <>
      {!userId && (
        <div className={classNames(styles.stats, 'mb-4')}>
          <span className="ml-10 mr-2">Всего ходатайств</span>
          <Tag className="mr-10">{data?.totals.petitionsTotal ?? '-'}</Tag>
          <span className="mr-2">Всего НЛ</span>
          <Tag className="mr-10">{data?.totals.awardsTotal ?? '-'}</Tag>
          <span className="mr-2">Всего образов</span>
          <Tag className="mr-10">{data?.totals.pagesTotal ?? '-'}</Tag>
          <span className="mr-2">Всего ошибок загрузки</span>
          <Tag className="mr-10">{data?.totals.validationErrorsTotal ?? '-'}</Tag>
          <span className="mr-2">Всего ошибок обработки</span>
          <Tag>{data?.totals.processErrorsTotal ?? '-'}</Tag>
        </div>
      )}
      <DataGrid
        gridKey={QueryKeys.UserReport}
        rows={userId ? (userStat ? [userStat] : []) : data?.results}
        totalCount={data?.pagination.totalCount}
        pagesCount={
          data ? Math.ceil(data.pagination.totalCount / data.pagination.perPage) : undefined
        }
        isLoading={userId ? userStatFetching : isFetching}
        columns={columns}
        rowIdSelector={row => row.user.id.toString()}
        hideAllSettings
        hidePagination={!!userId}
        serverControlled={true}
        onPaginationChange={paginationChangeHandler}
        onSortingChange={sortingChangeHandler}
        pageIndex={fetchOptions.page}
        perPage={fetchOptions.perPage}
      />
    </>
  );
};

export default UsersReportGrid;
