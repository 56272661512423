import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Routes } from '../Router';

export const rtkQueryErrorHandler: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    const { error, payload } = action;

    let message = 'Что-то пошло не так!';
    if (error && payload && payload.status === 401 && location.pathname !== Routes.Login) {
      location.pathname = Routes.Login;
    } else if (error && payload && payload.status === 401 && location.pathname === Routes.Login) {
      console.error(payload);
    } else if (
      error &&
      payload &&
      payload.data &&
      payload.data.message &&
      typeof payload.data.message === 'string'
    ) {
      message = payload.data.message;
      console.error(payload);
      toast.error(message);
    } else if (error) {
      console.error(payload);
      toast.error(message);
    }
  }

  return next(action);
};
