import { useEffect, useState } from 'react';
import GridPage from '../gridPage/GridPage';
import EventsFilters from './EventsFilters';
import EventsGrid from './EventsGrid';
import EventsActions from './EventsActions';
import { IEventsOptions } from '../../../redux/api/events';

interface Props {
  isSystem: boolean;
}

const EventsPage = ({ isSystem }: Props) => {
  const [fetchOptions, setFetchOptions] = useState<IEventsOptions>({
    page: 0,
    perPage: 20,
    userEvent: !isSystem,
  });

  useEffect(() => {
    setFetchOptions(prev => ({
      ...prev,
      userEvent: !isSystem,
    }));
  }, [isSystem]);

  return (
    <GridPage
      title={isSystem ? 'Системный журнал' : 'Журнал событий'}
      panel={<EventsFilters isSystem={isSystem} fetchOptionsSetter={setFetchOptions} />}
      grid={
        <EventsGrid
          isSystem={isSystem}
          fetchOptions={fetchOptions}
          fetchOptionsSetter={setFetchOptions}
        />
      }
      actions={<EventsActions fetchOptions={fetchOptions} />}
    />
  );
};

export default EventsPage;
