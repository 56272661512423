import { useGetCRInstancesListQuery } from '../../../redux/api/crinstances';
import { createColumnHelper } from '@tanstack/react-table';
import { ICRInstance } from '../../../types/ICRInstance';
import Tag from '../../../components/ui/Tag';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import ColorIndicator from '../../../components/common/ColorIndicator';

const columnHelper = createColumnHelper<ICRInstance>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Наименование',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('url', {
    header: 'Адрес',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('threads', {
    header: 'Потоки',
    cell: info => <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('active', {
    header: 'Активность',
    cell: info => (
      <Tag
        variant={info.getValue() ? 'status' : 'low'}
        style={{ minWidth: '120px', margin: '0 auto' }}
      >
        {info.getValue() ? 'Да' : 'Нет'}
      </Tag>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'Статус',
    cell: info => (
      <ColorIndicator
        style={{ marginLeft: '20px' }}
        color={info.row.original.active ? (info.getValue() === 'alive' ? 'green' : 'red') : 'grey'}
        hint={info.getValue() === 'alive' ? 'Доступен' : 'Недоступен'}
      />
    ),
  }),
];

const CRInstancesGrid = () => {
  const { data, isLoading } = useGetCRInstancesListQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.CRInstances}
      rows={data}
      isLoading={isLoading}
      columns={columns}
      rowIdSelector={row => row.id.toString()}
      hideAllSettings
      filterColumn="name"
    />
  );
};

export default CRInstancesGrid;
