import { useCallback, useEffect, useState } from 'react';
import DateInput from './DateInput';
import { IDateRange } from '../../types/IDateRange';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import styles from './DateRangeInput.module.scss';

interface Props {
  wrapped?: boolean;
  className?: string;
  onChange?: (range: IDateRange) => void;
  value?: IDateRange;
}

const DateRangeInput = ({ wrapped, className, onChange, value }: Props) => {
  const [dateRange, setDateRange] = useState<IDateRange>(
    value ?? {
      start: undefined,
      end: undefined,
    },
  );

  useEffect(() => {
    if (value) {
      setDateRange(value);
    } else {
      setDateRange({ start: undefined, end: undefined });
    }
  }, [value]);

  const rangeStartChangeHandler = useCallback(
    (startDate?: DateTime) => {
      const value = startDate?.startOf('day');
      let end = dateRange.end;
      if (dateRange.end && value && dateRange.end < value) {
        end = value.endOf('day');
      }
      setDateRange({ start: value, end });
    },
    [dateRange.end],
  );

  const rangeEndChangeHandler = useCallback(
    (endDate?: DateTime) => {
      const value = endDate ? endDate.endOf('day') : undefined;
      let start = dateRange.start;
      if (dateRange.start && value && dateRange.start > value) {
        start = value.startOf('day');
      }
      setDateRange({ start, end: value });
    },
    [dateRange.start],
  );

  useEffect(() => {
    onChange?.(dateRange);
  }, [dateRange, onChange]);

  return (
    <div className={classNames(styles.root, wrapped && styles.wrapped, className)}>
      <DateInput
        label="Дата с:"
        variant="vertical"
        size="small"
        value={dateRange.start}
        onChange={rangeStartChangeHandler}
      />
      <DateInput
        label="Дата по:"
        variant="vertical"
        size="small"
        value={dateRange.end}
        onChange={rangeEndChangeHandler}
      />
    </div>
  );
};

export default DateRangeInput;
