import { TId } from './TId';
import { Enum } from '../data/enum';

export interface IPrintInfo {
  id: TId;
  name: string;
  state: PrintJobState;
  author: string;
  pagesTotal: number;
  dateAddTs: string;
  dateExecuteTs: string;
  userId: number;
  userShowName: string;
  warnings?: string[];
}

export enum PrintJobState {
  Loading = 'loading',
  Fail = 'fail',
  ReadyToPrint = 'readytoprint',
  InWork = 'inwork',
  Printing = 'printing',
  ErrorPrinting = 'errorprinting',
  Complete = 'complete',
  Deleted = 'deleted',
}

export const PrintJobStateEnum = new Enum<PrintJobState>([
  [PrintJobState.Loading, 'Копирование'],
  [PrintJobState.Fail, 'Ошибка копирования'],
  [PrintJobState.ReadyToPrint, 'Напечатать'],
  [PrintJobState.InWork, 'Обработка оператором'],
  [PrintJobState.Printing, 'Печать'],
  [PrintJobState.ErrorPrinting, 'Ошибка печати'],
  [PrintJobState.Complete, 'Исполнен'],
  [PrintJobState.Deleted, 'Удален'],
]);
