import { useCallback } from 'react';
import { useGetUsersListQuery } from '../../../../redux/api/users';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import Select from '../../../../components/ui/Select';
import { getSortedUsers } from '../../../../utils/select';
import { ISelectOption } from '../../../../types/ISelectOption';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { reportActions, reportSelectors } from '../../../../redux/slices/reports';
import { DateTime } from 'luxon';

const UsersReportFilters = () => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(reportSelectors.selectEmployeesReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectEmployeesReportEndDate);

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: users, isLoading: usersLoading } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      dispatch(
        reportActions.setRange({
          report: 'employees',
          range: { start: dates.start?.toString(), end: dates.end?.toString() },
        }),
      );
    },
    [dispatch],
  );

  const userSelectHandler = useCallback(
    (option: unknown) => {
      if (option) {
        searchParams.set('userId', (option as ISelectOption).value);
      } else {
        searchParams.delete('userId');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <>
      <DateRangeInput
        wrapped
        className="mr-4"
        onChange={dateChangeHandler}
        value={{
          start: startDate ? DateTime.fromISO(startDate) : undefined,
          end: endDate ? DateTime.fromISO(endDate) : undefined,
        }}
      />
      <Select
        isSearchable
        className="mr-4 w-30"
        placeholder="Оператор"
        options={getSortedUsers(users, 'id')}
        isClearable
        onChange={userSelectHandler}
        isLoading={usersLoading}
      />
    </>
  );
};
export default UsersReportFilters;
