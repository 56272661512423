import { IFolder } from '../../types/IFolder';
import { TId } from '../../types/TId';
import { api } from '../api';

export const foldersApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getFoldersList: builder.query<IFolder[], void>({
      query: () => 'api/folders',
    }),
    getFolderById: builder.query<IFolder, TId>({
      query: id => ({
        url: `api/folders/${id}`,
      }),
      forceRefetch: () => true,
    }),
    createFolder: builder.mutation<IFolder, Omit<IFolder, 'id' | 'dateaddts' | 'dateupdatets'>>({
      query: body => ({
        url: 'api/folders',
        method: 'POST',
        body,
      }),
    }),
    editFolder: builder.mutation<IFolder, IFolder>({
      query: body => ({
        url: `api/folders/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteFolder: builder.mutation<string, string>({
      query: id => ({
        url: `api/folders/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFoldersListQuery,
  useGetFolderByIdQuery,
  useCreateFolderMutation,
  useEditFolderMutation,
  useDeleteFolderMutation,
} = foldersApi;
