import { createColumnHelper } from '@tanstack/react-table';
import { ISetting } from '../../../types/ISetting';
import { useGetSettingsQuery } from '../../../redux/api/settings';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';

const columnHelper = createColumnHelper<ISetting>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Назначение',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('value', {
    header: 'Значение',
    cell: info => {
      return info.row.original.spec?.type === 'boolean'
        ? info.getValue() === '0'
          ? 'Нет'
          : 'Да'
        : info.renderValue();
    },
  }),
];

const SettingsGrid = () => {
  const { data, isFetching } = useGetSettingsQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.Settings}
      rows={data}
      columns={columns}
      isLoading={isFetching}
      rowIdSelector={row => row.id.toString()}
      hideAllSettings
      filterColumn="name"
    />
  );
};

export default SettingsGrid;
