import { createColumnHelper } from '@tanstack/react-table';
import Tag from '../../../components/ui/Tag';
import DataGrid from '../../../components/grid/DataGrid';
import { QueryKeys } from '../../../data/QueryKeys';
import { useGetProcessListQuery } from '../../../redux/api/process';
import { IProcess } from '../../../types/IProcess';

const columnHelper = createColumnHelper<IProcess>();

const columns = [
  /* columnHelper.accessor('name', {
    header: 'Наименование',
    cell: info => info.renderValue(),
  }), */
  columnHelper.accessor('description', {
    header: 'Процесс',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('status.running', {
    header: 'Активность',
    cell: info => (
      <Tag variant={info.getValue() ? 'status' : 'low'} style={{ minWidth: '90px' }}>
        {info.getValue() ? 'Да' : 'Нет'}
      </Tag>
    ),
  }),
];

const ProcessesGrid = () => {
  const { data, isFetching } = useGetProcessListQuery();

  return (
    <DataGrid
      gridKey={QueryKeys.Processes}
      rows={data}
      isLoading={isFetching}
      columns={columns}
      rowIdSelector={row => row.id}
      hideAllSettings
      filterColumn="description"
    />
  );
};

export default ProcessesGrid;
