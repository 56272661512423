import { FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { IPrintJobAuthor } from '../../../types/IPrintJobAuthor';
import {
  printjobAuthorsApi,
  useCreatePrintjobAuthorMutation,
  useGetPrintjobAuthorByIdQuery,
  useUpdatePrintjobAuthorMutation,
} from '../../../redux/api/printJobAuthors';
import { toast } from 'react-toastify';
import Spinner from '../../../components/ui/Spinner';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Select from '../../../components/ui/Select';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import { useGetDivisionsListQuery } from '../../../redux/api/divisions';
import { ISelectOption } from '../../../types/ISelectOption';

interface Props {
  authorId: string | undefined;
  onClose?: () => void;
}

const PrintjobAuthorModal = ({ authorId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { data: divisions, isFetching } = useGetDivisionsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [formData, setFormData] = useState<
    Omit<IPrintJobAuthor, 'id' | 'key' | 'showName' | 'divisionName'>
  >({
    name: '',
    divisionId: '',
  });

  const { data: author, isFetching: authorFetching } = useGetPrintjobAuthorByIdQuery(authorId!, {
    skip: !authorId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (author) {
      setFormData({
        name: author.name,
        divisionId: author.divisionId,
      });
    }
  }, [author]);

  const [createAuthor, { isLoading: creatingAuthor, data: createdAuthor }] =
    useCreatePrintjobAuthorMutation();
  const [editAuthor, { isLoading: editingAuthor, data: editResult }] =
    useUpdatePrintjobAuthorMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const divisionOptions: ISelectOption[] = useMemo(() => {
    const options: ISelectOption[] = divisions?.map(d => ({ value: d.id, label: d.name })) ?? [];
    return options;
  }, [divisions]);

  const selectedDivision = useMemo(
    () => divisionOptions.find(d => d.value === formData.divisionId),
    [divisionOptions, formData.divisionId],
  );

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.name.trim().length < 2) {
      toast.error('Некорректное значение имени!');
      isValid = false;
    }
    if (!formData.divisionId.length) {
      toast.error('Выберите управление!');
      isValid = false;
    }
    return isValid;
  }, [formData.divisionId, formData.name]);

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        createAuthor({ ...formData, key: formData.name });
      } else {
        return;
      }
    },
    [createAuthor, formData, validateForm],
  );

  useEffect(() => {
    if (createdAuthor || editResult) {
      onClose?.();
      dispatch(
        printjobAuthorsApi.endpoints.getPrinjobAuthorList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createdAuthor, dispatch, editResult, onClose]);

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();

      if (!author) return;

      const isFormValid = validateForm();

      if (!isFormValid) return toast.error('Данные некорректны!');

      editAuthor({
        id: author.id,
        body: { ...formData, key: formData.name },
      });
    },
    [author, editAuthor, formData, validateForm],
  );

  return (
    <div>
      {(creatingAuthor || editingAuthor || authorFetching) && <Spinner />}
      <ModalHeading className="mb-6">
        {authorId ? 'Редактирование данных сотрудника' : 'Создание нового сотрудника'}
      </ModalHeading>
      <form onSubmit={authorId ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="name"
          name="name"
          label="ФИО"
          className="mb-2"
          value={formData.name}
          onChange={value => formChangeHandler('name', value)}
        />
        <Select
          label="Управление"
          className="mb-4"
          placeholder="Выберите управление"
          options={divisionOptions}
          value={selectedDivision}
          isLoading={isFetching}
          onChange={value =>
            setFormData(prev => ({
              ...prev,
              divisionId: (value as ISelectOption).value,
            }))
          }
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {authorId ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default PrintjobAuthorModal;
