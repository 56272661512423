import { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import QrCode from '../components/ui/QrCode';

import styles from './useQrList.module.scss';

const useQrList = (barcode: string, partsTotal: number) => {
  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: `Qr-коды частей задания ${barcode}`,
  });

  const qrData = new Array(+partsTotal)
    .fill(barcode)
    .map((item, i) => ({ label: `${item}_${i + 1}`, data: `${item}_${i + 1}` }));

  const printHandler = useCallback(() => {
    handlePrint(null, () => printRef.current);
  }, [handlePrint]);

  const content = (
    <div style={{ display: 'none' }}>
      <div className={styles.printable} ref={printRef}>
        {qrData.map(code => (
          <QrCode
            key={code.data}
            data={code.data}
            label={code.label}
            width={600}
            className={styles.qrCode}
          />
        ))}
      </div>
    </div>
  );

  return { content, printHandler };
};
export default useQrList;
