import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Tag.module.scss';

interface Props {
  children: ReactNode;
  variant?: 'low' | 'average' | 'neutral' | 'status';
  bold?: boolean;
  className?: string;
  style?: CSSProperties;
  link?: boolean;
}

export type TTagVariants = Props['variant'];

const Tag = ({ children, variant, bold, className, style, link }: Props) => {
  return (
    <span
      className={classNames(
        styles.root,
        className,
        variant && styles[variant],
        bold && styles.bold,
        link && styles.link,
      )}
      style={style}
    >
      {children}
    </span>
  );
};

export default Tag;
