import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import {
  complexesApi,
  useGetPetitionsListQuery,
  useUpdateComplexMutation,
} from '../../../redux/api/complexes';
import { TId } from '../../../types/TId';
import Spinner from '../../../components/ui/Spinner';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Select from '../../../components/ui/Select';
import { ISelectOption } from '../../../types/ISelectOption';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import { toast } from 'react-toastify';
import { generatePath, useNavigate } from 'react-router';
import { QueryKeys } from '../../../data/QueryKeys';
import { Routes } from '../../../Router';
import { operatorTaskActions } from '../../../redux/slices/operatorTask';
import { OPERATOR_COMPLEXES } from '../grids/filters';
import { AWARD_REGEX } from '../../../const';

interface Props {
  complexId: TId | undefined;
  countSetting: boolean;
  barcodeSetting: boolean;
  initialCount: number;
  countEditing?: boolean;
  onClose?: () => void;
}

const UpdateComplexModal = ({
  complexId,
  onClose,
  initialCount,
  countSetting,
  barcodeSetting,
  countEditing,
}: Props) => {
  const navigate = useNavigate();
  const [barcodes, setBarcodes] = useState<string[]>([]);
  const [code, setCode] = useState('');
  const [count, setCount] = useState(initialCount ? initialCount.toString() : '');

  const dispatch = useAppDispatch();

  const { data: petitions, isLoading: petitionsLoading } = useGetPetitionsListQuery(complexId!, {
    skip: !complexId || !barcodeSetting,
    refetchOnMountOrArgChange: true,
  });

  const [updateComplex, { isLoading: updating, isSuccess: updatingSuccess }] =
    useUpdateComplexMutation();

  useEffect(() => {
    if (petitions) {
      setBarcodes(petitions.map(p => p.barcode));
      setCode(petitions[0]?.barcode ?? '');
    }
  }, [petitions]);

  useEffect(() => {
    if (updatingSuccess) {
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: OPERATOR_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );

      dispatch(
        complexesApi.endpoints.getComplexById.initiate(complexId!, {
          forceRefetch: true,
          subscribe: false,
        }),
      );

      if (countEditing) {
        onClose?.();
        dispatch(operatorTaskActions.setAwardsFactCount(+count));
      } else if (!countSetting) {
        onClose?.();
      } else {
        const path = generatePath(Routes.Details, {
          section: QueryKeys.Operator,
          taskId: complexId!,
        });
        navigate(path);
      }
    }
  }, [complexId, count, countEditing, countSetting, dispatch, navigate, onClose, updatingSuccess]);

  const submitHandler = () => {
    if (!complexId) {
      return toast.error('Не определен идентификатор задания!');
    }
    if (barcodeSetting && !AWARD_REGEX.test(code.trim())) {
      return toast.error('Штрихкод должен состоять из 13 цифр и начинаться на 21');
    }

    if (countSetting && (!count.trim().length || isNaN(+count)))
      return toast.error('Введите количество наградных листов!');

    const data: { barcode?: string; awardsFactCount?: number } = {};
    if (barcodeSetting) {
      data.barcode = code;
    }
    if (countSetting) {
      data.awardsFactCount = +count;
    }

    updateComplex({ complexId, data });
  };

  const heading = useMemo(() => {
    if (countEditing) return 'Изменение фактического количества НЛ';
    else return countSetting ? 'Обработка задания' : 'Присвоение штрихкода';
  }, [countEditing, countSetting]);

  return (
    <div>
      {(updating || petitionsLoading) && <Spinner />}
      <ModalHeading>{heading}</ModalHeading>
      {barcodeSetting &&
        (barcodes.length < 2 ? (
          <TextInput
            type="text"
            label="Введите штрихкод"
            value={code}
            onChange={setCode}
            maxLength={13}
          />
        ) : (
          <Select
            creatable
            isClearable
            label="Выберите или введите штрихкод"
            placeholder="Введите значение"
            options={barcodes.map(bc => ({
              label: bc,
              value: bc,
            }))}
            value={code.length ? { value: code, label: code } : undefined}
            onChange={value => setCode((value as ISelectOption)?.value ?? '')}
          />
        ))}
      {countSetting && (
        <TextInput
          type="number"
          label="Введите количество наградных листов"
          className="mt-4 mb-4"
          min={1}
          value={count}
          onChange={setCount}
          autoFocus
        />
      )}
      <Buttons className="mt-8">
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button onClick={submitHandler} variant="success">
          OK
        </Button>
      </Buttons>
    </div>
  );
};

export default UpdateComplexModal;
