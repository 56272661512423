import { ReactNode, useEffect, useRef } from 'react';

import Spinner from '../../components/ui/Spinner';
import Toolbar from '../../components/ui/Toolbar';
import { useAppDispatch } from '../../redux/hooks';
import { PAGE_SIDES_RATIO } from '../../const';
import { layoutActions } from '../../redux/slices/layout';
import { imageEditorActions } from '../../redux/slices/imageEditor';

import styles from './DetailPage.module.scss';

interface Props {
  isLoading: boolean;
  aside: ReactNode;
  toolbar?: ReactNode;
  content: ReactNode;
}

const DetailPage = ({ isLoading, aside, toolbar, content }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (containerRef.current) {
      const width = Math.floor((containerRef.current.clientHeight - 100) / PAGE_SIDES_RATIO);
      dispatch(layoutActions.setPageWidth(width));
      dispatch(imageEditorActions.setImageWidth(width));
    }
  }, [dispatch]);

  return (
    <section className={styles.root}>
      {isLoading && <Spinner />}
      <aside className={styles.aside}>{aside}</aside>
      <div className={styles.workZone}>
        {toolbar && <Toolbar>{toolbar}</Toolbar>}
        <div className={styles.container} ref={containerRef}>
          {content}
        </div>
      </div>
    </section>
  );
};

export default DetailPage;
