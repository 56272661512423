import { useEffect, useState } from 'react';
import GridPage from '../../gridPage/GridPage';
import PetitionsReportFilters from './PetitionsReportFilters';
import PetitionsReportGrid from './PetitionsReportGrid';
import PetitionsReportActions from './PetitionsReportActions';
import { IStatOptions } from '../../../../redux/api/statistic';
import { useAppSelector } from '../../../../redux/hooks';
import { reportSelectors } from '../../../../redux/slices/reports';

const PetitionsReport = () => {
  const startDate = useAppSelector(reportSelectors.selectPetitionsReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectPetitionsReportEndDate);

  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  useEffect(() => {
    setFetchOptions(prev => ({ ...prev, dateFromTs: startDate, dateToTs: endDate }));
  }, [startDate, endDate]);

  return (
    <GridPage
      title="Статистика по ходатайствам"
      className="pt-2 pb-7"
      panel={<PetitionsReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={
        <PetitionsReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />
      }
      actions={<PetitionsReportActions fetchOptions={fetchOptions} />}
    />
  );
};

export default PetitionsReport;
