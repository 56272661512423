import { ReactElement, cloneElement, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { TabProps } from './Tab';

import styles from './Tabs.module.scss';

interface Props<T> {
  value: T;
  onChange: (value: T) => void;
  children: ReadonlyArray<ReactElement<TabProps<T>>>;
  className?: string;
}

const Tabs = <T,>({ children, className, value: valueProp, onChange }: Props<T>) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const boundChildren = useMemo(
    () =>
      (children ?? []).map((tab, index) =>
        cloneElement(tab, {
          key: tab.key ?? index,
          active: tab.props.value === value,
          onClick: () => {
            tab.props.onClick?.(tab.props.value);
            setValue(tab.props.value);
          },
        }),
      ),
    [children, value, setValue],
  );

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return <div className={classNames(styles.root, className)}>{boundChildren}</div>;
};

export default Tabs;
