import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import classNames from 'classnames';

import Card from './Card';
import useScrollLock from '../../hooks/useScrollLock';
import Button from './Button';
import { CloseIcon } from './icons/Icons';

import styles from './Modal.module.scss';

interface Props {
  children: ReactNode;
  onClose: () => void;
  className?: string;
  options?: {
    light?: boolean;
  };
}

const Modal = ({ children, onClose, className, options }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    return () => setVisible(false);
  }, []);

  useScrollLock();

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return createPortal(
    <FocusLock
      className={classNames(styles.root, className, visible && styles.visible)}
      as="section"
    >
      <div className={styles.overlay} onClick={onClose} />
      <Card className={classNames(styles.content, options?.light && styles.light)}>
        <Button
          className={styles.closeBtn}
          viewType="round"
          onClick={onClose}
          variant={options?.light ? 'plain' : undefined}
        >
          <CloseIcon />
        </Button>

        {children}
      </Card>
    </FocusLock>,
    document.getElementById('modal-root')!,
  );
};

export default Modal;
