import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Buttons from '../../../components/ui/Buttons';
import Checkbox from '../../../components/ui/Checkbox';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import { toast } from 'react-toastify';
import { TId } from '../../../types/TId';
import {
  printersApi,
  useCreatePrinterMutation,
  useEditPrinterMutation,
  useGetPrinterByIdQuery,
} from '../../../redux/api/printers';
import Spinner from '../../../components/ui/Spinner';
import { IPrinter } from '../../../types/IPrinter';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  id?: TId;
  onClose?: () => void;
}

const PrinterModal = ({ id, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<IPrinter>({
    id: '',
    name: '',
    host: '',
    path: 'ipp',
    active: true,
    status: '',
  });

  const { data, isFetching } = useGetPrinterByIdQuery(id!, { skip: !id });

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        host: data.host,
        path: data.path ?? '',
        active: !!data.active,
        status: data.status,
      });
    }
  }, [data]);

  const [createPrinter, { isLoading: creatingPrinter, data: createResult }] =
    useCreatePrinterMutation();

  const [editPrinter, { isLoading: editingPrinter, data: editResult }] = useEditPrinterMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.name.trim().length < 2) {
      toast.error('Некорректное название!');
      isValid = false;
    }
    if (formData.host.trim().length < 2) {
      toast.error('Некорректный адрес!');
      isValid = false;
    }
    return isValid;
  }, [formData.host, formData.name]);

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        createPrinter({
          name: formData.name,
          host: formData.host,
          path: formData.path ?? '',
          active: formData.active,
        });
      } else {
        return;
      }
    },
    [createPrinter, formData, validateForm],
  );

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        editPrinter(formData);
      } else {
        return;
      }
    },
    [editPrinter, formData, validateForm],
  );

  useEffect(() => {
    if (createResult || editResult) {
      onClose?.();
      dispatch(
        printersApi.endpoints.getPrintersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createResult, dispatch, editResult, onClose]);

  return (
    <div>
      {(isFetching || creatingPrinter || editingPrinter) && <Spinner />}
      <ModalHeading className="mb-6">
        {id ? 'Редактирование данных принтера' : 'Создание нового принтера'}
      </ModalHeading>
      <form onSubmit={id ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="name"
          name="name"
          label="Наименование"
          className="mb-2"
          value={formData.name}
          onChange={value => formChangeHandler('name', value)}
        />
        <TextInput
          type="text"
          id="host"
          name="host"
          label="Адрес"
          className="mb-2"
          value={formData.host}
          onChange={value => formChangeHandler('host', value)}
        />
        <TextInput
          type="text"
          id="path"
          name="path"
          label="Путь IPP"
          className="mb-4"
          value={formData.path}
          onChange={value => formChangeHandler('path', value)}
        />
        <Checkbox
          label="Активность"
          checked={formData.active}
          onChange={state => setFormData(prev => ({ ...prev, active: state }))}
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {id ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default PrinterModal;
