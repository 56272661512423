import { Dispatch, SetStateAction, useCallback } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { useGetDivisionsListQuery } from '../../../../redux/api/divisions';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import Select from '../../../../components/ui/Select';
import { IDivision } from '../../../../types/IDivision';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { reportActions, reportSelectors } from '../../../../redux/slices/reports';
import { DateTime } from 'luxon';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const PrintingReportFilters = ({ fetchOptionsSetter }: Props) => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(reportSelectors.selectPrintingReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectPrintingReportEndDate);

  const { data: divisions, isLoading: divisionsLoading } = useGetDivisionsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      dispatch(
        reportActions.setRange({
          report: 'printing',
          range: { start: dates.start?.toString(), end: dates.end?.toString() },
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <DateRangeInput
        wrapped
        className="mr-4"
        onChange={dateChangeHandler}
        value={{
          start: startDate ? DateTime.fromISO(startDate) : undefined,
          end: endDate ? DateTime.fromISO(endDate) : undefined,
        }}
      />
      <Select
        isSearchable
        className="mr-4 w-60"
        placeholder="Управление"
        options={divisions}
        getOptionLabel={opt => (opt as IDivision).name}
        getOptionValue={opt => (opt as IDivision).id}
        isClearable
        onChange={division => {
          fetchOptionsSetter(prev => ({
            ...prev,
            divisionId: division ? (division as IDivision).id : undefined,
          }));
        }}
        isLoading={divisionsLoading}
      />
    </>
  );
};
export default PrintingReportFilters;
