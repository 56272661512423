import { useNavigate } from 'react-router';
import Button from '../components/ui/Button';

interface Props {
  text?: string;
  buttonText?: string;
  isBack?: boolean;
}

const ErrorPage = ({ text = 'Страница не найдена!', isBack, buttonText = 'На главную' }: Props) => {
  const navigate = useNavigate();

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <div className="p-4">
      <p>{text}</p>
      <Button link={isBack ? undefined : '/'} onClick={isBack ? backHandler : undefined}>
        {buttonText}
      </Button>
    </div>
  );
};

export default ErrorPage;
