import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import logoSrc from '../../images/logo.png';
import Container from '../../components/ui/Container';
import Card from '../../components/ui/Card';
import ModalHeading from '../../components/ui/ModalHeading';
import TextInput from '../../components/ui/TextInput';
import Buttons from '../../components/ui/Buttons';
import Button from '../../components/ui/Button';
import { Routes } from '../../Router';
import { IAuthData } from '../../types/IAuthData';
import Checkbox from '../../components/ui/Checkbox';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLoginMutation } from '../../redux/api/auth';
import Spinner from '../../components/ui/Spinner';
import { userActions, userSelectors } from '../../redux/slices/user';
import Heading from '../../components/ui/Heading';

import styles from './LoginPage.module.scss';
import PasswordInput from '../../components/ui/PasswordInput';
import { QueryKeys } from '../../data/QueryKeys';

const emptyAuth: IAuthData = {
  login: '',
  password: '',
  rememberMe: false,
};

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelectors.selectUserData);
  const [authData, setAuthData] = useState<IAuthData>(emptyAuth);

  const [signInUser, { isLoading, data }] = useLoginMutation();

  useEffect(() => {
    if (user) {
      navigate(generatePath(Routes.Grid, { section: QueryKeys.Running }));
    }
  }, [navigate, user]);

  const loginChangeHandler = (login: string) => setAuthData(prev => ({ ...prev, login }));
  const passwordChangeHandler = (password: string) => setAuthData(prev => ({ ...prev, password }));
  const rememberChangeHandler = (state: boolean) =>
    setAuthData(prev => ({ ...prev, rememberMe: state }));

  const loginSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    let hasError = false;

    if (!authData.login.trim().length) {
      toast.error('Введите логин!');
      hasError = true;
    }

    if (!authData.password.trim().length) {
      toast.error('Введите пароль!');
      hasError = true;
    }

    if (hasError) return;

    signInUser(authData);
  };

  useEffect(() => {
    if (data) {
      dispatch(userActions.setUser(data));
      navigate(generatePath(Routes.Grid, { section: QueryKeys.Running }));
    }
  }, [authData.login, data, dispatch, navigate]);

  return (
    <Container htmlTag="section" className={styles.root}>
      {isLoading && <Spinner />}
      <div className={styles.title}>
        <img src={logoSrc} className={styles.logo} alt="Лого" />
        <Heading level={1}>СПО Тираж</Heading>
      </div>
      <div className={styles.wrapper}>
        <Card className={styles.loginCard}>
          <form onSubmit={loginSubmitHandler}>
            <ModalHeading className="mb-10">Авторизация</ModalHeading>
            <TextInput
              type="text"
              label="Логин"
              className="mb-4"
              value={authData.login}
              onChange={loginChangeHandler}
            />
            <PasswordInput
              label="Пароль"
              className="mb-4"
              value={authData.password}
              onChange={passwordChangeHandler}
            />
            <Checkbox
              label="Запомнить"
              content="mb-4"
              className="ml-5"
              checked={authData.rememberMe}
              onChange={rememberChangeHandler}
            />
            <Buttons className="mt-4">
              <Button type="submit">Войти</Button>
            </Buttons>
          </form>
        </Card>
      </div>
    </Container>
  );
};

export default LoginPage;
