import { IProcess } from '../../types/IProcess';
import { TId } from '../../types/TId';
import { api } from '../api';

export const processApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getProcessList: builder.query<IProcess[], void>({
      query: () => 'api/processes',
    }),
    getProcessById: builder.query<IProcess, TId>({
      query: id => `api/processes/${id}`,
    }),
    startProcess: builder.mutation<string, TId>({
      query: id => ({
        url: `api/processes/${id}/start`,
        method: 'POST',
      }),
    }),
    stopProcess: builder.mutation<string, TId>({
      query: id => ({
        url: `api/processes/${id}/stop`,
        method: 'POST',
      }),
    }),
    restartProcess: builder.mutation<string, TId>({
      query: id => ({
        url: `api/processes/${id}/restart`,
        method: 'POST',
      }),
    }),
    restartAll: builder.mutation<string, void>({
      query: () => ({
        url: 'api/processes/restart-all',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetProcessListQuery,
  useGetProcessByIdQuery,
  useStartProcessMutation,
  useStopProcessMutation,
  useRestartProcessMutation,
  useRestartAllMutation,
} = processApi;
