import styles from './Menu.module.scss';
import { BurgerIcon, CloseIcon } from '../ui/icons/Icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../ui/Button';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FaUsersCog, FaHistory, FaTasks, FaNetworkWired } from 'react-icons/fa';
import { FaBuildingUser } from 'react-icons/fa6';
import { BsFillPrinterFill } from 'react-icons/bs';
import { PiPlayPauseFill, PiBookBookmark } from 'react-icons/pi';
import { MdOutlineDisplaySettings, MdOutlineVideoSettings, MdSettings } from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
import { RiCharacterRecognitionLine } from 'react-icons/ri';
import { Routes } from '../../Router';
import { useAppSelector } from '../../redux/hooks';
import { userSelectors } from '../../redux/slices/user';
import { Role } from '../../types/IUserInfo';
import { QueryKeys } from '../../data/QueryKeys';
import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';

const Menu = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const menuRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const { data } = useAppSelector(userSelectors.selectUserState);
  const scrollContainerRef = usePerfectScrollbar<HTMLDivElement>();

  const items = [
    {
      icon: <FaTasks />,
      title: 'Задания',
      path: `/${QueryKeys.Operator}`,
      show: data?.role === Role.Tech,
    },
    {
      icon: <FaUsersCog />,
      title: 'Пользователи',
      path: Routes.Users,
      show: data?.role === Role.Tech || data?.role === Role.Admin,
    },
    {
      icon: <FaBuildingUser />,
      title: 'Сотрудники управлений',
      path: Routes.PrintJobAuthors,
      show: data?.role === Role.Tech,
    },
    {
      icon: <BsFillPrinterFill />,
      title: 'Принтеры',
      path: Routes.Printers,
      show: data?.role === Role.Tech || data?.role === Role.Admin,
    },
    {
      icon: <FaHistory />,
      title: 'История обработки',
      path: Routes.History,
      show: data?.role === Role.Tech,
    },
    {
      icon: <MdOutlineDisplaySettings />,
      title: 'Процесс обработки',
      path: Routes.Processing,
      show: data?.role === Role.Tech,
    },
    {
      icon: <FaNetworkWired />,
      title: 'Сетевые папки',
      path: Routes.Folders,
      show: data?.role === Role.Tech,
    },
    {
      icon: <TbReport />,
      title: 'Отчеты',
      path: Routes.Reports,
      show: data?.role === Role.Tech,
    },
    {
      icon: <MdSettings />,
      title: 'Настройки',
      path: Routes.Settings,
      show: data?.role === Role.Tech,
    },
    {
      icon: <RiCharacterRecognitionLine />,
      title: 'Content Reader',
      path: Routes.CRInstances,
      show: data?.role === Role.Tech || data?.role === Role.Admin,
    },
    {
      icon: <PiBookBookmark />,
      title: 'Журнал событий',
      path: Routes.Events,
      show: data?.role === Role.Tech,
    },
    {
      icon: <MdOutlineVideoSettings />,
      title: 'Системный журнал',
      path: Routes.SystemEvents,
      show: data?.role === Role.Tech,
    },
    {
      icon: <PiPlayPauseFill />,
      title: 'Управление процессами',
      path: Routes.Processes,
      show: data?.role === Role.Tech || data?.role === Role.Admin,
    },
  ];

  const menuOpenHandler = useCallback(() => setMenuOpened(true), []);
  const menuCloseHandler = useCallback(() => setMenuOpened(false), []);

  const clickAwayListener = useCallback((event: MouseEvent) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setMenuOpened(false);
    }
  }, []);

  useEffect(() => {
    if (menuOpened) {
      setTimeout(() => {
        document.addEventListener('click', clickAwayListener);
      }, 10);
    } else document.removeEventListener('click', clickAwayListener);
  }, [clickAwayListener, menuOpened]);

  return (
    <>
      <Button viewType="round" onClick={menuOpenHandler}>
        <BurgerIcon />
      </Button>
      <nav className={classNames(styles.menu, menuOpened && styles.menuOpened)} ref={menuRef}>
        <Button className={styles.closeBtn} viewType="round" onClick={menuCloseHandler}>
          <CloseIcon />
        </Button>
        <div className={styles.links} ref={scrollContainerRef}>
          {items.map(
            item =>
              (data?.role === Role.SuperAdmin || item.show) && (
                <Link
                  to={item.path}
                  key={item.path}
                  className={classNames(
                    styles.menuLink,
                    'mb-2',
                    pathname.includes(item.path) && styles.activeLink,
                  )}
                  onClick={menuCloseHandler}
                >
                  <span className={styles.icon}>{item.icon}</span>
                  {item.title}
                </Link>
              ),
          )}
        </div>
      </nav>
    </>
  );
};

export default Menu;
