import React from 'react';
import GridPage from '../gridPage/GridPage';
import Search from '../../../components/common/Search';
import SettingsGrid from './SettingsGrid';
import SettingsActions from './SettingsActions';

const SettingsPage = () => {
  return (
    <GridPage
      title="Настройки"
      panel={<Search placeholder="Введите назначение" />}
      grid={<SettingsGrid />}
      actions={<SettingsActions />}
    />
  );
};

export default SettingsPage;
