import { IDivision, IDivisionCreateInfo } from '../../types/IDivision';
import { api } from '../api';

export const divisionsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getDivisionsList: builder.query<IDivision[], void>({
      query: () => 'api/division',
    }),
    getDivision: builder.query<IDivision, string>({
      query: id => ({
        url: `api/division/${id}`,
      }),
    }),
    createDivision: builder.mutation<IDivision, IDivisionCreateInfo>({
      query: body => ({
        url: '/api/division',
        method: 'POST',
        body,
      }),
    }),
    editDivision: builder.mutation<IDivision, { id: string; data: IDivisionCreateInfo }>({
      query: ({ id, data }) => ({
        url: `/api/division/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteDivision: builder.mutation<void, string>({
      query: id => ({
        url: `api/division/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetDivisionsListQuery,
  useGetDivisionQuery,
  useCreateDivisionMutation,
  useEditDivisionMutation,
  useDeleteDivisionMutation,
} = divisionsApi;
