import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TextInput from '../ui/TextInput';
import { SearchIcon } from '../ui/icons/Icons';

import styles from './Search.module.scss';

interface Props {
  placeholder: string;
  className?: string;
}

const Search = ({ placeholder, className }: Props) => {
  const [term, setTerm] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const searchHandler = useCallback(
    (text: string) => {
      if (text.trim().length) {
        searchParams.set('search', text);
      } else {
        searchParams.delete('search');
      }
      setTerm(text);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const formSubmitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    event => {
      event.preventDefault();
      searchHandler(term);
    },
    [searchHandler, term],
  );

  return (
    <form className={styles.search} onSubmit={formSubmitHandler}>
      <TextInput
        className={className}
        type="search"
        placeholder={placeholder}
        buttonIcon={<SearchIcon />}
        // onButtonClick={() => formSubmitHandler()}
        value={term}
        onChange={searchHandler}
      />
    </form>
  );
};

export default Search;
