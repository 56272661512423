import { useCallback } from 'react';
import classNames from 'classnames';

import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';
import { TId } from '../../types/TId';
import Heading from '../../components/ui/Heading';
import Checkbox from '../../components/ui/Checkbox';
import Indicator from '../../components/ui/Indicator';

import styles from './DocumentList.module.scss';
import { nanoid } from '@reduxjs/toolkit';

interface Props<T extends { extraCount?: number; isDublicate?: boolean }> {
  title?: string;
  getItemTitle: (item: T) => string;
  getItemId: (item: T) => TId;
  items?: T[];
  activeId?: TId;
  multiple?: boolean;
  selection?: T[];
  className?: string;
  showCount?: boolean;
  onDocumentClick?: (doc: T) => void;
  onDocumentSelect?: (item: T) => void;
}

const DocumentList = <T extends { extraCount?: number; isDublicate?: boolean }>({
  title,
  items,
  activeId,
  multiple,
  showCount,
  className,
  onDocumentClick,
  selection,
  onDocumentSelect,
  getItemId,
  getItemTitle,
}: Props<T>) => {
  const scrollContainerRef = usePerfectScrollbar<HTMLUListElement>();

  const docClickHandler = useCallback(
    (doc: T) => {
      onDocumentClick?.(doc);
    },
    [onDocumentClick],
  );

  return (
    <div className={classNames(styles.root, className)}>
      {title && <Heading>{title}</Heading>}
      <ul className={styles.list} ref={scrollContainerRef}>
        {items?.map(item => {
          const currentId = getItemId(item);
          const inSelection = !!selection?.some(doc => getItemId(doc) === currentId);
          const viewed = 'viewed' in item ? !!item.viewed : true;
          return (
            <li
              key={currentId ?? nanoid()}
              className={classNames(
                styles.listButton,
                'mb-1',
                activeId === currentId && styles.listButtonActive,
                viewed && styles.viewed,
                item.isDublicate && styles.dublicate,
              )}
            >
              {showCount && !!item.extraCount && item.extraCount > 0 && (
                <span className={classNames(styles.extraCount, 'count')}>{item.extraCount}</span>
              )}
              {multiple && (
                <Checkbox
                  className="ml-3 mr-2"
                  checked={inSelection}
                  onChange={() => onDocumentSelect?.(item)}
                />
              )}
              <button onClick={docClickHandler.bind(this, item)}>{getItemTitle(item)}</button>
              {'touched' in item && (
                <Indicator
                  active={!!item.touched}
                  variant="success"
                  className={styles.touchIndicator}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DocumentList;
