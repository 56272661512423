import GridPage from '../gridPage/GridPage';
import Search from '../../../components/common/Search';
import ProcessesGrid from './ProcessesGrid';
import ProcessesActions from './ProcessesActions';

const ProcessesPage = () => {
  return (
    <GridPage
      title="Управление процессами"
      panel={<Search placeholder="Введите название" />}
      grid={<ProcessesGrid />}
      actions={<ProcessesActions />}
    />
  );
};

export default ProcessesPage;
