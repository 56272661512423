import { useParams } from 'react-router';
import GridActions from '../../../../components/common/GridActions';
import Button from '../../../../components/ui/Button';
import { BASE_URL } from '../../../../const';

const SinglePetitionActions = () => {
  const { complexId } = useParams();

  return (
    <GridActions className="mt-11">
      <Button link={`${BASE_URL}api/statistics/complexes/${complexId}/export`} download>
        Экспорт
      </Button>
    </GridActions>
  );
};

export default SinglePetitionActions;
