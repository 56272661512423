import {
  IInsertedImage,
  INormalizedComplexDocument,
  INormalizedImage,
} from '../redux/slices/operatorTask/slice';
import { IImage } from './IImage';
import { DocSection } from './DocSection';
import { TId } from './TId';

export interface IAction {
  id: string;
  type: EditorActionType;
  payload?: any;
}

export interface IStackAction extends IAction {
  payload: {
    images?: TId[];
    petitions?: TId[];
    awards?: TId[];
  };
}

export enum EditorActionType {
  Rotate = 'image/rotate',
  DeleteImage = 'image/delete',
  MakeImageRecognized = 'image/makeRecognized',
  ImportantImage = 'image/importance',
  InsertImage = 'image/insert',
  ReplaceImage = 'image/replace',
  MoveImage = 'image/move',
  SwitchImages = 'image/switch',
  DeleteAward = 'award/delete',
  SplitAward = 'award/split',
  SetBarcode = 'award/setBarcode',
  CreateAward = 'award/create',
  RecognizeSelected = 'image/recognize',
}

export interface IRotateAction extends IAction {
  type: EditorActionType.Rotate;
  payload: number;
}

export interface ISplitAwardAction extends IAction {
  type: EditorActionType.SplitAward;
  payload: TId[];
}

export interface ICreateAwardAction extends IAction {
  type: EditorActionType.CreateAward;
}

export interface IInsertImageAction extends IAction {
  type: EditorActionType.InsertImage;
  payload: {
    section: DocSection;
    data: INormalizedComplexDocument;
    insertedImages: IImage[];
  };
}

export interface IReplaceImageAction extends IAction {
  type: EditorActionType.ReplaceImage;
  payload: {
    image: INormalizedImage;
    index: number;
    insertedImages: IInsertedImage[];
  };
}

export interface IMoveImageAction extends IAction {
  type: EditorActionType.MoveImage;
  payload: string[];
}

export interface ISwitchImagesAction extends IAction {
  type: EditorActionType.SwitchImages;
  payload: string[];
}
