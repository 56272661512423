import { ISelectOption } from '../../../types/ISelectOption';
import Select from '../../../components/ui/Select';
import { useGetUsersListQuery } from '../../../redux/api/users';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { IEventsOptions } from '../../../redux/api/events';
import { IDateRange } from '../../../types/IDateRange';
import DateRangeInput from '../../../components/ui/DateRangeInput';
import { getSortedUsers } from '../../../utils/select';

interface Props {
  isSystem: boolean;
  fetchOptionsSetter: Dispatch<SetStateAction<IEventsOptions>>;
}

const EventsFilters = ({ isSystem, fetchOptionsSetter }: Props) => {
  const { data: users, isLoading: usersLoading } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        dateAddTsFrom: startDate ?? undefined,
        dateAddTsTo: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  return (
    <>
      {!isSystem && (
        <Select
          isSearchable
          className="mr-4 w-25"
          placeholder="Оператор"
          options={getSortedUsers(users, 'id')}
          isClearable
          onChange={user =>
            fetchOptionsSetter(prev => ({
              ...prev,
              userId: user ? (user as ISelectOption).value : undefined,
            }))
          }
          isLoading={usersLoading}
        />
      )}

      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
      <Select
        className="ml-4 w-15"
        placeholder="Ошибки"
        options={[
          { value: true, label: 'Да' },
          { value: false, label: 'Нет' },
        ]}
        isClearable
        onChange={value =>
          fetchOptionsSetter(prev => ({
            ...prev,
            error: value ? (value as { label: string; value: boolean }).value : undefined,
          }))
        }
      />
    </>
  );
};

export default EventsFilters;
