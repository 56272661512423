import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';
import { QueryKeys } from '../../../data/QueryKeys';
import useModal from '../../../hooks/useModal';
import PrintjobAuthorModal from './PrintjobAuthorModal';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import {
  printjobAuthorsApi,
  useDeletePrintjobAuthorMutation,
} from '../../../redux/api/printJobAuthors';
import Spinner from '../../../components/ui/Spinner';

const PrintjobAuthorsActions = () => {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();

  const selectedAuthor = useAppSelector(state =>
    selectionSelectors.selectSelectedRowIdByGridKey(state, QueryKeys.PrintjobAuthors),
  );

  const { modalOpenHandler, modalMarkup } = useModal(
    <PrintjobAuthorModal authorId={editing ? selectedAuthor : undefined} />,
  );

  const [deleteAuthor, { isLoading: deletingAuthor, isSuccess }] =
    useDeletePrintjobAuthorMutation();

  const {
    modalOpenHandler: confirmModalOpenHandler,
    modalMarkup: confirmModal,
    modalCloseHandler,
  } = useModal(
    <ConfirmationModal
      title="Подтверждение удаления"
      variant="danger"
      onConfirm={() => deleteAuthor(selectedAuthor)}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(selectionActions.removeSelection(QueryKeys.PrintjobAuthors));
      dispatch(
        printjobAuthorsApi.endpoints.getPrinjobAuthorList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      modalCloseHandler();
    }
  }, [dispatch, isSuccess, modalCloseHandler]);

  return (
    <GridActions>
      {deletingAuthor && <Spinner />}
      <Button
        onClick={() => {
          setEditing(false);
          modalOpenHandler();
        }}
      >
        Создать сотрудника
      </Button>
      <Button
        onClick={() => {
          setEditing(true);
          modalOpenHandler();
        }}
        disabled={!selectedAuthor}
      >
        Редактировать
      </Button>
      <Button
        className="mt-12"
        variant="danger"
        disabled={!selectedAuthor}
        onClick={confirmModalOpenHandler}
      >
        Удалить
      </Button>
      {modalMarkup}
      {confirmModal}
    </GridActions>
  );
};

export default PrintjobAuthorsActions;
