import { generatePath, Outlet, useLocation, useNavigate } from 'react-router';
import Container from '../../../components/ui/Container';
import classNames from 'classnames';

import styles from './ReportsPage.module.scss';
import Tabs from '../../../components/ui/Tabs';
import { QueryKeys } from '../../../data/QueryKeys';
import { Routes } from '../../../Router';
import Tab from '../../../components/ui/Tab';
import { useCallback, useEffect } from 'react';
import Card from '../../../components/ui/Card';

const DEFAULT_SECTION = QueryKeys.Department;

const sections = [
  {
    title: 'Статистика по участку',
    path: '/reports/' + QueryKeys.Department,
  },
  {
    title: 'Статистика по сотрудникам',
    path: '/reports/' + QueryKeys.Employee,
  },
  {
    title: 'Статистика по ходатайствам',
    path: '/reports/' + QueryKeys.Petitions,
  },
  {
    title: 'Статистика по обратной печати',
    path: '/reports/' + QueryKeys.Printing,
  },
];

const ReportsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === Routes.Reports) {
      navigate(generatePath(Routes.ReportsSection, { reportSection: DEFAULT_SECTION }));
    }
  }, [location.pathname, navigate]);

  const tabChangeHandler = useCallback(
    (tab: string) => {
      navigate(tab);
    },
    [navigate],
  );

  const as = sections.find(s => location.pathname.startsWith(s.path));

  return (
    <>
      <Container className="pt-4">
        <Card className={classNames(styles.card, 'mb-1')}>
          <Tabs value={as ? as.path : sections[0].path} onChange={tabChangeHandler}>
            {sections.map(section => (
              <Tab value={section.path} key={section.path}>
                {section.title}
              </Tab>
            ))}
          </Tabs>
        </Card>
      </Container>
      <Outlet />
    </>
  );
};
export default ReportsPage;
