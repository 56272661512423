import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

type StringDateRange = { start: string | undefined; end: string | undefined };

export interface IReportsState {
  departments: StringDateRange;
  employees: StringDateRange;
  petitions: StringDateRange;
  printing: StringDateRange;
}

const TODAY: StringDateRange = {
  start: DateTime.now().startOf('day').toString(),
  end: DateTime.now().endOf('day').toString(),
};

const initialState: IReportsState = {
  departments: TODAY,
  employees: TODAY,
  petitions: TODAY,
  printing: TODAY,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setRange: (
      state,
      { payload }: PayloadAction<{ report: keyof typeof initialState; range: StringDateRange }>,
    ) => {
      state[payload.report] = {
        start: payload.range.start,
        end: payload.range.end,
      };
    },
  },
});

export const { setRange } = reportsSlice.actions;

export default reportsSlice.reducer;
