import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Card from '../../components/ui/Card';
import Tabs from '../../components/ui/Tabs';
import Tab from '../../components/ui/Tab';
import { useAppSelector } from '../../redux/hooks';
import {
  // selectNewOperatorTaskCount,
  selectNewPrintTaskCount,
} from '../../redux/slices/notification/selectors';
// import { setOperatorTaskCount, setPrintTaskCount } from '../../redux/slices/notification/slice';

import styles from './SearchTabs.module.scss';
import Search from '../../components/common/Search';
import { QueryKeys } from '../../data/QueryKeys';

interface Props {
  className?: string;
}

const sections = [
  { title: 'Задания в обработке', path: `/${QueryKeys.Running}` },
  { title: 'Задания оператору', path: `/${QueryKeys.Operator}` },
  { title: 'Задания на печать', path: `/${QueryKeys.Printing}` },
  { title: 'Завершенные задания', path: `/${QueryKeys.Completed}` },
];

const SearchTabs = ({ className }: Props) => {
  const { pathname } = useLocation();
  const as = sections.find(s => s.path === pathname);
  const [activeSection, setActiveSection] = useState(as ?? sections[0]);
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const newPrintTaskCount = useAppSelector(selectNewPrintTaskCount);

  // const resetNewOperatorTasks = () => dispatch(setOperatorTaskCount(0));
  // const resetNewPrintTasks = () => dispatch(setPrintTaskCount(0));

  const tabChangeHandler = useCallback(
    (tab: (typeof sections)[0]) => {
      navigate(tab.path);
      setActiveSection(tab);
    },
    [navigate],
  );

  useEffect(() => {
    if (as) {
      setActiveSection(as);
    }
  }, [as]);

  const placeholder = useMemo(() => {
    if (pathname.indexOf(QueryKeys.Printing) >= 0) {
      return 'Введите фамилию оператора для поиска';
    } else {
      return 'Введите код или имя документа для поиска';
    }
  }, [pathname]);

  return (
    <Card className={classNames(styles.root, className)}>
      <Search placeholder={placeholder} className={styles.search} />

      <Tabs value={activeSection} onChange={tabChangeHandler}>
        {sections.map(section => (
          <Tab
            value={section}
            key={section.path}
            className={classNames(
              // section.title === sections[1].title && newOperatorTaskCount > 0 && styles.animated,
              section.title === sections[2].title && newPrintTaskCount > 0 && styles.animated,
            )}
            // onClick={value => {
            //   if (value.title === sections[1].title) resetNewOperatorTasks();
            //   else if (value.title === sections[2].title) resetNewPrintTasks();
            //   else return;
            // }}
          >
            {section.title}
          </Tab>
        ))}
      </Tabs>
    </Card>
  );
};

export default SearchTabs;
