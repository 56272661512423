import { useState } from 'react';
import TextInput, { TextInputProps } from './TextInput';
import { BiHide, BiShow } from 'react-icons/bi';

interface Props extends Omit<TextInputProps, 'type' | 'buttonIcon' | 'onButtonClick'> {}

const PasswordInput = (props: Props) => {
  const [readable, setReadable] = useState(false);
  return (
    <TextInput
      type={readable ? 'text' : 'password'}
      {...props}
      buttonIcon={readable ? <BiHide /> : <BiShow />}
      onButtonClick={() => setReadable(prev => !prev)}
    />
  );
};

export default PasswordInput;
