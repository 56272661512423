import { IAuthData } from '../../types/IAuthData';
import { IUser } from '../../types/IUser';
import { api } from '../api';

export const authApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    login: builder.mutation<IUser, IAuthData>({
      query: body => ({
        url: 'api/auth/login',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        url: 'api/auth/logout',
        method: 'POST',
      }),
    }),
    checkAuth: builder.query<IUser | null, void>({
      query: () => 'api/auth/session',
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useCheckAuthQuery } = authApi;
