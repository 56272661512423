import TextInput from '../../../../components/ui/TextInput';
import { SearchIcon } from '../../../../components/ui/icons/Icons';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { reportActions, reportSelectors } from '../../../../redux/slices/reports';
import { DateTime } from 'luxon';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const PetitionsReportFilters = ({ fetchOptionsSetter }: Props) => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(reportSelectors.selectPetitionsReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectPetitionsReportEndDate);

  const [searchTerm, setSearchTerm] = useState('');

  const updateFilter = useCallback(
    (value: string, filterId: string) => {
      fetchOptionsSetter(prev => ({ ...prev, [`${filterId}`]: value.length ? value : undefined }));
    },
    [fetchOptionsSetter],
  );

  useEffect(() => {
    const timeOut = setTimeout(() => updateFilter(searchTerm, 'search'), 300);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchTerm, updateFilter]);

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      dispatch(
        reportActions.setRange({
          report: 'petitions',
          range: { start: dates.start?.toString(), end: dates.end?.toString() },
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <TextInput
        type="search"
        className="mr-4 w-30"
        placeholder="Введите штрихкод"
        buttonIcon={<SearchIcon />}
        value={searchTerm}
        onChange={setSearchTerm}
      />
      <DateRangeInput
        wrapped
        className="mr-4"
        onChange={dateChangeHandler}
        value={{
          start: startDate ? DateTime.fromISO(startDate) : undefined,
          end: endDate ? DateTime.fromISO(endDate) : undefined,
        }}
      />
    </>
  );
};

export default PetitionsReportFilters;
