import { FC, PropsWithChildren } from 'react';
import Card from '../ui/Card';
import styles from './GridActions.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const GridActions: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <Card className={classNames(styles.actions, className)}>{children}</Card>;
};

export default GridActions;
