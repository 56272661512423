import GridActions from '../../../components/common/GridActions';
import Button from '../../../components/ui/Button';
import { BASE_URL } from '../../../const';
import { EVENTS_DATA_KEYS, IEventsOptions } from '../../../redux/api/events';
import { getSearchParams } from '../../../utils/searchParams';

interface Props {
  fetchOptions: IEventsOptions;
}

const EventsActions = ({ fetchOptions }: Props) => {
  const params = getSearchParams(fetchOptions, EVENTS_DATA_KEYS);

  return (
    <GridActions>
      <Button link={`${BASE_URL}api/events/export${params}`} download>
        Экспорт
      </Button>
    </GridActions>
  );
};

export default EventsActions;
