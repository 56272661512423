import { Enum } from '../data/enum';
import { TId } from './TId';

export interface IFolder {
  id: TId;
  name: string;
  type: FolderType;
  path: string;
  host: string | null;
  protocol: Protocol | null;
  active: boolean;
  status: string;
  workgroup: string;
  login: string;
  password: string;
  dateaddts: string;
  dateupdatets: string;
}

export enum FolderType {
  OperatorFolder = 'operatorfolder',
  PrintFolder = 'printfolder',
  CompleteFolder = 'completefolder',
  ReferenceFolder = 'referencefolder',
  CompletePrintFolder = 'completeprintfolder',
  CompleteInputScan = 'complex_input_scan',
  ComplexCopySed = 'complex_copy_sed',
}

export enum Protocol {
  SMB = 'smb',
  FTP = 'ftp',
}

export const FolderTypeEnum = new Enum<FolderType>([
  [FolderType.OperatorFolder, 'Входная папка'],
  [FolderType.PrintFolder, 'Входная папка Награды'],
  [FolderType.CompleteFolder, 'Выходная папка'],
  [FolderType.ReferenceFolder, 'Эталонная папка'],
  [FolderType.CompletePrintFolder, 'Выходная папка Награды'],
  [FolderType.CompleteInputScan, 'Сетевой скан'],
  [FolderType.ComplexCopySed, 'Папка АСЭД'],
]);

export const ProtocolEnum = new Enum<Protocol>([
  [Protocol.FTP, 'FTP'],
  [Protocol.SMB, 'SMB'],
]);
