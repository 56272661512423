import { FilterFn } from '@tanstack/react-table';
import { IDateRange } from '../types/IDateRange';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

export const makeDateRangeFilterFunction =
  <T>(): FilterFn<T> =>
  (row, columnId, filterValue: IDateRange) => {
    const value = row.getValue(columnId) as string;
    const date = DateTime.fromISO(value);
    let result = true;

    if (!date.isValid) {
      result = false;
      toast.warn('Невалидная дата');
    }

    if (filterValue.start && !filterValue.end) {
      result = date > filterValue.start.startOf('day');
    } else if (filterValue.end && !filterValue.start) {
      result = date < filterValue.end.endOf('day');
    } else if (filterValue.start && filterValue.end) {
      result = date > filterValue.start?.startOf('day') && date < filterValue.end?.endOf('day');
    }

    return result;
  };
