import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import Buttons from '../../../components/ui/Buttons';
import ModalHeading from '../../../components/ui/ModalHeading';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { complexesApi, useCreateComplexMutation } from '../../../redux/api/complexes';
import Spinner from '../../../components/ui/Spinner';
import { AWARD_REGEX, PARTS_MAX_COUNT } from '../../../const';
import { ComplexType } from '../../../types/IComplexInfo';
import useQrList from '../../../hooks/useQrList';
import { useAppDispatch } from '../../../redux/hooks';
import { RUNNING_COMPLEXES } from '../grids/filters';

interface Props {
  onClose?: () => void;
}

const PrintQrModal = ({ onClose }: Props) => {
  const [barcode, setBarcode] = useState('');
  const [partsTotal, setPartsTotal] = useState('');
  const dispatch = useAppDispatch();

  const [createComplex, { isLoading: creatingComplex, data: creatingData }] =
    useCreateComplexMutation();

  const { printHandler: printList, content } = useQrList(
    barcode,
    isNaN(+partsTotal) ? 0 : +partsTotal,
  );

  useEffect(() => {
    if (creatingData) {
      printList();
      onClose?.();
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: RUNNING_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    }
  }, [creatingData, dispatch, onClose, printList]);

  const printHandler = useCallback(() => {
    if (!AWARD_REGEX.test(barcode)) {
      return toast.error('Штрихкод ходатайства должен состоять из 13 цифр и начинаться на 21!');
    }

    if (isNaN(+partsTotal) || +partsTotal < 2 || +partsTotal > PARTS_MAX_COUNT) {
      return toast.error(`Введите корректное количество частей (от 2 до ${PARTS_MAX_COUNT})!`);
    }

    createComplex({
      barcode,
      type: ComplexType.AwardBig,
      partsTotal: +partsTotal,
    });
  }, [barcode, createComplex, partsTotal]);

  return (
    <>
      {creatingComplex && <Spinner />}
      <ModalHeading>Печать QR-кода</ModalHeading>
      <TextInput
        type="text"
        label="Штрихкод ходатайства"
        className="mb-4"
        autoFocus
        value={barcode}
        onChange={setBarcode}
        maxLength={13}
      />
      <TextInput
        type="number"
        label="Количество частей"
        className="mb-4"
        min={2}
        max={PARTS_MAX_COUNT}
        value={partsTotal}
        onChange={setPartsTotal}
      />
      {content}
      <Buttons>
        <Button variant="outlined" onClick={() => onClose?.()}>
          Отмена
        </Button>
        <Button onClick={printHandler}>Печать</Button>
      </Buttons>
    </>
  );
};

export default PrintQrModal;
