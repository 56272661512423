import { createColumnHelper } from '@tanstack/react-table';
import DataGrid from '../../../../components/grid/DataGrid';
import Tag from '../../../../components/ui/Tag';

import styles from '../ReportsPage.module.scss';
import classNames from 'classnames';
import {
  IPetitionStatItem,
  IStatOptions,
  useGetPetitionsStatQuery,
} from '../../../../redux/api/statistic';
import { Dispatch, SetStateAction } from 'react';
import { QueryKeys } from '../../../../data/QueryKeys';
import { ComplexProcessingEnum } from '../../../../types/IComplexInfo';
import { useServerSortingPagination } from '../../../../hooks/useServerSortingPagination';

interface Props {
  fetchOptions: IStatOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const columnHelper = createColumnHelper<IPetitionStatItem>();

const columns = [
  columnHelper.accessor('sameBarcodeCount', {
    header: '',
    cell: info => <span className={styles.error}>{info.getValue() > 1 ? '||' : ''}</span>,
    enableSorting: false,
    enableGlobalFilter: false,
    size: 20,
  }),
  columnHelper.accessor('barcode', {
    header: 'Штрихкод (QR)',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('awardsTotal', {
    header: 'Кол-во НЛ',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue() ?? '-'}</Tag>
    ),
  }),
  columnHelper.accessor('type', {
    header: 'Способ обр.',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>
        {ComplexProcessingEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Кол-во обр.',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue() ?? '-'}</Tag>
    ),
  }),
  columnHelper.accessor('user.showName', {
    header: 'ФИО',
    cell: info =>
      info.getValue() ? (
        <Tag style={{ minWidth: '200px', margin: '0 auto' }}>{info.getValue()}</Tag>
      ) : null,
  }),
  columnHelper.accessor('timeSpent', {
    header: 'Затраченное время',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue() ?? '-'}</Tag>
    ),
  }),
];

const PetitionsReportGrid = ({ fetchOptions, fetchOptionsSetter }: Props) => {
  const { data, isFetching } = useGetPetitionsStatQuery(fetchOptions, {
    refetchOnMountOrArgChange: true,
  });

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  return (
    <>
      {/* {statLoading && <Spinner />} */}
      <div className={classNames(styles.stats, 'mb-4')}>
        <span className="ml-10 mr-2">Всего ходатайств</span>
        <Tag className="mr-10">{data?.totals.petitionsTotal ?? '-'}</Tag>
        <span className="mr-2">Всего НЛ</span>
        <Tag className="mr-10">{data?.totals.awardsTotal ?? '-'}</Tag>
        <span className="mr-2">Всего образов</span>
        <Tag>{data?.totals.pagesTotal ?? '-'}</Tag>
      </div>

      <DataGrid
        gridKey={QueryKeys.PetitionsReport}
        rows={data?.results}
        totalCount={data?.pagination.totalCount}
        pagesCount={
          data ? Math.ceil(data.pagination.totalCount / data.pagination.perPage) : undefined
        }
        isLoading={isFetching}
        columns={columns}
        rowIdSelector={row => row.id}
        hideAllSettings
        serverControlled={true}
        onPaginationChange={paginationChangeHandler}
        onSortingChange={sortingChangeHandler}
        pageIndex={fetchOptions.page}
        perPage={fetchOptions.perPage}
      />
    </>
  );
};

export default PetitionsReportGrid;
