import GridPage from '../gridPage/GridPage';
import ProcessingGrid from './ProcessingGrid';
import ProcessingActions from './ProcessingActions';
import { useState } from 'react';
import ProcessingFilters from './ProcessingFilters';
import { IComplexInfo } from '../../../types/IComplexInfo';
import { IComplexesOptions } from '../../../redux/api/complexes';

const ProcessingPage = () => {
  const [selectedRow, setSelectedRow] = useState<IComplexInfo>();
  const [fetchOptions, setFetchOptions] = useState<IComplexesOptions>({
    page: 0,
    perPage: 20,
  });

  return (
    <GridPage
      title="Управление процессом обработки"
      panel={<ProcessingFilters fetchOptionsSetter={setFetchOptions} />}
      grid={
        <ProcessingGrid
          fetchOptions={fetchOptions}
          fetchOptionsSetter={setFetchOptions}
          onRowClick={setSelectedRow}
        />
      }
      actions={<ProcessingActions fetchOptions={fetchOptions} selectedRow={selectedRow} />}
    />
  );
};

export default ProcessingPage;
