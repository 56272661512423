import { IPaginatedResultWithTotals } from './../../types/IPaginatedResult';
import { IGridOptions } from '../../types/IGridOptions';
import { getSearchParams } from '../../utils/searchParams';
import { api } from '../api';
import { IPrintJobAuthor } from '../../types/IPrintJobAuthor';
import { ComplexType } from '../../types/IComplexInfo';

export interface IStatOptions extends IGridOptions {
  dateFromTs?: string;
  dateToTs?: string;
  divisionId?: string;
  search?: string;
}

export const STAT_DATA_KEYS = ['dateFromTs', 'dateToTs', 'divisionId', 'search', 'sort', 'order'];

export interface IUserStatItem {
  user: {
    id: number;
    showName: string;
  };
  petitionsTotal: number;
  awardsTotal: number;
  pagesTotal: number;
  validationErrorsTotal: number;
  processErrorsTotal: number;
}

export interface IPrintjobStatItem {
  author: IPrintJobAuthor;
  awardsTotal: number;
  dateExecuteTs: string;
  pagesTotal: number;
  user: {
    id: number;
    showName: string;
  };
}

export interface IPetitionStatItem {
  id: string;
  barcode: string;
  type: ComplexType;
  sameBarcodeCount: number;
  awardsTotal: number;
  pagesTotal: number;
  timeSpent: number;
  user: {
    id: number;
    showName: string;
  };
}

export interface ISinglePetitionStatItem {
  barcode: string;
  partNumber: number;
  dateProcessStartTs: string;
  dateProcessEndTs: string;
}

export interface IComplexesStat {
  awardsTotal: number;
  pagesTotal: number;
  petitionsTotal: number;
  sentErrorsTotal: number;
}

export const statisticApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getPrintjobsStat: builder.query<IPaginatedResultWithTotals<IPrintjobStatItem>, IStatOptions>({
      query: params => `/api/statistics/print-jobs${getSearchParams(params, STAT_DATA_KEYS)}`,
    }),
    getComplexesStat: builder.query<IComplexesStat, IStatOptions>({
      query: params => `/api/statistics/complexes/totals${getSearchParams(params, STAT_DATA_KEYS)}`,
    }),
    getPetitionsStat: builder.query<IPaginatedResultWithTotals<IPetitionStatItem>, IStatOptions>({
      query: params => `/api/statistics/complexes${getSearchParams(params, STAT_DATA_KEYS)}`,
    }),
    getSinglePetitionStat: builder.query<
      Omit<IPaginatedResultWithTotals<ISinglePetitionStatItem>, 'pagination'>,
      string
    >({
      query: complexId => `/api/statistics/complexes/${complexId}`,
    }),
    getUsersStat: builder.query<IPaginatedResultWithTotals<IUserStatItem>, IStatOptions>({
      query: params => `/api/statistics/complexes/users${getSearchParams(params)}`,
    }),
    getSingleUserStat: builder.query<IUserStatItem, { userId: string; params: IStatOptions }>({
      query: ({ userId, params }) =>
        `/api/statistics/complexes/users/${userId}${getSearchParams(params)}`,
    }),
  }),
});

export const {
  useGetPrintjobsStatQuery,
  useGetComplexesStatQuery,
  useGetPetitionsStatQuery,
  useGetSinglePetitionStatQuery,
  useGetUsersStatQuery,
  useGetSingleUserStatQuery,
} = statisticApi;
