import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { TickBoldIcon } from './icons/Icons';

import styles from './Checkbox.module.scss';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  label?: string;
  onChange?: (value: boolean) => void;
}

const Checkbox = ({ id, className, label, checked, onChange, ...rest }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.blur();
  }, [checked]);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onChange?.(target.checked);
    },
    [onChange],
  );

  const labelClickHandler = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return (
    <div
      className={classNames(
        styles.root,
        className,
        !label && styles.noLabel,
        checked && styles.checked,
      )}
    >
      <input
        type="checkbox"
        onChange={changeHandler}
        id={id}
        checked={checked}
        ref={inputRef}
        onClick={e => {
          e.stopPropagation();
        }}
        {...rest}
      />
      {checked && <TickBoldIcon className={styles.tick} />}
      {label && (
        <label htmlFor={id} className={styles.label} onClick={labelClickHandler}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
