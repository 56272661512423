import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { printJobActions, printJobSelectors } from '../../redux/slices/printJob';
import ErrorPage from '../ErrorPage';
import Slider from '../../components/common/Slider';
import { useCallback, useMemo } from 'react';
import { SheetFormat } from '../../types/SheetFormat';
import { ISlide } from '../../types/ISlide';
import DraggableView from '../../components/common/DraggableView';
import { SLIDE_PREVIEW_SIZE } from '../../const';
import { layoutSelectors } from '../../redux/slices/layout';

import styles from './PrintView.module.scss';

interface IPageSlide extends ISlide {
  pageId: string;
  format: SheetFormat;
  filename: string;
}

const PrintView = () => {
  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(printJobSelectors.selectPrintJobInfo);
  const docName = useAppSelector(printJobSelectors.selectActiveDocumentId);
  const pages = useAppSelector(printJobSelectors.selectDocumentPages);
  const selectedImage = useAppSelector(printJobSelectors.selectActiveImage);
  const selection = useAppSelector(printJobSelectors.selectSelectedPages);
  const pageWidth = useAppSelector(layoutSelectors.selectPageWidth);

  const images: IPageSlide[] = useMemo(() => {
    const imgs: IPageSlide[] = [];
    pages.forEach(page => {
      const image1: IPageSlide = {
        id: page.id + '-1',
        pageId: page.id,
        src: page.file1 + `/${SLIDE_PREVIEW_SIZE}/${SLIDE_PREVIEW_SIZE}`,
        format: page.format,
        filename: page.file1,
      };
      const image2: IPageSlide = {
        id: page.id + '-2',
        pageId: page.id,
        src: page.file2 ? page.file2 + `/${SLIDE_PREVIEW_SIZE}/${SLIDE_PREVIEW_SIZE}` : '',
        format: page.format,
        filename: page.file2 ?? '',
        hidden: !page.file2,
      };

      imgs.push(image1, image2);
    });
    return imgs;
  }, [pages]);

  const slideClickHandler = (image: IPageSlide) => {
    dispatch(printJobActions.setImage({ ...image, src: image.filename }));
  };

  const slideSelectHandler = (image: IPageSlide) => {
    dispatch(printJobActions.changeSelectedPages(image.pageId));
  };

  const selectionResetHandler = useCallback(() => {
    dispatch(printJobActions.resetSelection());
  }, [dispatch]);

  if (!jobInfo) return <ErrorPage text="Запись не найдена!" />;

  return (
    <div className={styles.root}>
      <Slider
        headerText={`Всего: ${images.filter(i => i.filename.length > 0).length}`}
        docId={docName}
        items={images}
        selection={selection}
        activeId={selectedImage?.id}
        onSlideClick={slideClickHandler}
        onSlideSelect={slideSelectHandler}
        pairedSelection
        selectionChecker={item => selection.includes(item.pageId)}
        className={styles.slider}
        keyboard={true}
        onSelectionReset={selectionResetHandler}
      />
      <DraggableView
        src={selectedImage?.src ? `${selectedImage.src}.png` : undefined}
        imageWidth={pageWidth}
        imageFormat={selectedImage?.format}
        className={styles.view}
        scrollBy={{ left: -120 }}
        isVisible={true}
      />
    </div>
  );
};

export default PrintView;
