import {
  ISinglePetitionStatItem,
  useGetSinglePetitionStatQuery,
} from '../../../../redux/api/statistic';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import Tag from '../../../../components/ui/Tag';
import DataGrid from '../../../../components/grid/DataGrid';
import { QueryKeys } from '../../../../data/QueryKeys';

import styles from '../ReportsPage.module.scss';
import { getReadableDateTime } from '../../../../utils/date';

interface Props {
  complexId?: string;
}

const columnHelper = createColumnHelper<ISinglePetitionStatItem>();

const columns = [
  columnHelper.accessor('barcode', {
    header: 'Штрихкод (QR)',
    cell: info => info.renderValue(),
    enableSorting: false,
  }),
  columnHelper.accessor('partNumber', {
    header: 'Номер части',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue() ?? '-'}</Tag>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('dateProcessStartTs', {
    header: 'Дата/время начала',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>
        {getReadableDateTime(info.getValue())}
      </Tag>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('dateProcessEndTs', {
    header: 'Дата/время завершения',
    cell: info => (
      <Tag style={{ minWidth: '100px', margin: '0 auto' }}>
        {getReadableDateTime(info.getValue())}
      </Tag>
    ),
    enableSorting: false,
  }),
];

const SinglePetitionGrid = ({ complexId }: Props) => {
  const { data, isFetching } = useGetSinglePetitionStatQuery(complexId!, {
    skip: !complexId,
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <div className={classNames(styles.stats, 'mb-4')}>
        <span className="ml-10 mr-2">Всего частей</span>
        <Tag className="mr-10">{data?.totals.partsTotal ?? '-'}</Tag>
        <span className="mr-2">Всего НЛ</span>
        <Tag className="mr-10">{data?.totals.awardsTotal ?? '-'}</Tag>
        <span className="mr-2">Всего образов</span>
        <Tag>{data?.totals.pagesTotal ?? '-'}</Tag>
      </div>

      <DataGrid
        gridKey={QueryKeys.SinglePetitionReport}
        rows={data?.results}
        isLoading={isFetching}
        columns={columns}
        rowIdSelector={row => row.barcode}
        hideAllSettings
        hidePagination
      />
    </>
  );
};
export default SinglePetitionGrid;
