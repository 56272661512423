import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BiSelectMultiple } from 'react-icons/bi';

import Button from '../../components/ui/Button';
import { TickIcon } from '../../components/ui/icons/Icons';
import useModal from '../../hooks/useModal';
import PrintModal from './modals/PrintModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { printJobSelectors } from '../../redux/slices/printJob';
import { IPrinterTaskPages } from '../../types/IPrinterTask';

import styles from './PrintJobToolbar.module.scss';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { printjobsApi, useFinishPrintjobMutation } from '../../redux/api/printjobs';
import Spinner from '../../components/ui/Spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const PrintJobToolbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [task, setTask] = useState<IPrinterTaskPages>();
  const { modalMarkup, modalOpenHandler } = useModal(<PrintModal showCountSelector task={task!} />);

  const [finishPrintJob, { isLoading, isSuccess }] = useFinishPrintjobMutation();

  const selectedPages = useAppSelector(printJobSelectors.selectSelectedPages);
  const jobInfo = useAppSelector(printJobSelectors.selectPrintJobInfo);

  const completeConfirmHandler = useCallback(() => {
    if (jobInfo?.id) {
      finishPrintJob(jobInfo.id);
    } else {
      toast.error('Что-то пошло не так!');
    }
  }, [finishPrintJob, jobInfo?.id]);

  const { modalMarkup: confirmModal, modalOpenHandler: confirmOpenHandler } = useModal(
    <ConfirmationModal
      title="Завершение печати"
      text="Подтверждаете выполнение печати?"
      onConfirm={completeConfirmHandler}
    />,
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        printjobsApi.endpoints.getPrinjobList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      navigate(-1);
    }
  }, [dispatch, isSuccess, navigate]);

  return (
    <>
      {isLoading && <Spinner />}
      <div className={classNames(styles.startBtnGroup, 'mr-14')}>
        <Button
          icon={<BiSelectMultiple />}
          viewType="toolbarText"
          title="Печать выбранных листов"
          onClick={() => {
            setTask({
              printJobId: jobInfo!.id,
              selectedPages,
            });
            modalOpenHandler();
          }}
          disabled={selectedPages.length === 0}
        >
          Печать
        </Button>
      </div>
      <div></div>
      <div className={styles.lastBtnGroup}>
        <Button
          icon={<TickIcon />}
          viewType="toolbarText"
          variant="danger"
          className={styles.approveBtn}
          onClick={confirmOpenHandler}
        >
          Подтвердить выполнение печати
        </Button>
      </div>
      {modalMarkup}
      {confirmModal}
    </>
  );
};

export default PrintJobToolbar;
