export const getSearchParams = (params: Record<string, any>, keysFilter?: string[]) => {
  const searchParams = new URLSearchParams();

  Object.entries(params)
    .filter(([key, value]) =>
      keysFilter
        ? (value || value === 0 || value === false) && keysFilter.includes(key)
        : value || value === 0 || value === false,
    )
    .forEach(([key, value]) => searchParams.set(key, value));

  const paramsString = searchParams.toString();

  return paramsString.length ? `?${paramsString}` : '';
};
