import { createElement, forwardRef } from 'react';
import classNames from 'classnames';

import styles from './Container.module.scss';

interface Props {
  children: React.ReactNode;
  htmlTag?: 'div' | 'section' | 'article';
  className?: string;
}

const Container = forwardRef(
  ({ className, htmlTag = 'div', children }: Props, ref: React.ForwardedRef<HTMLElement>) => {
    return createElement(
      htmlTag ?? 'div',
      {
        className: classNames(styles.container, className),
        ref,
      },
      children,
    );
  },
);

export default Container;
