import { generatePath, useNavigate } from 'react-router';

import ModalHeading from '../../../components/ui/ModalHeading';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import { TId } from '../../../types/TId';
import { Routes } from '../../../Router';
import { useAppDispatch } from '../../../redux/hooks';
import { useCallback } from 'react';
import { complexesApi } from '../../../redux/api/complexes';
import { QueryKeys } from '../../../data/QueryKeys';
import { OPERATOR_COMPLEXES } from '../grids/filters';

interface Props {
  onClose?: () => void;
  taskId: TId;
}

const OtherOperatorModal = ({ onClose, taskId }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const confirmHandler = useCallback(() => {
    const path = generatePath(Routes.Details, {
      section: QueryKeys.Operator,
      taskId,
    });
    navigate(path);
    dispatch(
      complexesApi.endpoints.getComplexesList.initiate(
        { states: OPERATOR_COMPLEXES },
        {
          forceRefetch: true,
          subscribe: false,
        },
      ),
    );
  }, [dispatch, navigate, taskId]);

  return (
    <div>
      <ModalHeading>Задание другого оператора</ModalHeading>
      <p className="p-5">Данное задание было назначено другому оператору. Хотите обработать его?</p>
      <Buttons>
        <Button type="button" variant="outlined" onClick={() => onClose?.()}>
          Отмена
        </Button>
        <Button onClick={confirmHandler}>Обработать</Button>
      </Buttons>
    </div>
  );
};

export default OtherOperatorModal;
