import classNames from 'classnames';

import { useAppSelector } from '../../redux/hooks';
import ErrorPage from '../ErrorPage';
import EditZone from './EditZone';
import InsertZone from './InsertZone';
import { operatorTaskSelectors } from '../../redux/slices/operatorTask';

import styles from './ItemView.module.scss';

interface Props {
  readOnly: boolean;
  className?: string;
}

const ItemView = ({ className, readOnly }: Props) => {
  const images = useAppSelector(operatorTaskSelectors.images.selectAll);

  if (!images.length) return <ErrorPage text="Что-то пошло не так!" isBack buttonText="К списку" />;

  return (
    <div className={classNames(styles.root, className)}>
      <EditZone readOnly={readOnly} />
      {!readOnly && <InsertZone />}
    </div>
  );
};

export default ItemView;
