import { Dispatch, SetStateAction } from 'react';
import {
  IPrintjobStatItem,
  IStatOptions,
  useGetPrintjobsStatQuery,
} from '../../../../redux/api/statistic';
// import Spinner from '../../../../components/ui/Spinner';
import { createColumnHelper } from '@tanstack/react-table';
import { getReadableDateTime } from '../../../../utils/date';
import classNames from 'classnames';

import styles from '../ReportsPage.module.scss';
import Tag from '../../../../components/ui/Tag';
import DataGrid from '../../../../components/grid/DataGrid';
import { QueryKeys } from '../../../../data/QueryKeys';
import { useServerSortingPagination } from '../../../../hooks/useServerSortingPagination';

interface Props {
  fetchOptions: IStatOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const columnHelper = createColumnHelper<IPrintjobStatItem>();

const columns = [
  columnHelper.accessor('author.showName', {
    header: 'ФИО приславшего док-т',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('awardsTotal', {
    header: 'Кол-во НЛ',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Кол-во обр.',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('user.showName', {
    header: 'ФИО исполнителя',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('dateExecuteTs', {
    header: 'Дата обработки',
    cell: info => getReadableDateTime(info.getValue()),
  }),
];

const PrintingReportGrid = ({ fetchOptions, fetchOptionsSetter }: Props) => {
  const { data, isFetching } = useGetPrintjobsStatQuery(fetchOptions, {
    refetchOnMountOrArgChange: true,
  });

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  return (
    <>
      <div className={classNames(styles.stats, 'mb-4')}>
        <span className="mr-2">Всего НЛ</span>
        <Tag className="mr-10">{data?.totals.awardsTotal ?? '-'}</Tag>
        <span className="mr-2">Всего образов</span>
        <Tag>{data?.totals.pagesTotal ?? '-'}</Tag>
      </div>
      <DataGrid
        gridKey={QueryKeys.PrintingReport}
        rows={data?.results}
        totalCount={data?.pagination.totalCount}
        pagesCount={
          data ? Math.ceil(data.pagination.totalCount / data.pagination.perPage) : undefined
        }
        isLoading={isFetching}
        columns={columns}
        hideAllSettings
        serverControlled={true}
        onPaginationChange={paginationChangeHandler}
        onSortingChange={sortingChangeHandler}
        pageIndex={fetchOptions.page}
        perPage={fetchOptions.perPage}
      />
    </>
  );
};

export default PrintingReportGrid;
