import { createColumnHelper } from '@tanstack/react-table';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import Tag from '../../../components/ui/Tag';
import DataGrid from '../../../components/grid/DataGrid';
import InfoButton from '../../../components/ui/InfoButton';
import { QueryKeys } from '../../../data/QueryKeys';
import ErrorCell from '../../../components/grid/ErrorCell';
import { getReadableDateTime } from '../../../utils/date';
import { useGetEventsQuery } from '../../../redux/api/events';
import { IEvent, ObjectActionEnum, ObjectTypeEnum } from '../../../types/IEvent';
import { Dispatch, SetStateAction } from 'react';
import { IStatOptions } from '../../../redux/api/statistic';
import { useServerSortingPagination } from '../../../hooks/useServerSortingPagination';

interface Props {
  isSystem: boolean;
  fetchOptions: IStatOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const columnHelper = createColumnHelper<IEvent>();

const userColumns = [
  columnHelper.accessor('objectType', {
    header: 'Тип',
    cell: info => (
      <Tag style={{ minWidth: '200px' }}>{ObjectTypeEnum.getDisplayName(info.getValue())}</Tag>
    ),
  }),
  columnHelper.accessor('objectName', {
    header: 'Объект',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('userShowName', {
    header: 'Пользователь',
    cell: info => <Tag style={{ minWidth: '200px' }}>{info.getValue()}</Tag>,
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<IEvent>(),
  }),
  columnHelper.accessor('action', {
    header: 'Действие',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', width: '250px' }}>
        {ObjectActionEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('error', {
    header: 'Ошибки',
    cell: info => <ErrorCell isError={info.getValue()} />,
  }),
  columnHelper.accessor('message', {
    header: 'Описание',
    cell: info =>
      info.getValue() ? (
        <InfoButton text={info.getValue() as string} style={{ verticalAlign: 'middle' }} />
      ) : null,
    enableSorting: false,
  }),
];

const systemColumns = [
  columnHelper.accessor('objectType', {
    header: 'Тип',
    cell: info => (
      <Tag style={{ minWidth: '200px' }}>{ObjectTypeEnum.getDisplayName(info.getValue())}</Tag>
    ),
  }),
  columnHelper.accessor('objectName', {
    header: 'Объект',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<IEvent>(),
  }),
  columnHelper.accessor('action', {
    header: 'Действие',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', width: '100%' }}>
        {ObjectActionEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('error', {
    header: 'Ошибки',
    cell: info => <ErrorCell isError={info.getValue()} />,
  }),
  columnHelper.accessor('message', {
    header: 'Описание',
    cell: info =>
      info.getValue() ? (
        <InfoButton text={info.getValue() as string} style={{ verticalAlign: 'middle' }} />
      ) : null,
    enableSorting: false,
  }),
];

const EventsGrid = ({ isSystem, fetchOptions, fetchOptionsSetter }: Props) => {
  const { data, isLoading } = useGetEventsQuery(fetchOptions, {
    refetchOnMountOrArgChange: true,
  });

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  return (
    <DataGrid
      gridKey={QueryKeys.Events}
      rows={data?.results}
      totalCount={data?.pagination?.totalCount}
      pagesCount={
        data ? Math.ceil(data.pagination?.totalCount / data.pagination?.perPage) : undefined
      }
      isLoading={isLoading}
      columns={isSystem ? systemColumns : userColumns}
      rowIdSelector={row => row.id}
      hideAllSettings
      serverControlled={true}
      onPaginationChange={paginationChangeHandler}
      onSortingChange={sortingChangeHandler}
    />
  );
};

export default EventsGrid;
