import { ReactElement, useCallback, useRef } from 'react';
import classNames from 'classnames';

import Button from './Button';

import styles from './TextInput.module.scss';

export interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange' | 'size'> {
  label?: string;
  type: 'email' | 'password' | 'search' | 'tel' | 'text' | 'number';
  withErrorSpace?: boolean;
  error?: boolean;
  errorMessge?: string;
  onChange?: (value: string) => void;
  buttonIcon?: ReactElement;
  onButtonClick?: (value: string) => void;
  variant?: 'vertical';
  size?: 'small' | 'medium';
}

const TextInput = ({
  label,
  type,
  onChange,
  withErrorSpace,
  error,
  errorMessge,
  className,
  buttonIcon,
  onButtonClick,
  variant,
  size,
  ...rest
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onChange?.(target.value);
    },
    [onChange],
  );

  const buttonClickHandler = useCallback(() => {
    onButtonClick?.(inputRef.current?.value ?? '');
  }, [onButtonClick]);

  return (
    <div
      className={classNames(
        styles.root,
        className,
        withErrorSpace && styles.withErrorSpace,
        error && styles.error,
        buttonIcon && styles.withButton,
        variant && styles[variant],
        size && styles[size],
      )}
    >
      {label && (
        <label htmlFor={rest.id} className={styles.label}>
          {label}
        </label>
      )}
      <input
        className={styles.input}
        type={type}
        ref={inputRef}
        {...rest}
        onChange={changeHandler}
      />
      {buttonIcon && (
        <Button
          type="button"
          onClick={buttonClickHandler}
          variant="primary"
          viewType="round"
          className={styles.button}
        >
          {buttonIcon}
        </Button>
      )}
      {error && withErrorSpace && errorMessge && (
        <span className={styles.errorMessage}>{errorMessge}</span>
      )}
    </div>
  );
};

export default TextInput;
