import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTagVariants } from '../../../components/ui/Tag';

export interface ITag {
  text: string;
  variant?: TTagVariants;
  button?: boolean;
  modal?: boolean;
}

export interface IHeaderInfoState {
  content: (string | ITag | null)[];
}

const initialState: IHeaderInfoState = {
  content: [],
};

const headerInfoSlice = createSlice({
  name: 'headerInfo',
  initialState,
  reducers: {
    setHeaderInfoContent: (state, action: PayloadAction<(string | ITag | null)[]>) => {
      state.content = action.payload;
    },
  },
});

export const { setHeaderInfoContent } = headerInfoSlice.actions;

export default headerInfoSlice.reducer;
