import { createBrowserRouter } from 'react-router-dom';
import MainPage from './pages/main/MainPage';
import ErrorPage from './pages/ErrorPage';
import CompletedGrid from './pages/main/grids/CompletedGrid';
import OperatorGrid from './pages/main/grids/OperatorGrid';
import LoginPage from './pages/login/LoginPage';
import Layout from './components/layout/Layout';
import RunningGrid from './pages/main/grids/RunningGrid';
import ProtectedRoute from './components/layout/ProtectedRoute';
import UsersPage from './pages/techPages/users/UsersPage';
import PrintersPage from './pages/techPages/printers/PrintersPage';
import HistoryPage from './pages/techPages/history/HistoryPage';
import ProcessingPage from './pages/techPages/processing/ProcessingPage';
import FoldersPage from './pages/techPages/folders/FoldersPage';
import SettingsPage from './pages/techPages/settings/SettingsPage';
import CRInstancesPage from './pages/techPages/CRInstances/CRInstancesPage';
import EventsPage from './pages/techPages/events/EventsPage';
import ProcessesPage from './pages/techPages/processes/ProcessesPage';
import PrintingGrid from './pages/main/grids/PrintingGrid';
import { Role } from './types/IUserInfo';
import PrintJob from './pages/printJob/PrintJob';
import OperatorTask from './pages/operatorTask/OperatorTask';
import { QueryKeys } from './data/QueryKeys';
import PageError from './components/layout/PageError';
import PrintjobAuthorsPage from './pages/techPages/printjobAuthors/PrintjobAuthorsPage';
import DivisionsPage from './pages/techPages/divisions/DivisionsPage';
import ReportsPage from './pages/techPages/reports/ReportsPage';
import DepartmentsReport from './pages/techPages/reports/departments/DepartmentsReport';
import UsersReport from './pages/techPages/reports/users/UsersReport';
import PrintingReport from './pages/techPages/reports/printing/PrintingReport';
import PetitionsReport from './pages/techPages/reports/petitions/PetitionsReport';
import SinglePetitionReport from './pages/techPages/reports/singlePetition/SinglePetitionReport';

export enum Routes {
  Main = '/',
  Grid = '/:section',
  Details = '/:section/:taskId',
  TaskHistory = '/:section/history/:taskId',
  Login = '/login',
  NotFound = '/404',
  Error = '/error',
  Users = '/users',
  Printers = '/printers',
  History = '/history',
  Processing = '/processing',
  Folders = '/folders',
  Reports = '/reports',
  ReportsSection = '/reports/:reportSection',
  Settings = '/settings',
  CRInstances = '/content-readers',
  Events = '/events',
  SystemEvents = '/system',
  Processes = '/processes',
  PrintJobAuthors = '/authors',
  Divisions = '/divisions',
  SinglePetitionReport = '/reports/petitions/:complexId',
}

//вместо createBrowserRouter используется createHashRouter для показа без сервера
const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <PageError />,
    children: [
      {
        path: Routes.Login,
        element: <LoginPage />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: Routes.Main,
            element: <MainPage />,
            children: [
              {
                path: QueryKeys.Running,
                element: <RunningGrid />,
              },
              {
                path: QueryKeys.Operator,
                element: <OperatorGrid />,
              },
              {
                path: QueryKeys.Printing,
                element: <PrintingGrid />,
              },
              {
                path: QueryKeys.Completed,
                element: <CompletedGrid />,
              },
            ],
          },
          {
            path: `/${QueryKeys.Operator}/:taskId`,
            element: <OperatorTask />,
          },
          {
            path: `/${QueryKeys.Printing}/:taskId`,
            element: <PrintJob />,
          },
          {
            path: Routes.TaskHistory,
            element: <HistoryPage />,
          },
        ],
      },
      {
        element: <ProtectedRoute roles={[Role.SuperAdmin, Role.Tech]} />,
        children: [
          {
            path: Routes.History,
            element: <HistoryPage />,
          },
          {
            path: Routes.Processing,
            element: <ProcessingPage />,
          },
          {
            path: Routes.Folders,
            element: <FoldersPage />,
          },
          {
            path: Routes.Reports,
            element: <ReportsPage />,
            children: [
              {
                path: QueryKeys.Department,
                element: <DepartmentsReport />,
              },
              {
                path: QueryKeys.Employee,
                element: <UsersReport />,
              },
              {
                path: QueryKeys.Petitions,
                element: <PetitionsReport />,
              },
              {
                path: `${QueryKeys.Petitions}/:complexId`,
                element: <SinglePetitionReport />,
              },
              {
                path: QueryKeys.Printing,
                element: <PrintingReport />,
              },
            ],
          },
          {
            path: Routes.PrintJobAuthors,
            element: <PrintjobAuthorsPage />,
          },
          {
            path: Routes.Divisions,
            element: <DivisionsPage />,
          },
          {
            path: Routes.Settings,
            element: <SettingsPage />,
          },
          {
            path: Routes.Events,
            element: <EventsPage isSystem={false} />,
          },
          {
            path: Routes.SystemEvents,
            element: <EventsPage isSystem={true} />,
          },
        ],
      },
      {
        element: <ProtectedRoute roles={[Role.SuperAdmin, Role.Admin, Role.Tech]} />,
        children: [
          {
            path: Routes.Users,
            element: <UsersPage />,
          },
          {
            path: Routes.Printers,
            element: <PrintersPage />,
          },
          {
            path: Routes.CRInstances,
            element: <CRInstancesPage />,
          },
          {
            path: Routes.Processes,
            element: <ProcessesPage />,
          },
        ],
      },
      {
        path: Routes.Error,
        element: <ErrorPage text="Ошибка сервера, попробуйте позже!" />,
      },
      {
        path: Routes.NotFound,
        element: <ErrorPage />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

export default router;
