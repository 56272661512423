import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router';

import { RiLoginBoxLine, RiLogoutBoxRLine } from 'react-icons/ri';
import Container from '../ui/Container';
import Button from '../ui/Button';
import Tag from '../../components/ui/Tag';
import { ArrowBigIcon } from '../ui/icons/Icons';
import Buttons from '../ui/Buttons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ITag } from '../../redux/slices/headerInfo/slice';
import { Routes } from '../../Router';
import useModal from '../../hooks/useModal';
import { imageEditorActions } from '../../redux/slices/imageEditor';
import { userActions, userSelectors } from '../../redux/slices/user';
import { headerInfoSelectors } from '../../redux/slices/headerInfo';
import Menu from './Menu';
import { Role } from '../../types/IUserInfo';
import { printJobActions } from '../../redux/slices/printJob';
import ConfirmationModal from '../common/ConfirmationModal';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { QueryKeys } from '../../data/QueryKeys';
import UpdateComplexModal from '../../pages/main/modals/UpdateComplexModal';
import { useGetInformationQuery } from '../../redux/api/information';
import { POLLING_INTERVAL_M } from '../../const';
import { useLogoutMutation } from '../../redux/api/auth';
import Spinner from '../ui/Spinner';

import styles from './Header.module.scss';

interface Props {
  className?: string;
}

const Header = ({ className }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const headerInfo = useAppSelector(headerInfoSelectors.selectHeaderInfoContent);
  const awardsFactCount = useAppSelector(operatorTaskSelectors.selectAwardFactCount);
  const awardsCount = useAppSelector(operatorTaskSelectors.selectActualAwardsCount);
  const user = useAppSelector(userSelectors.selectUserData);
  const isTaskHistory = !!useMatch(Routes.TaskHistory);
  const [logoutUser, { isLoading, isSuccess }] = useLogoutMutation();

  const { data: information } = useGetInformationQuery(undefined, {
    skip: !user,
    pollingInterval: POLLING_INTERVAL_M,
  });

  const locationParts = location.pathname.split('/');
  const isOperatorTask = locationParts.some(part => part.includes(QueryKeys.Operator)); // TODO удалить

  const isPrintTask = locationParts.includes(QueryKeys.Printing);

  const { modalOpenHandler, modalCloseHandler, modalMarkup } = useModal(
    <ConfirmationModal
      title="Выход из системы"
      text="Вы действительно хотите выйти из системы?"
      onConfirm={logoutUser}
      variant="danger"
    />,
  );

  const { modalMarkup: handleTaskModalMarkup, modalOpenHandler: handleTaskModalOpenHandler } =
    useModal(
      <UpdateComplexModal
        complexId={params.taskId}
        initialCount={+awardsFactCount}
        countEditing
        countSetting
        barcodeSetting={false}
      />,
    );

  const activeDocumentResetHandler = useCallback(() => {
    dispatch(imageEditorActions.turnOffImageAnimation());
    if (isOperatorTask) {
      dispatch(operatorTaskActions.setActiveDocument(null));
    } else if (isPrintTask) {
      dispatch(printJobActions.setDocument(null));
    }
    setTimeout(() => {
      dispatch(imageEditorActions.turnOnImageAnimation());
    }, 300);
  }, [dispatch, isOperatorTask, isPrintTask]);

  const backLink = useMemo(() => {
    const locationParts = location.pathname.split('/');
    locationParts.pop();
    if (isTaskHistory) {
      locationParts.pop();
    }

    return locationParts.join('/');
  }, [isTaskHistory, location.pathname]);

  const backClickHandler = useCallback(() => {
    if (backLink.length) navigate(backLink);
    else navigate(-1);
  }, [backLink, navigate]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(userActions.resetUser());
      dispatch(userActions.setAuthCheckedStatus(false));
      modalCloseHandler();
      navigate(Routes.Login);
    }
  }, [isSuccess, dispatch, navigate, modalCloseHandler]);

  const renderHeaderInfo = (info: (string | null | ITag)[]) => {
    return info.map(item => {
      if (item === null) return null;
      if (typeof item === 'string')
        return (
          <span key={item} className="mt-1">
            {item}
          </span>
        );
      else if (item.button)
        return (
          <button
            key={item.text}
            onClick={activeDocumentResetHandler}
            className={styles.headerInfoBtn}
          >
            <Tag variant={item.variant} link>
              {item.text}
            </Tag>
          </button>
        );
      else if (item.modal) {
        return (
          <button
            key={item.text}
            onClick={handleTaskModalOpenHandler}
            className={styles.headerInfoBtn}
          >
            <Tag variant={awardsFactCount === awardsCount ? 'status' : 'low'} link>
              {`Указ. НЛ: ${awardsFactCount}`}
            </Tag>
          </button>
        );
      } else
        return (
          <Tag key={item.text} variant={item.variant}>
            {item.text}
          </Tag>
        );
    });
  };

  return (
    <header className={classNames(styles.header, className, 'pt-2 pb-2')}>
      {isLoading && <Spinner />}
      <Container className={styles.container}>
        {(user?.role === Role.Admin ||
          user?.role === Role.SuperAdmin ||
          user?.role === Role.Tech) && <Menu />}
        <div className={classNames(styles.headerInfo, 'ml-4', 'mr-4')}>
          <div className={classNames(styles.info, styles.leftInfo)}>
            {renderHeaderInfo(headerInfo)}
          </div>
          <div className={classNames(styles.info, styles.rightInfo)}>
            <Tag>{information?.lineName ?? 'Линия'}</Tag>
            <Tag>{information?.crRemainingLicencesSum ?? 'Лицензия'}</Tag>
            <Tag>{user?.name ?? 'Оператор'}</Tag>
          </div>
        </div>
        <Buttons>
          <Button
            viewType="round"
            className={styles.backBtn}
            onClick={backClickHandler}
            title="Назад"
          >
            <ArrowBigIcon />
          </Button>
          {user ? (
            <Button
              viewType="round"
              className={styles.headerBtn}
              onClick={modalOpenHandler}
              variant="danger"
              title="Выйти"
            >
              <RiLogoutBoxRLine />
            </Button>
          ) : (
            <Button
              viewType="round"
              className={styles.headerBtn}
              link={Routes.Login}
              variant="success"
              title="Авторизация"
            >
              <RiLoginBoxLine />
            </Button>
          )}
        </Buttons>
      </Container>
      {modalMarkup}
      {handleTaskModalMarkup}
    </header>
  );
};

export default Header;
