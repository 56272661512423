import { IPaginatedResult } from './../../types/IPaginatedResult';
import { TId } from './../../types/TId';
import { api } from '../api';
import { ComplexType, IComplexInfo } from '../../types/IComplexInfo';
import { IComplex } from '../../types/IComplex';
import { IComplexDocument } from '../../types/IComplexDocument';
import { IImage } from '../../types/IImage';
import { IGridOptions } from '../../types/IGridOptions';
import { getSearchParams } from '../../utils/searchParams';

export interface IComplexesOptions extends IGridOptions {
  error?: boolean;
  states?: string;
  userId?: string;
  dateAddTsFrom?: string;
  dateAddTsTo?: string;
  search?: string;
}

export const complexesApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getComplexesList: builder.query<IPaginatedResult<IComplexInfo>, IComplexesOptions>({
      query: params => `api/complexes${getSearchParams(params)}`,
    }),
    getComplexById: builder.query<IComplex, number | string>({
      query: id => `api/complexes/${id}/model`,
    }),
    deleteComplex: builder.mutation<string, string>({
      query: id => ({
        url: `api/complexes/${id}`,
        method: 'DELETE',
      }),
    }),
    createComplex: builder.mutation<
      IComplexInfo,
      { barcode: string; type: ComplexType; partsTotal?: number }
    >({
      query: body => ({
        url: 'api/complexes',
        method: 'POST',
        body,
      }),
    }),
    finalizeComplex: builder.mutation<string, TId>({
      query: complexId => ({
        url: `api/complexes/${complexId}/done`,
        method: 'POST',
      }),
    }),
    getPetitionsList: builder.query<Array<IComplexDocument>, TId>({
      query: complexId => `api/complexes/${complexId}/petitions`,
    }),
    setDocumentViewed: builder.mutation<
      IComplexDocument,
      { complexId: TId; docType: 'petitions' | 'awards'; docId: TId }
    >({
      query: ({ complexId, docId, docType }) => ({
        url: `api/complexes/${complexId}/${docType}/${docId}/viewed`,
        method: 'PUT',
      }),
    }),
    uploadImages: builder.mutation<Array<IImage>, { complexId: TId; data: FormData }>({
      query: ({ complexId, data }) => ({
        url: `api/complexes/${complexId}/images`,
        method: 'POST',
        body: data,
      }),
    }),
    updateComplex: builder.mutation<
      IComplexInfo,
      { complexId: TId; data: { barcode?: string; awardsFactCount?: number } }
    >({
      query: ({ complexId, data }) => ({
        url: `api/complexes/${complexId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    saveComplex: builder.mutation<IComplex, { complexId: TId; data: Partial<IComplex> }>({
      query: ({ complexId, data }) => ({
        url: `api/complexes/${complexId}/model/save`,
        method: 'POST',
        body: data,
      }),
    }),
    saveRecognizeComplex: builder.mutation<IComplex, { complexId: TId; data: Partial<IComplex> }>({
      query: ({ complexId, data }) => ({
        url: `api/complexes/${complexId}/model/save-recognize`,
        method: 'POST',
        body: data,
      }),
    }),
    revertComplex: builder.mutation<IComplex, TId>({
      query: complexId => ({
        url: `api/complexes/${complexId}/revert`,
        method: 'POST',
      }),
    }),
    retryComplex: builder.mutation<IComplex, TId>({
      query: complexId => ({
        url: `api/complexes/${complexId}/retry`,
        method: 'POST',
      }),
    }),
    priorityComplex: builder.mutation<IComplex, TId>({
      query: complexId => ({
        url: `api/complexes/${complexId}/raise-priority`,
        method: 'POST',
      }),
    }),
    duplicateActualize: builder.mutation<IComplex, { id: TId; action: 'accept' | 'cancel' }>({
      query: ({ id, action }) => ({
        url: `api/complexes/${id}/duplicate-${action}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetComplexesListQuery,
  useGetComplexByIdQuery,
  useDeleteComplexMutation,
  useGetPetitionsListQuery,
  useSetDocumentViewedMutation,
  useUploadImagesMutation,
  useUpdateComplexMutation,
  useSaveComplexMutation,
  useSaveRecognizeComplexMutation,
  useFinalizeComplexMutation,
  useRevertComplexMutation,
  useCreateComplexMutation,
  useRetryComplexMutation,
  usePriorityComplexMutation,
  useDuplicateActualizeMutation,
} = complexesApi;
