import { RootState } from '../../store';

export const selectDepartmentsReportStartDate = (state: RootState) =>
  state.reports.departments.start;
export const selectDepartmentsReportEndDate = (state: RootState) => state.reports.departments.end;

export const selectEmployeesReportStartDate = (state: RootState) => state.reports.employees.start;
export const selectEmployeesReportEndDate = (state: RootState) => state.reports.employees.end;

export const selectPetitionsReportStartDate = (state: RootState) => state.reports.petitions.start;
export const selectPetitionsReportEndDate = (state: RootState) => state.reports.petitions.end;

export const selectPrintingReportStartDate = (state: RootState) => state.reports.printing.start;
export const selectPrintingReportEndDate = (state: RootState) => state.reports.printing.end;
