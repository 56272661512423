import { ReactElement } from 'react';
import classNames from 'classnames';
import { ImageState } from '../../types/IImage';
import {
  TbArticleOff,
  TbTrashX,
  TbPlus,
  TbBaselineDensityMedium,
  TbArrowBigRightLines,
} from 'react-icons/tb';
import styles from './Slide.module.scss';
import Indicator from '../ui/Indicator';
import Checkbox from '../ui/Checkbox';
import LazyLoad from 'react-lazyload';

interface Props {
  isReady: boolean;
  src: string;
  rotateDeg: number;
  mod?: ImageState;
  active: boolean;
  selected: boolean;
  showCheckbox: boolean;
  touched?: boolean;
  className?: string;
  a3middle?: boolean;
  onClick: () => void;
  onSelect: () => void;
  hidden?: boolean;
}

const OVERLAYS: Record<ImageState, ReactElement> = {
  [ImageState.NotRecognized]: <TbArticleOff />,
  [ImageState.Deleted]: <TbTrashX />,
  [ImageState.Added]: <TbPlus />,
  [ImageState.Empty]: <TbBaselineDensityMedium />,
  [ImageState.Inserted]: <TbArrowBigRightLines />,
};

const Slide = ({
  src,
  rotateDeg,
  mod,
  active,
  selected,
  showCheckbox,
  touched,
  className,
  a3middle,
  hidden,
  onClick,
  onSelect,
  isReady,
}: Props) => {
  return (
    <div
      className={classNames(
        styles.slide,
        active && styles.active,
        className,
        a3middle && styles.a3middle,
        hidden && styles.emptySlide,
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.slideState, hidden && styles.hidden)}>
        <Indicator active={!!touched} variant="success" />
        {showCheckbox && <Checkbox onChange={onSelect} checked={selected} className="ml-a" />}
      </div>
      {isReady ? (
        <LazyLoad debounce={500}>
          <img
            src={src}
            alt="Предпросмотр"
            className={classNames(styles.previewImage, hidden && styles.hidden)}
            style={{
              transform: `rotate(${rotateDeg}deg)`,
            }}
          />
        </LazyLoad>
      ) : null}

      <div className={classNames('swiper-lazy-preloader', hidden && styles.hidden)} />
      {!!mod && (
        <div className={classNames(styles.overlay, hidden && styles.hidden, styles[mod])}>
          {OVERLAYS[mod]}
        </div>
      )}
    </div>
  );
};
export default Slide;
