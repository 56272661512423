import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';

import { useAppSelector } from '../../redux/hooks';
import { Routes } from '../../Router';
import { userSelectors } from '../../redux/slices/user';
import Spinner from '../ui/Spinner';
import { Role } from '../../types/IUserInfo';

interface Props {
  roles?: Role[];
}

const ProtectedRoute = ({ roles }: Props) => {
  const navigate = useNavigate();
  const { authChecked, data } = useAppSelector(userSelectors.selectUserState);

  useEffect(() => {
    if (authChecked && !data) {
      navigate(Routes.Login);
    }

    if (authChecked && data && roles && !roles.includes(data.role)) {
      navigate(Routes.Main);
    }
  }, [authChecked, data, roles, navigate]);

  if (!authChecked) return <Spinner />;

  return authChecked && data ? <Outlet /> : null;
};

export default ProtectedRoute;
