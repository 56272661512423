import DataGrid from '../../../components/grid/DataGrid';
import { createColumnHelper } from '@tanstack/react-table';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import Tag from '../../../components/ui/Tag';
import { QueryKeys } from '../../../data/QueryKeys';
import { ComplexState, ComplexStateEnum } from '../../../types/IComplexInfo';
import InfoButton from '../../../components/ui/InfoButton';
import ErrorCell from '../../../components/grid/ErrorCell';
import { getReadableDateTime } from '../../../utils/date';
import { IEventsOptions, useGetEventsQuery } from '../../../redux/api/events';
import { Dispatch, SetStateAction } from 'react';
import { IEvent, ObjectActionEnum } from '../../../types/IEvent';
import { PrintJobState, PrintJobStateEnum } from '../../../types/IPrintInfo';
import { useServerSortingPagination } from '../../../hooks/useServerSortingPagination';

interface Props {
  isSingle: boolean;
  fetchOptions: IEventsOptions;
  fetchOptionsSetter: Dispatch<SetStateAction<IEventsOptions>>;
}

const columnHelper = createColumnHelper<IEvent>();

const singleColumns = [
  columnHelper.accessor('dateAddTs', {
    header: 'Дата',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    enableGlobalFilter: false,
    filterFn: makeDateRangeFilterFunction<IEvent>(),
  }),
  columnHelper.accessor('objectState', {
    header: 'Статус',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '200px', margin: '0 auto', width: '200px' }}>
        {/* Временно для отображения статусов заданий на печать*/}
        {info.row.original.objectType === 'complex'
          ? ComplexStateEnum.getDisplayName(info.getValue() as ComplexState)
          : PrintJobStateEnum.getDisplayName(info.getValue() as PrintJobState)}
      </Tag>
    ),
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('userShowName', {
    header: 'Оператор',
    cell: info =>
      info.getValue() && info.getValue()?.trim().length ? (
        <Tag style={{ minWidth: '200px', margin: '0 auto' }}>{info.getValue()}</Tag>
      ) : null,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('action', {
    header: 'Действие',
    cell: info => (
      <Tag variant="status" style={{ minWidth: '250px', margin: '0 auto', width: '250px' }}>
        {ObjectActionEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('error', {
    header: 'Ошибки',
    cell: info => <ErrorCell isError={info.getValue()} />,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('message', {
    header: 'Описание',
    cell: info =>
      info.getValue() ? (
        <InfoButton text={info.getValue() as string} style={{ verticalAlign: 'middle' }} />
      ) : null,
    enableSorting: false,
  }),
];

const allColumns = [
  columnHelper.accessor('objectName', {
    header: 'Имя документа',
    cell: info => info.renderValue(),
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('objectCode', {
    header: 'Штрихкод (QR-код)',
    cell: info => info.renderValue(),
    enableGlobalFilter: true,
  }),
  ...singleColumns,
];

const HistoryGrid = ({ isSingle, fetchOptions, fetchOptionsSetter }: Props) => {
  const { data, isLoading } = useGetEventsQuery(fetchOptions, {
    refetchOnMountOrArgChange: true,
  });

  const { paginationChangeHandler, sortingChangeHandler } =
    useServerSortingPagination(fetchOptionsSetter);

  return (
    <DataGrid
      gridKey={QueryKeys.Events}
      rows={data?.results}
      totalCount={data?.pagination?.totalCount}
      pagesCount={
        data ? Math.ceil(data.pagination?.totalCount / data.pagination?.perPage) : undefined
      }
      isLoading={isLoading}
      columns={isSingle ? singleColumns : allColumns}
      rowIdSelector={row => row.id}
      hideAllSettings
      serverControlled={true}
      onPaginationChange={paginationChangeHandler}
      onSortingChange={sortingChangeHandler}
      initialSorting={[{ id: 'dateAddTs', desc: true }]}
    />
  );
};

export default HistoryGrid;
