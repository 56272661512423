import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { FolderTypeEnum, IFolder, ProtocolEnum } from '../../../types/IFolder';
import Tag from '../../../components/ui/Tag';
import DataGrid from '../../../components/grid/DataGrid';
import { useGetFoldersListQuery } from '../../../redux/api/folders';
import ColorIndicator from '../../../components/common/ColorIndicator';
import { QueryKeys } from '../../../data/QueryKeys';
import { POLLING_INTERVAL_S } from '../../../const';

interface Props {
  filters: ColumnFiltersState;
}
const columnHelper = createColumnHelper<IFolder>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Наименование',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('type', {
    header: 'Тип',
    cell: info => (
      <Tag style={{ minWidth: '200px', margin: '0 auto' }}>
        {FolderTypeEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
  }),
  columnHelper.accessor('protocol', {
    header: 'Протокол',
    cell: info =>
      info.getValue() ? (
        <Tag style={{ minWidth: '100px', margin: '0 auto' }}>
          {ProtocolEnum.getDisplayName(info.getValue()!)}
        </Tag>
      ) : null,
    size: 160,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('host', {
    header: 'Хост',
    cell: info =>
      info.getValue() ? (
        <Tag style={{ minWidth: '100px', margin: '0 auto' }}>{info.getValue()}</Tag>
      ) : null,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('path', {
    header: 'Путь',
    cell: info => <Tag style={{ minWidth: '200px', margin: '0 auto' }}>{info.getValue()}</Tag>,
    size: 160,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('active', {
    header: 'Активность',
    cell: info => (
      <Tag
        variant={info.getValue() ? 'status' : 'low'}
        style={{ minWidth: '60px', margin: '0 auto', display: 'block', width: 'fit-content' }}
      >
        {info.getValue() ? 'Да' : 'Нет'}
      </Tag>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'Статус',
    cell: info => (
      <ColorIndicator
        style={{ marginLeft: '20px' }}
        color={info.row.original.active ? (info.getValue() === 'alive' ? 'green' : 'red') : 'grey'}
        hint={info.getValue() === 'alive' ? 'Доступен' : 'Недоступен'}
      />
    ),
  }),
];

const FoldersGrid = ({ filters }: Props) => {
  const { data, isLoading } = useGetFoldersListQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_S,
  });
  return (
    <DataGrid
      gridKey={QueryKeys.Folders}
      rows={data}
      isLoading={isLoading}
      columns={columns}
      rowIdSelector={row => row.id.toString()}
      hideAllSettings
      filters={filters}
    />
  );
};

export default FoldersGrid;
