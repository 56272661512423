import Search from '../../../components/common/Search';
import Button from '../../../components/ui/Button';
import { Routes } from '../../../Router';
import GridPage from '../gridPage/GridPage';
import DivisionsActions from './DivisionsActions';
import DivisionsGrid from './DivisionsGrid';

const DivisionsPage = () => {
  return (
    <GridPage
      title="Управления"
      panel={
        <>
          <Search placeholder="Введите название" />
          <Button className="ml-a" link={Routes.PrintJobAuthors}>
            Сотрудники
          </Button>
        </>
      }
      grid={<DivisionsGrid />}
      actions={<DivisionsActions />}
    />
  );
};

export default DivisionsPage;
