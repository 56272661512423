import { useCallback, useEffect, useState } from 'react';

import Button from '../../components/ui/Button';
import {
  DeleteIcon,
  PasteAfterIcon,
  PasteBeforeIcon,
  RecognizeIcon,
  SaveIcon,
} from '../../components/ui/icons/Icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { imageEditorActions } from '../../redux/slices/imageEditor';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { INormalizedComplexDocument } from '../../redux/slices/operatorTask/slice';
import DocumentList from '../../components/common/DocumentList';

import styles from './ItemList.module.scss';
import { DocSection } from '../../types/DocSection';
import { prepareComplex } from '../../redux/slices/operatorTask/prepareComplex';
import {
  useSetDocumentViewedMutation,
  useSaveComplexMutation,
  complexesApi,
  useSaveRecognizeComplexMutation,
} from '../../redux/api/complexes';
import Spinner from '../../components/ui/Spinner';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { QueryKeys } from '../../data/QueryKeys';
import useModal from '../../hooks/useModal';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { OPERATOR_COMPLEXES } from '../main/grids/filters';
import { EditorActionType } from '../../types/EditorAction';
import CreateAwardModal from './modals/CreateAwardModal';

interface Props {
  readOnly: boolean;
}

const ItemList = ({ readOnly }: Props) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [recognizeAllMode, setRecognizeAllMode] = useState(false);
  const [insertIndex, setInsertIndex] = useState<number>();
  const activeDocument = useAppSelector(operatorTaskSelectors.selectActiveDocumentInfo);
  const activeAwardIndex = useAppSelector(operatorTaskSelectors.selectActiveAwardIndex);
  const actions = useAppSelector(operatorTaskSelectors.actions.selectIds);
  const complex = useAppSelector(operatorTaskSelectors.selectComplex);
  const complexName = useAppSelector(operatorTaskSelectors.selectComplexName);

  const recognizeHandler = () => {
    setRecognizeAllMode(true);
    saveRecognizeHandler(true);
  };

  const {
    modalOpenHandler: recognizeConfirmOpenHandler,
    modalCloseHandler: recognizeConfirmCloseHandler,
    modalMarkup: recognizeConfirmModal,
  } = useModal(
    <ConfirmationModal
      title="Перераспознавание задания"
      text="Все предыдущие изменения будут сохранены, задание отправится на перераспознавание. Продолжить?"
      onConfirm={recognizeHandler}
    />,
  );

  const { modalMarkup: insertAwardMarkup, modalOpenHandler: insertAwardOpenHandler } = useModal(
    <CreateAwardModal index={insertIndex} />,
  );

  const [setViewed, { isSuccess: setViewedSuccess }] = useSetDocumentViewedMutation();

  useEffect(() => {
    if (setViewedSuccess && activeDocument) {
      dispatch(
        operatorTaskActions.setDocumentViewed({
          id: activeDocument.id,
          type: activeDocument.docType,
        }),
      );
    }
  }, [activeDocument, dispatch, setViewedSuccess]);

  const documents: INormalizedComplexDocument[] = useAppSelector(
    operatorTaskSelectors.selectAllActiveDocuments,
  );

  const notRecCount = useAppSelector(operatorTaskSelectors.selectNotRecognizedCount);

  const documentsWithNotRecognizedCount = documents.map(doc => ({
    ...doc,
    extraCount: notRecCount[doc.barcode],
    isDublicate: documents.some(d => d.id !== doc.id && d.barcode === doc.barcode),
  }));

  const listCLickHandler = useCallback(
    (item: INormalizedComplexDocument) => {
      const docType = item.barcode.startsWith('21') ? DocSection.Petitions : DocSection.Awards;
      dispatch(imageEditorActions.turnOffImageAnimation());
      dispatch(
        operatorTaskActions.setActiveDocument({
          id: item.id,
          docType,
          barcode: item.barcode,
        }),
      );
      setTimeout(() => {
        dispatch(imageEditorActions.turnOnImageAnimation());
      }, 300);
      if (
        !readOnly &&
        !item.viewed &&
        !item.actions.some(a => a.type === EditorActionType.CreateAward)
      ) {
        setViewed({ complexId: taskId!, docType, docId: item.id });
      }
    },
    [dispatch, readOnly, setViewed, taskId],
  );

  const [saveComplex, { data: savedComplex, isLoading: saving }] = useSaveComplexMutation();
  const [saveRecognizeComplex, { data: savedRecognizedComplex, isLoading: savingAndRecognizing }] =
    useSaveRecognizeComplexMutation();

  const saveHandler = useCallback(() => {
    const result = prepareComplex(complex, false);

    if (taskId) {
      saveComplex({ complexId: taskId, data: result });
    }
  }, [complex, taskId, saveComplex]);

  const saveRecognizeHandler = useCallback(
    (isRecognition: boolean) => {
      const result = prepareComplex(complex, isRecognition);

      if (taskId) {
        saveRecognizeComplex({ complexId: taskId, data: result });
      }
    },
    [complex, taskId, saveRecognizeComplex],
  );

  useEffect(() => {
    if (savedComplex) {
      toast.success(
        `Задание ${complexName} успешно сохранено${
          recognizeAllMode ? ' и отправлено на перераспознавание' : ''
        }!`,
      );
      dispatch(imageEditorActions.turnOffImageAnimation());
      dispatch(operatorTaskActions.setNormalizedTask(savedComplex));
      recognizeConfirmCloseHandler();
      setTimeout(() => {
        dispatch(imageEditorActions.turnOnImageAnimation());
      }, 300);
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: OPERATOR_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    }
  }, [complexName, dispatch, recognizeAllMode, recognizeConfirmCloseHandler, savedComplex, taskId]);

  useEffect(() => {
    if (savedRecognizedComplex) {
      if (savedRecognizedComplex.state === 'in_work') {
        toast.success(`Задание ${complexName} успешно сохранено. Нет образов для распознавания!`);
        dispatch(imageEditorActions.turnOffImageAnimation());
        dispatch(operatorTaskActions.setNormalizedTask(savedRecognizedComplex));
        setTimeout(() => {
          dispatch(imageEditorActions.turnOnImageAnimation());
        }, 300);
      } else {
        toast.success(
          `Задание ${complexName} успешно сохранено и отправлено на перераспознавание!`,
        );
        navigate(`/${QueryKeys.Operator}`);
      }
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: OPERATOR_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    }
  }, [complexName, dispatch, navigate, savedRecognizedComplex, taskId]);

  return (
    <>
      {(saving || savingAndRecognizing) && <Spinner />}
      <DocumentList<INormalizedComplexDocument & { extraCount?: number }>
        items={documentsWithNotRecognizedCount}
        getItemTitle={item => item.barcode}
        getItemId={item => item.id}
        activeId={activeDocument?.id ?? undefined}
        title="Список наградных листов"
        showCount
        className="mb-7"
        onDocumentClick={listCLickHandler}
      />
      {!readOnly && (
        <div>
          <Button
            className={styles.listButton}
            variant="danger"
            icon={<DeleteIcon />}
            disabled={activeDocument?.docType !== DocSection.Awards}
            onClick={() => {
              dispatch(operatorTaskActions.deleteAward());
              dispatch(operatorTaskActions.setActiveDocument(null));
            }}
          >
            Удалить НЛ
          </Button>
          <Button
            className={styles.listButton}
            icon={<PasteBeforeIcon />}
            disabled={activeAwardIndex < 0}
            onClick={() => {
              setInsertIndex(activeAwardIndex);
              insertAwardOpenHandler();
            }}
          >
            Вставить до
          </Button>
          <Button
            className={styles.listButton}
            icon={<PasteAfterIcon />}
            disabled={activeAwardIndex < 0}
            onClick={() => {
              setInsertIndex(activeAwardIndex + 1);
              insertAwardOpenHandler();
            }}
          >
            Вставить после
          </Button>
          <Button
            className={styles.listButton}
            icon={<SaveIcon />}
            disabled={!actions.length}
            onClick={() => saveHandler()}
          >
            Сохранить
          </Button>
          <Button
            className={styles.listButton}
            icon={<SaveIcon />}
            disabled={!actions.length}
            onClick={() => saveRecognizeHandler(false)}
          >
            Сохранить и распознать
          </Button>
          <Button
            className={styles.listButton}
            icon={<RecognizeIcon />}
            onClick={recognizeConfirmOpenHandler}
          >
            Перераспознать все
          </Button>
        </div>
      )}
      {recognizeConfirmModal}
      {insertAwardMarkup}
    </>
  );
};

export default ItemList;
