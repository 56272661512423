import { ReactElement, cloneElement, useCallback, useState } from 'react';
import Modal from '../components/ui/Modal';

type TContent = ReactElement & { onClose?: () => void };

const useModal = (content: TContent, className?: string, options?: { light?: boolean }) => {
  const [open, setOpen] = useState(false);

  const modalOpenHandler = useCallback(() => setOpen(true), []);
  const modalCloseHandler = useCallback(() => setOpen(false), []);

  const contentWithClose = cloneElement(content, {
    onClose: modalCloseHandler,
  });

  const modalMarkup = open && (
    <Modal onClose={modalCloseHandler} className={className} options={options}>
      {contentWithClose}
    </Modal>
  );

  return { modalOpenHandler, modalCloseHandler, modalMarkup };
};

export default useModal;
