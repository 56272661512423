import { ComponentProps } from 'react';
import ReactSelect, { GroupBase, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';

import cssVars from '../../styles/vars.module.scss';
import styles from './Select.module.scss';

interface Props extends ComponentProps<ReactSelect> {
  label?: string;
  className?: string;
  creatable?: boolean;
}

const Select = ({ label, className, creatable, isSearchable = false, ...rest }: Props) => {
  const { borderColor, focusColor, borderRadius, transition, textColor } = cssVars;

  const stylesConfig: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      transition,
      cursor: 'pointer',
      borderColor: state.isFocused ? focusColor : borderColor,
      borderRadius,
      boxShadow: 'none',
      padding: '6px 8px',
      outline: 'none',
      '&:hover': {
        borderColor: focusColor,
      },
    }),
    option: baseStyles => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: textColor,
    }),
  };

  return (
    <div className={classNames(styles.root, className)}>
      {label && <label className={styles.label}>{label}</label>}
      {creatable ? (
        <CreatableSelect
          isClearable
          menuPosition="fixed"
          styles={stylesConfig}
          formatCreateLabel={value => `Новое значение: ${value}`}
          {...rest}
        />
      ) : (
        <ReactSelect
          {...rest}
          isSearchable={isSearchable}
          menuPosition="fixed"
          styles={stylesConfig}
          noOptionsMessage={() => 'Ничего не найдено!'}
        />
      )}
    </div>
  );
};

export default Select;
