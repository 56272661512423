import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import {
  crInstancesApi,
  useCreateCRInstanceMutation,
  useEditCRInstanceMutation,
  useGetCRInstanceByIdQuery,
} from '../../../redux/api/crinstances';
import {
  ICRInstance,
  OrientationDetectionModeEnum,
  RecognitionModeEnum,
  OrientationDetectionMode,
  RecognitionMode,
} from '../../../types/ICRInstance';
import { toast } from 'react-toastify';
import Spinner from '../../../components/ui/Spinner';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Checkbox from '../../../components/ui/Checkbox';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import { useAppDispatch } from '../../../redux/hooks';
import Select from '../../../components/ui/Select';
import { ISelectOption } from '../../../types/ISelectOption';

interface Props {
  instanceId?: string;
  onClose?: () => void;
}

const CRInstancesModal = ({ instanceId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { data, isFetching } = useGetCRInstanceByIdQuery(instanceId!, { skip: !instanceId });

  const [formData, setFormData] = useState<ICRInstance>({
    id: '',
    name: '',
    url: '',
    threads: 1,
    active: true,
    status: 'alive',
    properties: {
      orientationDetectionMode: OrientationDetectionMode.Normal,
      recognitionMode: RecognitionMode.Normal,
    },
  });

  const [createInstance, { data: createData, isLoading: creatingInstance }] =
    useCreateCRInstanceMutation();

  const [editInstance, { data: editData, isLoading: editingInstance }] =
    useEditCRInstanceMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        url: data.url,
        threads: data.threads,
        active: data.active,
        status: data.status,
        properties: {
          recognitionMode: data.properties.recognitionMode,
          orientationDetectionMode: data.properties.orientationDetectionMode,
        },
      });
    }
  }, [data]);

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.name.trim().length < 2) {
      toast.error('Некорректное название!');
      isValid = false;
    }
    if (formData.url.trim().length < 2) {
      toast.error('Некорректный адрес!');
      isValid = false;
    }
    if (formData.threads <= 0) {
      toast.error('Укажите количество потоков!');
      isValid = false;
    }

    return isValid;
  }, [formData.name, formData.url, formData.threads]);

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...createData } = formData;
        createInstance(createData);
      } else {
        return;
      }
    },
    [createInstance, formData, validateForm],
  );

  useEffect(() => {
    if (createData || editData) {
      onClose?.();
      dispatch(
        crInstancesApi.endpoints.getCRInstancesList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createData, dispatch, editData, onClose]);

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        editInstance(formData);
      } else {
        return;
      }
    },
    [editInstance, formData, validateForm],
  );

  return (
    <div>
      {(isFetching || creatingInstance || editingInstance) && <Spinner />}
      <ModalHeading className="mb-6">
        {instanceId ? 'Редактирование инстанса' : 'Создание инстанса'}
      </ModalHeading>
      <form onSubmit={instanceId ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="name"
          name="name"
          label="Наименование"
          className="mb-4"
          value={formData.name}
          onChange={value => formChangeHandler('name', value)}
        />
        <TextInput
          type="text"
          id="url"
          name="url"
          label="Адрес"
          className="mb-4"
          value={formData.url ?? ''}
          onChange={value => formChangeHandler('url', value)}
        />
        <TextInput
          type="number"
          id="threads"
          name="threads"
          label="Потоки"
          className="mb-4"
          value={formData.threads ?? ''}
          onChange={value => formChangeHandler('threads', value)}
        />
        <Select
          label="Режим определения ориентации"
          placeholder="Выберите значение"
          className="mb-4"
          isClearable
          options={OrientationDetectionModeEnum.items.map(mode => ({
            label: mode.displayName,
            value: mode.value,
          }))}
          value={
            formData.properties.orientationDetectionMode
              ? {
                  label: OrientationDetectionModeEnum.getDisplayName(
                    formData.properties.orientationDetectionMode,
                  ),
                  value: formData.properties.orientationDetectionMode,
                }
              : null
          }
          onChange={value =>
            setFormData(prev => ({
              ...prev,
              properties: {
                orientationDetectionMode: value
                  ? ((value as ISelectOption).value as OrientationDetectionMode)
                  : undefined,
                recognitionMode: prev.properties.recognitionMode,
              },
            }))
          }
        />
        <Select
          label="Режим распознавания"
          placeholder="Выберите значение"
          className="mb-4"
          isClearable
          options={RecognitionModeEnum.items.map(mode => ({
            label: mode.displayName,
            value: mode.value,
          }))}
          value={
            formData.properties.recognitionMode
              ? {
                  label: RecognitionModeEnum.getDisplayName(formData.properties.recognitionMode),
                  value: formData.properties.recognitionMode,
                }
              : null
          }
          onChange={value =>
            setFormData(prev => ({
              ...prev,
              properties: {
                orientationDetectionMode: prev.properties.orientationDetectionMode,
                recognitionMode: value
                  ? ((value as ISelectOption).value as RecognitionMode)
                  : undefined,
              },
            }))
          }
        />

        <Checkbox
          label="Активность"
          checked={formData.active}
          onChange={state => setFormData(prev => ({ ...prev, active: state }))}
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {instanceId ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default CRInstancesModal;
