import { useCallback } from 'react';
import { ColumnFiltersState, Updater } from '@tanstack/react-table';
import { IDateRange } from '../../types/IDateRange';
import DateRangeInput from '../ui/DateRangeInput';

interface Props {
  filterSetter: (updater: Updater<ColumnFiltersState>) => void;
  dateFilterColumn: string;
  wrapped?: boolean;
  className?: string;
}

const DateFilter = ({ dateFilterColumn, filterSetter, wrapped, className }: Props) => {
  const dateChangeHandler = useCallback(
    (dateRange: IDateRange) => {
      filterSetter(prev => [
        ...prev.filter(f => f.id !== dateFilterColumn),
        { id: dateFilterColumn as string, value: dateRange },
      ]);
    },
    [dateFilterColumn, filterSetter],
  );

  return <DateRangeInput wrapped={wrapped} className={className} onChange={dateChangeHandler} />;
};

export default DateFilter;
