import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { IDivisionCreateInfo } from '../../../types/IDivision';
import {
  divisionsApi,
  useCreateDivisionMutation,
  useEditDivisionMutation,
  useGetDivisionQuery,
} from '../../../redux/api/divisions';
import { toast } from 'react-toastify';
import Spinner from '../../../components/ui/Spinner';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';

interface Props {
  divisionId: string | undefined;
  onClose?: () => void;
}

const DivisionModal = ({ divisionId, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<IDivisionCreateInfo>({
    name: '',
  });

  const { data: division, isFetching: divisionFetching } = useGetDivisionQuery(divisionId!, {
    skip: !divisionId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (division) {
      setFormData({
        name: division.name,
      });
    }
  }, [division]);

  const [createDivision, { isLoading: creatingDivision, data: createdDivision }] =
    useCreateDivisionMutation();
  const [editDivision, { isLoading: editingDivision, data: editResult }] =
    useEditDivisionMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.name.trim().length < 2) {
      toast.error('Некорректное наименование!');
      isValid = false;
    }
    return isValid;
  }, [formData.name]);

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        createDivision(formData);
      } else {
        return;
      }
    },
    [createDivision, formData, validateForm],
  );

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();

      if (!division) return;

      const isFormValid = validateForm();

      if (!isFormValid) return toast.error('Данные некорректны!');

      editDivision({
        id: division.id,
        data: formData,
      });
    },
    [division, editDivision, formData, validateForm],
  );

  useEffect(() => {
    if (createdDivision || editResult) {
      onClose?.();
      dispatch(
        divisionsApi.endpoints.getDivisionsList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createdDivision, dispatch, editResult, onClose]);

  return (
    <div>
      {(creatingDivision || editingDivision || divisionFetching) && <Spinner />}
      <ModalHeading className="mb-6">
        {divisionId ? 'Редактирование данных управления' : 'Создание нового управления'}
      </ModalHeading>
      <form onSubmit={divisionId ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="name"
          name="name"
          label="Название"
          className="mb-2"
          value={formData.name}
          onChange={value => formChangeHandler('name', value)}
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {divisionId ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};
export default DivisionModal;
