import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import Select from '../../../components/ui/Select';
import { FolderType, FolderTypeEnum, IFolder, ProtocolEnum } from '../../../types/IFolder';
import { ISelectOption } from '../../../types/ISelectOption';
import PasswordInput from '../../../components/ui/PasswordInput';
import Checkbox from '../../../components/ui/Checkbox';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import {
  foldersApi,
  useCreateFolderMutation,
  useEditFolderMutation,
  useGetFolderByIdQuery,
} from '../../../redux/api/folders';
import Spinner from '../../../components/ui/Spinner';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  folderId?: string;
  onClose?: () => void;
}

const FoldersModal = ({ folderId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { data, isFetching } = useGetFolderByIdQuery(folderId!, { skip: !folderId });

  const [formData, setFormData] = useState<IFolder>({
    id: '',
    name: '',
    type: FolderType.OperatorFolder,
    host: null,
    protocol: null,
    path: '',
    workgroup: '',
    login: '',
    password: '',
    active: true,
    dateaddts: '',
    dateupdatets: '',
    status: '1',
  });

  const [createFolder, { data: createData, isLoading: creatingFolder }] = useCreateFolderMutation();

  const [editFolder, { data: editData, isLoading: editingFolder }] = useEditFolderMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        type: data.type,
        protocol: data.protocol,
        host: data.host,
        path: data.path,
        workgroup: data.workgroup,
        login: data.login,
        password: data.password,
        active: data.active,
        dateaddts: data.dateaddts,
        dateupdatets: data.dateupdatets,
        status: data.status,
      });
    }
  }, [folderId, data]);

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.name.trim().length < 2) {
      toast.error('Некорректное наименование!');
      isValid = false;
    }
    if (!formData.protocol) {
      toast.error('Выберите протокол!');
      isValid = false;
    }
    if (!formData.host || formData.host.trim().length < 2) {
      toast.error('Некорректный хост!');
      isValid = false;
    }
    if (!formData.type.trim().length) {
      toast.error('Выберите тип!');
      isValid = false;
    }
    if (formData.login.trim().length < 2) {
      toast.error('Введите логин!');
      isValid = false;
    }
    if (formData.password.trim().length < 2) {
      toast.error('Введите пароль!');
      isValid = false;
    }

    return isValid;
  }, [
    formData.name,
    formData.protocol,
    formData.host,
    formData.type,
    formData.login,
    formData.password,
  ]);

  const createSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, dateaddts, dateupdatets, ...createData } = formData;
        createData.host = createData.host === '' ? null : createData.host;
        createFolder(createData);
      } else {
        return;
      }
    },
    [createFolder, formData, validateForm],
  );

  useEffect(() => {
    if (createData || editData) {
      onClose?.();
      dispatch(
        foldersApi.endpoints.getFoldersList.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [createData, dispatch, editData, onClose]);

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        const editData = { ...formData };
        editData.host = editData.host === '' ? null : editData.host;
        editFolder(editData);
      } else {
        return;
      }
    },
    [editFolder, formData, validateForm],
  );

  return (
    <div>
      {(isFetching || creatingFolder || editingFolder) && <Spinner />}
      <ModalHeading className="mb-6">
        {folderId ? 'Редактирование папки' : 'Создание папки'}
      </ModalHeading>
      <form onSubmit={folderId ? editSubmitHandler : createSubmitHandler}>
        <TextInput
          type="text"
          id="name"
          name="name"
          label="Наименование"
          className="mb-4"
          value={formData.name}
          onChange={value => formChangeHandler('name', value)}
        />
        <Select
          label="Тип"
          placeholder="Выберите значение"
          className="mb-4"
          options={FolderTypeEnum.items.map(type => ({
            label: type.displayName,
            value: type.value,
          }))}
          value={{ label: FolderTypeEnum.getDisplayName(formData.type), value: formData.type }}
          onChange={value => formChangeHandler('type', (value as ISelectOption).value)}
        />
        <Select
          label="Протокол"
          placeholder="Выберите значение"
          className="mb-4"
          options={ProtocolEnum.items.map(type => ({ label: type.displayName, value: type.value }))}
          value={{ label: formData.protocol, value: formData.protocol }}
          onChange={value => formChangeHandler('protocol', (value as ISelectOption).value)}
        />
        <TextInput
          type="text"
          id="host"
          name="host"
          label="Хост"
          className="mb-4"
          value={formData.host ?? ''}
          onChange={value => formChangeHandler('host', value)}
        />
        <TextInput
          type="text"
          id="path"
          name="path"
          label="Путь"
          className="mb-4"
          value={formData.path}
          onChange={value => formChangeHandler('path', value)}
        />
        <TextInput
          type="text"
          id="workgroup"
          name="workgroup"
          label="Рабочая группа"
          className="mb-4"
          value={formData.workgroup}
          onChange={value => formChangeHandler('workgroup', value)}
        />
        <TextInput
          type="text"
          id="login"
          name="login"
          label="Логин"
          className="mb-4"
          value={formData.login}
          onChange={value => formChangeHandler('login', value)}
        />
        <PasswordInput
          id="password"
          name="password"
          label="Пароль"
          className="mb-4"
          value={formData.password}
          onChange={value => formChangeHandler('password', value)}
        />
        <Checkbox
          label="Активность"
          checked={!!formData.active}
          onChange={state => setFormData(prev => ({ ...prev, active: state }))}
        />
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            {folderId ? 'Сохранить' : 'Создать'}
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default FoldersModal;
