import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../redux/hooks';
import Spinner from '../../components/ui/Spinner';
import { headerInfoActions } from '../../redux/slices/headerInfo';
import { useGetPrintjobByIdQuery } from '../../redux/api/printjobs';
import { printJobActions } from '../../redux/slices/printJob';
import DetailPage from '../details/DetailPage';
import PrintJobToolbar from './PrintJobToolbar';
import ItemList from './ItemList';
import PrintView from './PrintView';
import { toast } from 'react-toastify';

const PrintJob = () => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetPrintjobByIdQuery(taskId!, {
    skip: !taskId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      dispatch(
        headerInfoActions.setHeaderInfoContent([
          {
            text: data.name,
            variant: 'status',
            button: true,
          },
          {
            text: `Страниц: ${data.pagesTotal}`,
          },
        ]),
      );
      dispatch(printJobActions.setJob(data));
    }
    return () => {
      dispatch(headerInfoActions.setHeaderInfoContent([]));
      dispatch(printJobActions.setJob(null));
    };
  }, [data, dispatch]);

  useEffect(() => {
    if (data?.warnings?.length) {
      data.warnings.forEach(warn =>
        toast.warn(warn, { autoClose: false, position: 'bottom-right' }),
      );
    }
    return () => toast.dismiss();
  }, [data?.warnings]);

  return (
    <DetailPage
      isLoading={isLoading}
      aside={<ItemList />}
      toolbar={<PrintJobToolbar />}
      content={isLoading ? <Spinner /> : <PrintView />}
    />
  );
};

export default PrintJob;
