import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import ModalHeading from '../../../components/ui/ModalHeading';
import TextInput from '../../../components/ui/TextInput';
import { TId } from '../../../types/TId';
import { ISetting } from '../../../types/ISetting';
import Buttons from '../../../components/ui/Buttons';
import Button from '../../../components/ui/Button';
import {
  settingsApi,
  useEditSettingMutation,
  useGetSettingsQuery,
} from '../../../redux/api/settings';
import Spinner from '../../../components/ui/Spinner';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../redux/hooks';
import styles from './SettingsModal.module.scss';
import { informationApi } from '../../../redux/api/information';
import Select from '../../../components/ui/Select';
import { ISelectOption } from '../../../types/ISelectOption';

interface Props {
  id: TId;
  onClose?: () => void;
}

const BOOLEAN_OPTIONS = [
  { value: '1', label: 'Да' },
  { value: '0', label: 'Нет' },
];

const SettingsModal = ({ id, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<ISetting>({
    id: '',
    name: '',
    key: '',
    value: '',
  });

  const { data, isFetching } = useGetSettingsQuery(undefined, {
    skip: !id,
    selectFromResult: ({ data, error, isFetching }) => ({
      data: data?.find(s => s.id === id),
      error,
      isFetching,
    }),
  });

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        key: data.key,
        value: data.value,
      });
    }
  }, [data]);

  const [editSetting, { isLoading: editingSetting, data: editResult }] = useEditSettingMutation();

  const formChangeHandler = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    if (formData.value.trim().length < 1) {
      toast.error('Некорректное значение!');
      isValid = false;
    }
    return isValid;
  }, [formData.value]);

  const editSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const isFormValid = validateForm();
      if (isFormValid) {
        editSetting({ id: formData.id, key: formData.key, value: formData.value });
      } else {
        return;
      }
    },
    [editSetting, formData, validateForm],
  );

  useEffect(() => {
    if (editResult) {
      onClose?.();
      dispatch(
        settingsApi.endpoints.getSettings.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
      dispatch(
        informationApi.endpoints.getInformation.initiate(undefined, {
          forceRefetch: true,
          subscribe: false,
        }),
      );
    }
  }, [dispatch, editResult, onClose]);

  const booleanField = (
    <Select
      label="Значение"
      className="mb-4"
      id="value"
      options={BOOLEAN_OPTIONS}
      value={BOOLEAN_OPTIONS.find(opt => opt.value === formData.value)}
      onChange={value => formChangeHandler('value', (value as ISelectOption).value)}
    />
  );

  const textField = (
    <TextInput
      type="text"
      id="value"
      name="value"
      label="Значение"
      className="mb-4"
      value={formData.value}
      onChange={value => formChangeHandler('value', value)}
    />
  );

  return (
    <div className={styles.root}>
      <ModalHeading>Редактирование настройки</ModalHeading>
      <form onSubmit={editSubmitHandler}>
        {(isFetching || editingSetting) && <Spinner />}
        <div className="pl-6 pr-6 pt-4">
          <p className={styles.label}>Назначение</p>
          <p className={styles.value}>{formData.name}</p>
        </div>
        {data?.spec?.type === 'boolean' ? booleanField : textField}
        <Buttons className="mt-6">
          <Button variant="outlined" onClick={() => onClose?.()}>
            Отмена
          </Button>
          <Button type="submit" variant="success">
            Сохранить
          </Button>
        </Buttons>
      </form>
    </div>
  );
};

export default SettingsModal;
