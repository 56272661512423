import { useSearchParams } from 'react-router-dom';
import GridActions from '../../../../components/common/GridActions';
import Button from '../../../../components/ui/Button';
import { BASE_URL } from '../../../../const';
import { IStatOptions, STAT_DATA_KEYS } from '../../../../redux/api/statistic';
import { getSearchParams } from '../../../../utils/searchParams';

interface Props {
  fetchOptions: IStatOptions;
}

const UsersReportActions = ({ fetchOptions }: Props) => {
  const params = getSearchParams(fetchOptions, STAT_DATA_KEYS);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');

  return (
    <GridActions className={userId ? undefined : 'mt-11'}>
      <Button
        link={
          userId
            ? `${BASE_URL}api/statistics/complexes/users/${userId}/export${params}`
            : `${BASE_URL}api/statistics/complexes/users/export${params}`
        }
        download
      >
        Экспорт
      </Button>
    </GridActions>
  );
};
export default UsersReportActions;
