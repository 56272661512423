import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Checkbox from '../../components/ui/Checkbox';
import PopperButton from '../../components/ui/PopperButton';
import { SettingsIcon } from '../../components/ui/icons/Icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { useMemo } from 'react';
import { ALL_FILTER, EMPTY_FILTER } from '../../types/IFilter';
import { TbArticleOff, TbBaselineDensityMedium } from 'react-icons/tb';

import styles from './ImageFilter.module.scss';

const ImageFilter = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(operatorTaskSelectors.selectFilter);

  const allChecked = useMemo(() => {
    return !Object.values(filters).some(v => v === false);
  }, [filters]);

  const btn = (
    <Button viewType="toolbar">
      <SettingsIcon />
    </Button>
  );

  const dropDown = (
    <Card>
      <Checkbox
        className="mb-2"
        label="Все образы"
        checked={allChecked}
        onChange={state =>
          dispatch(operatorTaskActions.updateFilter(state ? ALL_FILTER : EMPTY_FILTER))
        }
      />
      <Checkbox
        className="mb-2"
        label="Значимые"
        checked={filters.important}
        onChange={state =>
          dispatch(
            operatorTaskActions.updateFilter({
              important: state,
            }),
          )
        }
      />
      <div className={styles.group}>
        <Checkbox
          className="mb-2"
          label="Незначимые"
          checked={filters.notImportant}
          onChange={state =>
            dispatch(
              operatorTaskActions.updateFilter({
                notImportant: state,
              }),
            )
          }
        />
        <TbBaselineDensityMedium className={styles.empty} />
      </div>
      <Checkbox
        className="mb-2"
        label="Распознанные"
        checked={filters.recognized}
        onChange={state =>
          dispatch(
            operatorTaskActions.updateFilter({
              recognized: state,
            }),
          )
        }
      />
      <div className={styles.group}>
        <Checkbox
          className="mb-2"
          label="Нераспознанные"
          checked={filters.notRecognized}
          onChange={state =>
            dispatch(
              operatorTaskActions.updateFilter({
                notRecognized: state,
              }),
            )
          }
        />
        <TbArticleOff />
      </div>
    </Card>
  );

  return <PopperButton button={btn} dropDown={dropDown} />;
};

export default ImageFilter;
