import { useEffect, useState } from 'react';
import GridPage from '../../gridPage/GridPage';
import UsersReportActions from './UsersReportActions';
import UsersReportFilters from './UsersReportFilters';
import UsersReportGrid from './UsersReportGrid';
import { IStatOptions } from '../../../../redux/api/statistic';
import { useAppSelector } from '../../../../redux/hooks';
import { reportSelectors } from '../../../../redux/slices/reports';

const UsersReport = () => {
  const startDate = useAppSelector(reportSelectors.selectEmployeesReportStartDate);
  const endDate = useAppSelector(reportSelectors.selectEmployeesReportEndDate);

  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
    dateFromTs: startDate,
    dateToTs: endDate,
  });

  useEffect(() => {
    setFetchOptions(prev => ({ ...prev, dateFromTs: startDate, dateToTs: endDate }));
  }, [startDate, endDate]);

  return (
    <GridPage
      title="Статистика по сотрудникам"
      className="pt-2 pb-7"
      panel={<UsersReportFilters />}
      grid={<UsersReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />}
      actions={<UsersReportActions fetchOptions={fetchOptions} />}
    />
  );
};
export default UsersReport;
