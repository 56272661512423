import Select from '../../../components/ui/Select';
import { ComplexStateEnum } from '../../../types/IComplexInfo';

import { ISelectOption } from '../../../types/ISelectOption';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IEventsOptions } from '../../../redux/api/events';
import { IDateRange } from '../../../types/IDateRange';
import DateRangeInput from '../../../components/ui/DateRangeInput';
import TextInput from '../../../components/ui/TextInput';
import { SearchIcon } from '../../../components/ui/icons/Icons';
import { useGetUsersListQuery } from '../../../redux/api/users';
import { ObjectActionEnum } from '../../../types/IEvent';
import { getGroupedOptions, getSortedUsers } from '../../../utils/select';

interface Props {
  isSingle: boolean;
  fetchOptionsSetter: Dispatch<SetStateAction<IEventsOptions>>;
  isOperator: boolean;
}

const HistoryFilters = ({ isSingle, fetchOptionsSetter, isOperator }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const updateFilter = useCallback(
    (choice: ISelectOption | null, filterId: string) => {
      fetchOptionsSetter(prev => ({
        ...prev,
        [`${filterId}`]: choice ? choice.value : undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        fetchOptionsSetter(prev => ({
          ...prev,
          search: searchTerm.length ? searchTerm : undefined,
        })),
      300,
    );

    return () => {
      clearTimeout(timeOut);
    };
  }, [fetchOptionsSetter, searchTerm]);

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        dateAddTsFrom: startDate ?? undefined,
        dateAddTsTo: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  const { data: users, isLoading: usersLoading } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {!isSingle && (
        <TextInput
          type="search"
          className="mr-4"
          placeholder="Поиск"
          buttonIcon={<SearchIcon />}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      )}

      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
      {!isSingle && (
        <Select
          isSearchable
          className="ml-4 w-25"
          placeholder="Статус документа"
          options={getGroupedOptions(ComplexStateEnum)}
          isClearable
          onChange={value => updateFilter(value as ISelectOption | null, 'objectState')}
        />
      )}
      {!isOperator && (
        <Select
          isSearchable
          className="ml-4 w-25"
          placeholder="Оператор"
          options={getSortedUsers(users, 'id')}
          isClearable
          onChange={value => updateFilter(value as ISelectOption | null, 'userId')}
          isLoading={usersLoading}
        />
      )}
      {!isSingle && (
        <Select
          isSearchable
          className="ml-4 w-25"
          placeholder="Действие"
          options={getGroupedOptions(ObjectActionEnum)}
          isClearable
          onChange={value => updateFilter(value as ISelectOption | null, 'action')}
        />
      )}
      <Select
        className="ml-4 w-15"
        placeholder="Ошибки"
        options={[
          { value: true, label: 'Да' },
          { value: false, label: 'Нет' },
        ]}
        isClearable
        onChange={value =>
          fetchOptionsSetter(prev => ({
            ...prev,
            error: value ? (value as { label: string; value: boolean }).value : undefined,
          }))
        }
      />
    </>
  );
};

export default HistoryFilters;
