import { IUserCreateInfo, IUserInfo } from '../../types/IUserInfo';
import { api } from '../api';

export const usersApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getUsersList: builder.query<IUserInfo[], void>({
      query: () => 'api/users',
    }),
    getUser: builder.query<IUserInfo, number>({
      query: id => ({
        url: `api/users/${id}`,
      }),
      forceRefetch: () => true,
    }),
    createUser: builder.mutation<IUserInfo, IUserCreateInfo>({
      query: body => ({
        url: '/api/users',
        method: 'POST',
        body,
      }),
    }),
    editUser: builder.mutation<string, { id: number; data: Partial<IUserCreateInfo> }>({
      query: ({ id, data }) => ({
        url: `/api/users/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteUser: builder.mutation<string, number>({
      query: id => ({
        url: `api/users/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUsersListQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} = usersApi;
